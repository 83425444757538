import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import FORMATTERS from 'helpers/formatters';
import confirmDialog from 'helpers/confirmDialog';
import { Button, SimpleAlert } from '_components/_core';

import {
  SubscriptionActionsCard,
  SubscriptionAmountCard,
  SubscriptionInformationCard,
  SubscriptionRenewalDateCard,
  SubscriptionUsageCards,
} from './components';
import PaymentAlert from '../PaymentAlert/PaymentAlertContainer';

function Overview({
  subscription,
  onChangeView,
  onGetUpdatePaymentSessionUrl,
  onKeepSubscription,
  onFetchSubscription,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const { dates } = subscription || {};
  const { cancel_at } = dates || {};

  const handleKeepSubscription = useCallback(() => {
    confirmDialog.info({
      title: 'Manter assinatura',
      message:
        'Você deseja manter sua assinatura? <br /> Ao confirmar, a assinatura será mantida e a próxima renovação acontecerá normalmente.',
      icon: 'info',
      confirmButtonText: 'Manter assinatura',
      cancelButtonText: 'Voltar',
      onConfirm: () => {
        setIsLoading(true);

        const callback = () => {
          onFetchSubscription(() => {
            setIsLoading(false);
          });
        };

        const errorCallback = () => {
          setIsLoading(false);
        };

        onKeepSubscription(callback, errorCallback);
      },
    });
  }, [onKeepSubscription, onFetchSubscription]);

  if (!subscription) {
    return null;
  }

  return (
    <>
      <PaymentAlert />
      {cancel_at && (
        <SimpleAlert variant="warning">
          <div className="d-flex align-items-center justify-content-between">
            <span>
              {`Cancelamento da assinatura agendado para ${FORMATTERS.DATE_DDMMYYYY_FROM_UTC_TO_ZONE(cancel_at)}.`}
            </span>
            <Button
              variant={'white'}
              className="p-1 pl-2 pr-2 d-flex align-items-center justify-content-center"
              size="sm"
              onClick={handleKeepSubscription}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Deseja manter a assinatura? Clique aqui
            </Button>
          </div>
        </SimpleAlert>
      )}
      <h4 className="mt-3 mb-3">Assinatura</h4>
      <Row>
        <Col md={12}>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <SubscriptionAmountCard subscription={subscription} />
            </Col>
            <Col className="mt-3 mt-lg-0" lg={6} md={12} sm={12}>
              <SubscriptionRenewalDateCard
                subscription={subscription}
                onChangeView={onChangeView}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col lg={6} sm={12}>
              <SubscriptionInformationCard
                subscription={subscription}
                onChangeView={onChangeView}
                onGetUpdatePaymentSessionUrl={onGetUpdatePaymentSessionUrl}
              />
            </Col>
            <Col lg={6} sm={12}>
              <SubscriptionActionsCard
                subscription={subscription}
                onChangeView={onChangeView}
                onGetUpdatePaymentSessionUrl={onGetUpdatePaymentSessionUrl}
              />
              <SubscriptionUsageCards subscription={subscription} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

Overview.defaultProps = {
  subscription: null,
};

Overview.propTypes = {
  onChangeView: PropTypes.func,
  subscription: PropTypes.object,
};

export default Overview;
