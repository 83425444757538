import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaBarcode } from 'react-icons/fa';
import { LuCreditCard } from 'react-icons/lu';
import { Col, Row } from 'react-bootstrap';

import { Button } from '_components/_core';
import { RepeatInvoiceChargeModal } from '../..';

function InvoicePaymentUndefined({ invoice, onSetInvoice, onFetchInvoice }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleRefreshInvoice = () => {
    onFetchInvoice(invoice.id, foundInvoice => {
      onSetInvoice(foundInvoice);
    });
  };

  return (
    <Row>
      <Col>
        <RepeatInvoiceChargeModal
          invoice={invoice}
          isVisible={isModalOpen}
          onModalToggle={() => setIsModalOpen(!isModalOpen)}
          onAfterSaveCallback={handleRefreshInvoice}
        />
        <div className="d-flex">
          <Button
            variant="default"
            className="d-flex justify-content-center align-items-center"
            onClick={() => setIsModalOpen(true)}
          >
            Pagar com boleto ou cartão
            <FaBarcode className="ml-2" />
            <LuCreditCard className="ml-2" />
          </Button>
        </div>
      </Col>
    </Row>
  );
}

InvoicePaymentUndefined.defaultProps = {
  invoice: null,
};

InvoicePaymentUndefined.propTypes = {
  invoice: PropTypes.object,
};

export default InvoicePaymentUndefined;
