import { authConstants, userConstants } from '../_constants';

const user = JSON.parse(localStorage.getItem('user'));

const INITIAL_STATE = {
  user: user || {},
  isLoggedIn: !!user,
  isLoading: false,
  hasErrors: false,
  errors: [],
  product: process.env.REACT_APP_CONTABILLS_APP || 'contabills',
};

export function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        ...state,
        // isLoading: true,
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isLoggedIn: true,
        isLoading: false,
      };
    case userConstants.USER_UPDATE_SUCCESS: {
      const updatedUser = action.payload.user;

      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          ...updatedUser,
        },
      };
    }

    case authConstants.LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
      };
    case authConstants.SIGN_UP_REQUEST:
      return {
        ...state,
        // isLoading: true,
      };
    case authConstants.SIGN_UP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case authConstants.SIGN_UP_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
        errors: action.payload.errors,
      };
    case authConstants.SIGN_UP_FORM_CLEAR:
      return {
        ...state,
        isLoading: false,
        hasErrors: false,
        errors: [],
      };
    case authConstants.PASSWORD_RESET_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case authConstants.PASSWORD_RESET_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case authConstants.PASSWORD_RESET_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
        errors: action.payload.errors,
      };
    case authConstants.PASSWORD_UPDATE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case authConstants.PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case authConstants.PASSWORD_UPDATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
        errors: action.payload.errors,
      };
    case authConstants.COMPLETE_SIGN_UP_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case authConstants.COMPLETE_SIGN_UP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case authConstants.COMPLETE_SIGN_UP_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
        errors: action.payload.errors,
      };
    case authConstants.EMAIL_CONFIRMATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case authConstants.EMAIL_CONFIRMATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case authConstants.EMAIL_CONFIRMATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasErrors: true,
        errors: action.payload.errors,
      };
    case 'SET_PRODUCT':
      return {
        ...state,
        product: action.payload,
      };
    case userConstants.USER_AVATAR_UPLOAD_SUCCESS: {
      const { avatar_url } = action.payload;

      return {
        ...state,
        user: {
          ...state.user,
          avatar_url,
        },
      };
    }
    case userConstants.USER_AVATAR_DELETE_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          avatar_url: null,
        },
      };
    }
    default:
      return state;
  }
}
