import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { FaBarcode } from 'react-icons/fa';

import BillingInformationModal from '../../BillingInformationModal/BillingInformationModalContainer';

import {
  ActionsList,
  ActionsListItem,
  CustomCard,
  CustomCardBody,
} from '../../../../../styles';
import {
  LuArrowRight,
  LuCreditCard,
  LuDollarSign,
  LuFileEdit,
  LuRefreshCcw,
  LuRefreshCwOff,
  LuStar,
  LuUsers,
} from 'react-icons/lu';

const PAYMENT_METHOD_ICONS = {
  BOLETO: <FaBarcode />,
  CREDIT_CARD: <LuCreditCard />,
  UNDEFINED: <LuCreditCard />,
  null: <LuCreditCard />,
};

function SubscriptionActionsCard({ subscription, onChangeView }) {
  const [isBillingInfoModalOpen, setIsBillingInfoModalOpen] = useState(false);

  const { can_purchase_users, payment_method } = subscription;

  if (!subscription) {
    return null;
  }

  return (
    <>
      <BillingInformationModal
        subscription={subscription}
        isVisible={isBillingInfoModalOpen}
        onModalToggle={() => setIsBillingInfoModalOpen(!isBillingInfoModalOpen)}
      />
      <CustomCard className="mt-3 mt-lg-0">
        <CustomCardBody className="p-0">
          {/* <Label>Ações</Label> */}
          <Row>
            <Col>
              <ActionsList>
                <ActionsListItem onClick={() => onChangeView('INVOICE_HISTORY')}>
                  <div>
                    <div>
                      <LuDollarSign />
                      Histórico de faturas
                    </div>
                  </div>
                  <LuArrowRight className="text-muted" />
                </ActionsListItem>
                <ActionsListItem
                  disabled={['incomplete'].includes(subscription.status)}
                  onClick={() => setIsBillingInfoModalOpen(true)}
                >
                  <div>
                    <LuFileEdit />
                    Dados de cobrança
                  </div>
                  <LuArrowRight className="text-muted" />
                </ActionsListItem>

                <ActionsListItem
                  disabled={[
                    'canceled',
                    'trialing',
                    'trial-ended',
                    'incomplete',
                    'incomplete_expired',
                  ].includes(subscription.status)}
                  onClick={() => onChangeView('CHANGE_PAYMENT_METHOD')}
                >
                  <div>
                    {PAYMENT_METHOD_ICONS[payment_method]}
                    Formas de pagamento
                  </div>
                  <LuArrowRight className="text-muted" />
                </ActionsListItem>
                {['active', 'trialing'].includes(subscription.status) &&
                  can_purchase_users && (
                    <ActionsListItem onClick={() => onChangeView('ADDITIONAL_USERS')}>
                      <div>
                        <LuUsers />
                        Usuários adicionais
                      </div>
                      <LuArrowRight className="text-muted" />
                    </ActionsListItem>
                  )}
                {/* {can_purchase_companies && (
                  <ActionsListItem onClick={() => onChangeView('ADDITIONAL_COMPANIES')}>
                    <div>
                      <LuBuilding2 />
                      Empresas adicionais
                    </div>
                    <LuArrowRight className="text-muted" />
                  </ActionsListItem>
                )} */}
                {/* <ActionsListItem
                  disabled={!['trialing', 'active'].includes(subscription.status)}
                  onClick={() => onChangeView('CHANGE_PLAN')}
                >
                  <div>
                    <LuArrowRightLeft />
                    Alterar plano
                  </div>
                  <div>
                    <LuArrowRight className="text-muted" />
                  </div>
                </ActionsListItem> */}

                {['active', 'past_due'].includes(subscription.status) && (
                  <ActionsListItem onClick={() => onChangeView('CANCEL_SUBSCRIPTION')}>
                    <div className="text-danger">
                      <LuRefreshCwOff className="danger" />
                      Cancelar assinatura
                    </div>
                    <LuArrowRight className="text-muted" />
                  </ActionsListItem>
                )}
                {['trialing', 'trial-ended', 'canceled', 'incomplete_expired'].includes(
                  subscription.status,
                ) && (
                  <ActionsListItem onClick={() => onChangeView('CHECKOUT')}>
                    <div className="text-success">
                      {['canceled', 'incomplete_expired'].includes(
                        subscription.status,
                      ) ? (
                        <LuRefreshCcw className="danger" />
                      ) : (
                        <LuStar className="danger" />
                      )}

                      {['canceled', 'incomplete_expired'].includes(subscription.status)
                        ? 'Reativar assinatura'
                        : 'Assinar agora'}
                    </div>
                    <LuArrowRight className="text-muted" />
                  </ActionsListItem>
                )}
              </ActionsList>
            </Col>
          </Row>
        </CustomCardBody>
      </CustomCard>
    </>
  );
}

SubscriptionActionsCard.defaultProps = {
  subscription: null,
};

SubscriptionActionsCard.propTypes = {
  subscription: PropTypes.object,
  onChangeView: PropTypes.func.isRequired,
};

export default SubscriptionActionsCard;
