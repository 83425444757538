import styled from 'styled-components';
import { Popover } from 'react-bootstrap';

export const StyledPopover = styled(Popover)`
  padding: 0 !important;
  color: rgb(42, 46, 52);
  font-family: var(--font-family);
  z-index: 9999;
  min-width: 260px;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.12), 0px 5px 25px 0px rgba(48, 49, 61, 0.08);

  &.popover, .popover-header {
    background-color: #FFFFFF !important;
    padding: 8px;
  }
  
  &.popover, .popover-body {
    background-color: #FFFFFF !important;
    border-radius: 8px;
    padding: 8px 8px !important; 
  }

  &.popover .arrow::before, .popover .arrow::after {
    display: none;
  }

  &.bs-popover-bottom .arrow::after {
    display: none;
  }  
`;
