import { connect } from 'react-redux';
import { compose } from 'redux';

import paymentsActionsCreators from '_store/_actions/payments';
import subscriptionActionCreators from '_store/_actions/subscription';

import AdditionalUsers from './AdditionalUsers';

const mapStateToProps = state => ({
  payments: state.payments.payments,
  activeCompany: state.company.activeCompany,
  userSeatsPricing: state.subscription.userSeatsPricing,
});

const mapDispatchToProps = {
  onFetchPayments: paymentsActionsCreators.fetchPayments,
  onFetchUserSeatsPricing: subscriptionActionCreators.fetchUserSeatsPricing,
  onConfirmUserSeatsPurchase: subscriptionActionCreators.confirmUserSeatsPurchase,
  onValidateUserSeatsPurchase: subscriptionActionCreators.validateUserSeatsPurchase,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AdditionalUsers);
