import { parsePaginationParams } from 'helpers';

import constants from '../../_constants/notifications.constants';
import service from '../../../services/notifications.service';

const fetchUserNotifications = (params = {}) => {
  return dispatch => {
    dispatch(request());

    const parsedParams = parsePaginationParams(params);

    service
      .fetchUserNotifications(parsedParams)
      .then(response => {
        dispatch(success(response.data));
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request() {
    return {
      type: constants.FETCH_USER_NOTIFICATIONS_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.FETCH_USER_NOTIFICATIONS_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_USER_NOTIFICATIONS_FAILURE,
      payload: error,
    };
  }
};

export default fetchUserNotifications;
