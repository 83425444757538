import { connect } from 'react-redux';

import notificationsActionCreators from '_store/_actions/notifications';

import DashboardNotifications from './DashboardNotifications';

const mapStateToProps = state => ({
  subscription: state.subscription.subscription,
  user: state.auth.user,
  notifications: state.notifications.notifications,
});

const mapDispatchToProps = {
  onFetchNotifications: notificationsActionCreators.fetchUserNotifications,
  onReadNotification: notificationsActionCreators.readNotification,
  onArchiveNotification: notificationsActionCreators.archiveNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardNotifications);
