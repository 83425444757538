import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { IoMailOutline, IoLockClosedOutline } from 'react-icons/io5';

import { Button } from '_components/_core';
import { getQueryVariable } from 'helpers';

import { StyledForm, StyledButton } from '../../styles';

function SignInForm({ history, onSignIn }) {
  const [isSigningIn, setIsSigningIn] = useState(false);

  const handleSignIn = useCallback(
    values => {
      setIsSigningIn(true);

      const successCallback = () => {
        setIsSigningIn(false);

        history.push('/dashboard');
      };

      const failureCallback = () => {
        setIsSigningIn(false);
      };

      onSignIn(values.email, values.password, successCallback, failureCallback);
    },
    [onSignIn, history],
  );

  return (
    <StyledForm>
      <Row>
        <Col>
          <Formik
            enableReinitialize
            initialValues={{
              email: getQueryVariable('email') || '',
              password: '',
            }}
            onSubmit={handleSignIn}
          >
            {({ values, handleChange, handleSubmit }) => (
              <>
                <Form.Row>
                  <Form.Group as={Col} lg="12">
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <IoMailOutline />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        name="email"
                        placeholder="E-mail"
                        onChange={handleChange}
                        required
                        value={values.email}
                        type="email"
                      />
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} lg="12">
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <IoLockClosedOutline />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="password"
                        name="password"
                        autoComplete="off"
                        placeholder="Senha"
                        onChange={handleChange}
                        required
                        value={values.password}
                      />
                    </InputGroup>
                  </Form.Group>
                </Form.Row>
                <div className="d-flex col-12 justify-content-center">
                  <StyledButton
                    type="submit"
                    variant="success"
                    className="p-3"
                    onClick={handleSubmit}
                    disabled={isSigningIn}
                    isLoading={isSigningIn}
                  >
                    ENTRAR
                  </StyledButton>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <Button variant="link" onClick={() => history.push('/recuperar-senha')}>
                    Esqueci minha senha
                  </Button>
                </div>
              </>
            )}
          </Formik>
        </Col>
      </Row>
    </StyledForm>
  );
}

SignInForm.propTypes = {
  history: PropTypes.object.isRequired,
  onSignIn: PropTypes.func.isRequired,
};

export default withRouter(SignInForm);
