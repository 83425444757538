import { Col } from 'react-bootstrap';
import styled, { css } from 'styled-components';

export const Title = styled.h5`
  color: #071437;
`;

export const PlansContainer = styled.div`
  flex: 1;
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-gap: 8px;
  } */
`;

export const StyledCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    display: block;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlanItem = styled.div`
  background-color: #fff;
  border: 1px solid #e9ecef;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 0px;
  position: relative;

  input[type='radio'] {
    width: 18px;
    height: 18px;
  }

  &:hover {
    border-color: #4fcb1a;
    cursor: pointer;
  }

  ${({ active }) =>
    active &&
    css`
    border-color: #4fcb1a;
  `}
`;

export const FeaturesContainer = styled.ul`
  font-weight: 400;
  margin-bottom: 0;
`;

export const CurrentPlan = styled.div`
  /* position: absolute;
  top: -16px; */
`;

export const Box = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #F1F1F4;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    padding: 0;
    margin-top: 4px;
    font-size: 16px;
  }

  small {
    margin-top: 4px;
    color: #6c757d;    
  }

`;

export const BoxLabel = styled.strong`
  font-size: 14px;
  font-weight: 500;
  color: #071437;
`;

export const BoxValue = styled.span`
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  color: #0030B9;
  color: #ec6b00;

  ${({ status }) =>
    status === 'paid' &&
    css`
      color: #198754;
  `}

  ${({ status }) =>
    status === 'warning' &&
    css`
    color: #ec6b00;
  `}

  ${({ status }) =>
    status === 'danger' &&
    css`
      color: #dc3545;
  `}
`;

export const BoxText = styled.p`
  margin: 0;
  padding: 0;
  margin-top: 4px;
  font-size: 16px;
`;

export const Details = styled.ul`
  list-style: none;
  margin: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 0.5rem;
    font-weight: 400;
    font-size: 1rem;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 13px;
  }

  p.previous-value {
    color: #6c757d;
    text-decoration: line-through;
    min-width: 170px;
    text-align: right;
    margin-right: 0.5rem;
  }

  p.highlight {
    font-weight: 600;
    min-width: 170px;
    text-align: left;
    margin-left: 0.5rem;
    color: #252F4A;
  }

  .mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    text-align: center;
    min-width: 20px;
  }

  p.no-strike {
    text-decoration: none;
  }
`;

export const SmallHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #252F4A;
`;
