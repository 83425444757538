import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Form } from './components';
import { Container } from './styles';

function CompleteSignUp({ isLoading, onCompleteSignUp, onValidateInviteToken }) {
  useEffect(() => {
    document.body.style.backgroundImage = 'url(assets/img/bg-activate-subscription.png)';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundRepeat = 'no-repeat';

    return () => {
      document.body.style.backgroundImage = 'none';
    };
  }, []);

  return (
    <Container className="h-100">
      <Form
        isLoading={isLoading}
        onCompleteSignUp={onCompleteSignUp}
        onValidateInviteToken={onValidateInviteToken}
      />
    </Container>
  );
}

CompleteSignUp.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onCompleteSignUp: PropTypes.func.isRequired,
};

export default CompleteSignUp;
