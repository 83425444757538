import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useStripe } from '@stripe/react-stripe-js';
import { Col, Form, Row } from 'react-bootstrap';
import {
  LuAlertCircle,
  LuArrowRight,
  LuCheckCircle2,
  LuCreditCard,
  LuXCircle,
} from 'react-icons/lu';
import { FaBarcode } from 'react-icons/fa';
import { IoArrowBack } from 'react-icons/io5';
import classNames from 'classnames';
import pluralize from 'pluralize';

import { sanitizePlanName } from 'helpers';
import FORMATTERS from 'helpers/formatters';
import { Button, Select, SimpleAlert } from '_components/_core';

import { BillingInformationModal } from '../..';
import {
  CustomCard,
  CustomCardBody,
  Value,
} from '_components/Settings/components/SettingsV2/styles';

import { Details, PaymentMethodItem, PaymentMethodsList, PlanFeatures } from '../styles';

const CYCLE_MONTHS = {
  1: 'Mensal',
  6: 'Semestral',
  12: 'Anual',
};

const CYCLE = {
  1: 'mensal',
  6: 'a cada 6 meses',
  12: 'a cada 12 meses',
};

const USERS_CYCLE = {
  1: 'por mês',
  6: 'por semestre',
  12: 'por ano',
};

function SelectPaymentMethod({
  planId,
  plans,
  subscription,
  onCreateCheckout,
  onBackToPlans,
  onChangeView,
  onUpdateStoreSubscription,
  onFetchUserSeatsPricing,
}) {
  const stripe = useStripe();

  const [isLoading, setIsLoading] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('CREDIT_CARD');
  const [userSeatsPricing, setUserSeatsPricing] = useState(null);
  const [numberOfUsers, setNumberOfUsers] = useState(0);

  const plan = useMemo(() => plans.find(p => p.id === planId, [plans, planId]));

  const hasDiscount = plan.cycle_months !== 1;

  const usersTotal = useMemo(() => {
    if (!userSeatsPricing) {
      return 0;
    }

    return numberOfUsers * userSeatsPricing.product_info.price;
  }, [numberOfUsers, userSeatsPricing]);

  const canPurchaseUsers = useMemo(() => {
    if (!subscription) {
      return false;
    }

    if (subscription.type === 'COMPANY' && subscription.can_purchase_users) {
      return true;
    }

    return false;
  }, [subscription]);

  useEffect(() => {
    if (canPurchaseUsers && !userSeatsPricing) {
      const params = {
        new_subscription: true,
        plan_cycle_months: plan.cycle_months,
      };

      onFetchUserSeatsPricing(params, data => {
        setUserSeatsPricing(data);
      });
    }
  }, [plan, canPurchaseUsers, userSeatsPricing, onFetchUserSeatsPricing]);

  const usersOptions = useMemo(() => {
    const options = Array.from({ length: 7 }).map((_, index) => {
      return {
        value: index + 1,
        label: `${index + 1} ${pluralize('usuário', index + 1)} ${pluralize('adicional', index + 1)}`,
      };
    });

    options.unshift({
      value: 0,
      label: 'Nenhum usuário adicional',
    });

    return options;
  }, []);

  const handleSubmitCheckout = useCallback(() => {
    setIsLoading(true);

    const successCallback = async data => {
      if (selectedPaymentMethod === 'CREDIT_CARD') {
        setIsLoading(false);

        stripe.redirectToCheckout({ sessionId: data.session_id });

        return;
      }

      if (selectedPaymentMethod === 'BOLETO') {
        const { invoice, full_subscription, short_subscription, pending_invoice } = data;

        onUpdateStoreSubscription({
          short_subscription,
          full_subscription,
          pending_invoice,
        });

        onChangeView('INVOICE_DETAILS', {
          invoice,
          invoiceId: invoice.id,
          originView: 'DEFAULT',
        });

        setIsLoading(false);

        return;
      }

      setIsLoading(false);
    };

    const errorCallback = () => {
      setIsLoading(false);
    };

    const params = {
      plan_id: planId,
      payment_method: selectedPaymentMethod,
    };

    if (numberOfUsers !== 0) {
      params.addons = [
        {
          product_id: userSeatsPricing.product_info.id,
          quantity: numberOfUsers,
        },
      ];
    }

    onCreateCheckout(params, successCallback, errorCallback);
  }, [
    onCreateCheckout,
    planId,
    selectedPaymentMethod,
    stripe,
    onChangeView,
    onUpdateStoreSubscription,
    numberOfUsers,
    userSeatsPricing,
  ]);

  const FeatureItem = ({ feature, enabled }) => (
    <li>
      <div className="d-flex align-items-center">
        {enabled && <LuCheckCircle2 size="1.1rem" className="text-success mr-3" />}
        {!enabled && <LuXCircle size="1.1rem" className="text-danger mr-3" />}
        <span>{feature}</span>
      </div>
    </li>
  );

  const totals = useMemo(() => {
    const selectedPlan = plans.find(p => p.id === planId);

    const monthlyPlan = plans.find(
      p =>
        p.type === selectedPlan.type &&
        selectedPlan.mnemonic_short === p.mnemonic_short &&
        p.cycle_months === 1,
    );

    const totalMonthly = monthlyPlan.price * selectedPlan.cycle_months;

    const discount = plan.price - totalMonthly;
    const discountPercentage = discount / selectedPlan.price;

    return {
      totalPrice: selectedPlan.price,
      totalMonthly,
      discount,
      discountPercentage,
    };
  }, [plans, plan, planId]);

  // const minUsers = useMemo(() => {
  //   if (!userSeatsPricing) {
  //     return 0;
  //   }

  //   const { users } = subscription;

  //   if (users.used > plan.max_users) {
  //     return users.used - plan.max_users;
  //   }

  //   return 0;
  // }, [subscription, plan, userSeatsPricing]);

  const renderPaymentButton = useCallback(() => {
    switch (selectedPaymentMethod) {
      case 'CREDIT_CARD':
        return (
          <div className="mt-3 d-flex flex-column justify-content-center align-items-start">
            <Button
              isLoading={isLoading}
              disabled={isLoading}
              variant="success-2"
              className="d-flex justify-content-center align-items-center"
              onClick={handleSubmitCheckout}
            >
              <LuCreditCard className="mr-2" />
              Pagar com cartão
              <LuArrowRight className="ml-2" />
            </Button>
            <p className="p-0 m-0 mt-3">
              Você será redirecionado para preencher seus dados do cartão. <br />
              Aprovação imediata (bandeiras aceitas Visa ou Mastercard)
            </p>
          </div>
        );
      case 'BOLETO':
        return (
          <div className="mt-3 d-flex flex-column justify-content-center align-items-start">
            <Button
              isLoading={isLoading}
              disabled={isLoading}
              variant="success-2"
              className="d-flex justify-content-center align-items-center"
              onClick={handleSubmitCheckout}
            >
              <FaBarcode className="mr-2" />
              Pagar com boleto
              <LuArrowRight className="ml-2" />
            </Button>
            <p className="p-0 m-0 mt-3">
              Um boleto será gerado com vencimento para 3 dias. <br />
              Aprovação entre 1 e 3 dias úteis
            </p>
          </div>
        );
      default:
        return null;
    }
  }, [selectedPaymentMethod, handleSubmitCheckout, isLoading]);

  const paymentOptions = useMemo(
    () => [
      {
        id: 'CREDIT_CARD',
        title: 'Cartão de crédito',
        description: 'Aprovação imediata',
        icon: <LuCreditCard size="1.4em" className="mr-3" />,
      },
      {
        id: 'BOLETO',
        title: 'Boleto bancário',
        description: 'Aprovação em até 3 dias úteis',
        icon: <FaBarcode size="1.4em" className="mr-3" />,
      },
    ],
    [],
  );

  const isBillingInfoValid = useCallback(billing_info => {
    const {
      name,
      document_type,
      document_number,
      email_primary,
      address_street,
      address_number,
      address_district,
      address_city,
      address_city_ibge,
      address_state,
      address_state_ibge,
      address_zip_code,
    } = billing_info || {};

    if (
      !name ||
      !document_type ||
      !document_number ||
      !email_primary ||
      !address_zip_code ||
      !address_number ||
      !address_street ||
      !address_district ||
      !address_state ||
      !address_state_ibge ||
      !address_city ||
      !address_city_ibge
    ) {
      return false;
    }

    return true;
  }, []);

  const InfoItem = ({
    title,
    content,
    onClick,
    contentStyle,
    isLoading,
    isLoadingMessage,
    className,
  }) => (
    <>
      <Value
        className={classNames({
          'w-100 d-flex justify-content-between align-items-center': true,
          [className]: className,
        })}
      >
        {title}
        {onClick && (
          <Button
            variant="link"
            size="small"
            className={classNames({
              'm-0 p-0 ml-auto d-flex justify-content-center align-items-center': true,
              'text-muted': isLoading,
            })}
            disabled={isLoading}
            onClick={onClick}
          >
            {isLoading ? isLoadingMessage : 'Alterar'}
          </Button>
        )}
      </Value>
      <Value variant="light" className="mt-1" style={contentStyle}>
        {content}
      </Value>
    </>
  );

  return (
    <>
      <BillingInformationModal
        isVisible={isAddressModalOpen}
        onModalToggle={() => setIsAddressModalOpen(!isAddressModalOpen)}
      />
      <div className="mt-3 d-flex align-items-center">
        <Button
          variant="link"
          className="m-0 p-0 mr-3 d-flex justify-content-center align-items-center"
          onClick={onBackToPlans}
        >
          <IoArrowBack className="mr-1" />
          Voltar
        </Button>
      </div>
      <Row className="mt-3">
        <Col sm={12}>
          <h4 className="m-0 mb-3">
            Pagamento do plano {sanitizePlanName(plan.name)}{' '}
            {CYCLE_MONTHS[plan.cycle_months]}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <CustomCard>
            <CustomCardBody>
              <InfoItem
                title={`Plano ${sanitizePlanName(plan.name)}`}
                contentStyle={{ flex: 1, width: '100%' }}
                content={
                  <div className="w-100">
                    <Value variant="large" className="mt-2">
                      {FORMATTERS.NUMBER(plan.price)}&nbsp;
                      <small>{CYCLE[plan.cycle_months]}</small>
                    </Value>
                    <PlanFeatures className="mt-3">
                      <FeatureItem
                        feature={`Até ${plan.max_users} ${pluralize('usuário', plan.max_users)}`}
                        error={numberOfUsers > plan.max_users}
                        enabled
                      />
                      <FeatureItem
                        feature={`Até ${plan.max_storage_size_megabytes / 1000} GB de armazenamento`}
                        enabled
                      />
                    </PlanFeatures>
                    {subscription.users.used > plan.max_users && (
                      <div
                        className="mt-3 pt-3 d-flex justify-content-start align-items-center"
                        style={{
                          borderTop: '1px solid #dee2e6',
                        }}
                      >
                        <LuAlertCircle className="text-warning" size={20} />
                        <p className="p-0 m-0 ml-3">
                          {`A empresa possui ${subscription.users.used} usuários cadastrados.`}
                          {/* {`O plano não comporta o total de ${subscription.users.used} usuários na empresa.`} */}
                          {/* <br />
                          <ul className="m-0">
                            <li>
                              {`Remova ao menos ${minUsers} ${pluralize('usuário', minUsers)} excedente`}
                            </li>
                            <li>
                              {`Ou adquira ao menos ${minUsers} ${pluralize('usuário', minUsers)} ${pluralize('adicional', minUsers)}`}
                            </li>
                          </ul> */}
                          {/* {`Remova usuários da empresa, ou inclua ao menos ${minUsers} ${pluralize('usuário', minUsers)} ${pluralize('adicional', minUsers)} em sua assinatura.`} */}
                        </p>
                      </div>
                    )}
                  </div>
                }
                onClick={onBackToPlans}
              />
            </CustomCardBody>
          </CustomCard>
          {canPurchaseUsers && (
            <CustomCard className="mt-3">
              <CustomCardBody>
                <InfoItem
                  title="Usuários adicionais"
                  contentStyle={{ flex: 1, width: '100%' }}
                  content={
                    <div className="w-100">
                      <Select
                        value={usersOptions.find(
                          option => option.value === numberOfUsers,
                        )}
                        onChange={option => {
                          setNumberOfUsers(option.value);
                        }}
                        options={usersOptions}
                        isSearchable={false}
                        className="w-100 mt-2"
                        min
                      />
                    </div>
                  }
                  onClick={null}
                />
              </CustomCardBody>
            </CustomCard>
          )}
          <CustomCard className="mt-3">
            <CustomCardBody>
              <InfoItem
                title="Dados de cobrança"
                contentStyle={{ flex: 1, width: '100%' }}
                content={
                  <div className="w-100">
                    <span className="d-flex align-items-center justify-content-start">
                      <span style={{ flex: 1 }} className="mt-2 mb-1">
                        <span>{subscription.billing_info.name}</span>
                        <br />
                        <span>
                          {FORMATTERS.CPF_CNPJ(subscription.billing_info.document_number)}
                        </span>
                        <br />
                        {subscription.billing_info.email_primary}
                        <div
                          className="w-100 pt-2"
                          style={{
                            flex: 1,
                          }}
                        >
                          <span>
                            {subscription.billing_info.address_street},{' '}
                            {subscription.billing_info.address_number}
                            {subscription.billing_info.address_complement
                              ? ` - ${subscription.billing_info.address_complement}`
                              : ``}
                          </span>
                          <br />
                          <span>
                            {subscription.billing_info.address_city} -{' '}
                            {subscription.billing_info.address_state} <br />
                            {FORMATTERS.CEP(subscription.billing_info.address_zip_code)}
                          </span>
                        </div>
                      </span>
                    </span>
                    {!isBillingInfoValid(subscription.billing_info) && (
                      <SimpleAlert variant="warning" className="mt-3">
                        <Button
                          variant="link"
                          className="p-0 m-0"
                          onClick={() => setIsAddressModalOpen(true)}
                        >
                          Complete aqui os dados de cobrança.
                        </Button>
                      </SimpleAlert>
                    )}
                  </div>
                }
                onClick={() => setIsAddressModalOpen(true)}
              />
            </CustomCardBody>
          </CustomCard>
        </Col>
        <Col md={7}>
          <CustomCard className="mt-3 mt-md-0">
            <CustomCardBody>
              <InfoItem
                title="Resumo"
                contentStyle={{ flex: 1, width: '100%', paddingTop: '0.8rem' }}
                content={
                  <>
                    <Details>
                      <li>
                        <span className="d-flex justify-content-center align-items-center">
                          {`Plano ${sanitizePlanName(plan.name)} ${CYCLE_MONTHS[plan.cycle_months]}`}
                        </span>
                        <span>
                          {FORMATTERS.NUMBER(totals.totalMonthly)}{' '}
                          {USERS_CYCLE[plan.cycle_months]}
                        </span>
                      </li>
                      {numberOfUsers > 0 && (
                        <li>
                          <span className="d-flex justify-content-center align-items-center">
                            {`Usuário adicional x${numberOfUsers}`}
                          </span>
                          <span>
                            {FORMATTERS.NUMBER(usersTotal)}{' '}
                            {USERS_CYCLE[plan.cycle_months]}
                          </span>
                        </li>
                      )}
                      {hasDiscount && (
                        <li>
                          <span>Desconto {CYCLE_MONTHS[plan.cycle_months]}</span>
                          <span className="text-success">
                            {FORMATTERS.NUMBER(totals.discount)}
                          </span>
                        </li>
                      )}
                    </Details>
                    <Details>
                      <li
                        style={{ borderTop: '1px solid #dee2e6', paddingTop: '0.5rem' }}
                      >
                        <span>Total</span>
                        <span>
                          <strong>
                            {FORMATTERS.NUMBER(plan.price + usersTotal)}{' '}
                            {USERS_CYCLE[plan.cycle_months]}
                          </strong>
                        </span>
                      </li>
                    </Details>
                  </>
                }
              />
              <InfoItem
                title="Forma de pagamento"
                className="mt-3"
                contentStyle={{ flex: 1, width: '100%', paddingTop: '0.8rem' }}
                content={
                  <>
                    <PaymentMethodsList>
                      {paymentOptions.map((option, index) => (
                        <PaymentMethodItem
                          key={index}
                          action
                          onClick={() => setSelectedPaymentMethod(option.id)}
                          className={classNames({
                            'd-flex justify-content-between align-items-center': true,
                            active: selectedPaymentMethod === option.id,
                          })}
                        >
                          <div className="d-flex align-items-center">
                            {option.icon}
                            <span className="ml-1">{option.title}</span>
                          </div>
                          <Form.Check
                            type="radio"
                            checked={selectedPaymentMethod === option.id}
                            style={{ cursor: 'pointer' }}
                          />
                        </PaymentMethodItem>
                      ))}
                    </PaymentMethodsList>
                    <div>{renderPaymentButton()}</div>
                  </>
                }
              />
            </CustomCardBody>
          </CustomCard>
        </Col>
      </Row>
    </>
  );
}

SelectPaymentMethod.propTypes = {
  planId: PropTypes.string.isRequired,
  plans: PropTypes.array.isRequired,
  onCreateCheckout: PropTypes.func.isRequired,
  onBackToPlans: PropTypes.func.isRequired,
  onFetchSubscription: PropTypes.func.isRequired,
  onFetchCompanies: PropTypes.func.isRequired,
  onFetchActiveCompany: PropTypes.func.isRequired,
  onChangeView: PropTypes.func.isRequired,
  onUpdateStoreSubscription: PropTypes.func.isRequired,
};

export default SelectPaymentMethod;
