import styled from 'styled-components';

import { CardFooter } from '_components/_core';

export const StyledCardFooter = styled(CardFooter)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    
    @media (max-width: 768px) {
        flex-direction: column;

        button {
            width: 100% !important;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }
`;
