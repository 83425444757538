import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Container from 'react-bootstrap/Container';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { Formik } from 'formik';
import { LuTrash } from 'react-icons/lu';

import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  FormDateField,
} from '_components/_core';

import FORMATTERS from 'helpers/formatters';

import { AllowedPeriodSchema } from './utilities';
import { StyledCardFooter } from './styles';

function AllowedPeriod({
  blockedPeriod,
  onFetchBlockedPeriod,
  onUpdateActivePeriod,
  isLoadingBlockedPeriod,
  isCompact,
}) {
  const [fetchedPeriod, setFetchedPeriod] = useState(false);

  useEffect(() => {
    if (!fetchedPeriod) {
      onFetchBlockedPeriod();
      setFetchedPeriod(true);
    }
  }, [onFetchBlockedPeriod, blockedPeriod, setFetchedPeriod, fetchedPeriod]);

  const handleUpdatePeriod = values => {
    onUpdateActivePeriod(values, () => {
      onFetchBlockedPeriod();
    });
  };

  const handleRemoveBlock = () => {
    onUpdateActivePeriod({ start_date: null, end_date: null }, () => {
      onFetchBlockedPeriod();
    });
  };

  const hasBlockedPeriod = useMemo(() => {
    return blockedPeriod.active_period_start && blockedPeriod.active_period_end;
  }, [blockedPeriod]);

  return (
    <Container
      fluid={!isCompact}
      className={classNames({
        'content-wrapper': !isCompact,
      })}
    >
      <Alert
        id="blocked-period-alert"
        variant={hasBlockedPeriod ? 'warning' : 'info'}
        description={
          <>
            {hasBlockedPeriod && (
              <h6 className="m-0">
                O período {FORMATTERS.BLOCKED_PERIOD(blockedPeriod)} está bloqueado para
                alterações.
              </h6>
            )}
            {!hasBlockedPeriod && <h6 className="m-0">Nenhum período bloqueado.</h6>}
          </>
        }
      />
      <Formik
        initialValues={{
          start_date: blockedPeriod.active_period_start,
          end_date: blockedPeriod.active_period_end,
        }}
        validationSchema={AllowedPeriodSchema}
        onSubmit={handleUpdatePeriod}
        enableReinitialize
      >
        {({ handleSubmit, isValid, errors }) => (
          <Form className="tab-content" onSubmit={handleSubmit}>
            <Card className="mt-3">
              <CardHeader
                title="Bloquear período"
                description="Você pode bloquear a criação, edição e exclusão de transações marcadas como pagas no período definido abaixo."
              />
              <CardBody noPaddingTop>
                <Form.Row>
                  <Form.Group as={Col} xs={12} md={6}>
                    <Form.Label>Data inicial</Form.Label>
                    <FormDateField
                      id="start_date"
                      name="start_date"
                      placeholder="Data inicial"
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={12} md={6}>
                    <Form.Label>Data final</Form.Label>
                    <FormDateField
                      id="end_date"
                      name="end_date"
                      placeholder="Data final"
                    />
                  </Form.Group>
                </Form.Row>
              </CardBody>
              <StyledCardFooter>
                <Button
                  isLoading={isLoadingBlockedPeriod}
                  disabled={isLoadingBlockedPeriod || !isValid}
                  onClick={handleSubmit}
                  id="btn-save-blocked-period"
                >
                  Confirmar bloqueio
                </Button>
                <Button
                  isLoading={isLoadingBlockedPeriod}
                  disabled={isLoadingBlockedPeriod}
                  onClick={handleRemoveBlock}
                  variant="inverse-danger"
                  className="d-flex justify-content-center align-items-center"
                  id="btn-remove-blocked-period"
                >
                  <LuTrash className="mr-2" />
                  Remover bloqueio
                </Button>
              </StyledCardFooter>
            </Card>
          </Form>
        )}
      </Formik>
    </Container>
  );
}

AllowedPeriod.defaultProps = {
  blockedPeriod: {},
  isLoadingBlockedPeriod: false,
  isCompact: false,
};

AllowedPeriod.propTypes = {
  onFetchBlockedPeriod: PropTypes.func,
  blockedPeriod: PropTypes.object,
  onUpdateActivePeriod: PropTypes.func,
  isLoadingBlockedPeriod: PropTypes.bool,
  isCompact: PropTypes.bool,
};

export default AllowedPeriod;
