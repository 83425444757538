import React, { useEffect, useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Col, Row } from 'react-bootstrap';

import FORMATTERS from 'helpers/formatters';
import confirmDialog from 'helpers/confirmDialog';
import { Select, SimpleAlert } from '_components/_core';
import { LoadingIcon } from '_components/_shared';

import { Confirmation } from './components';

import {
  CustomCard,
  CustomCardBody,
  CustomCardHeader,
  CustomBreadcrumb,
  CustomBreadcrumbItem,
} from '../../../../styles';

const CYCLE = {
  1: 'por mês',
  6: 'por semestre',
  12: 'por ano',
};

pluralize.addIrregularRule('adicional', 'adicionais');

function AdditionalUsers({
  subscription,
  userSeatsPricing,
  onChangeView,
  onFetchUserSeatsPricing,
  onConfirmUserSeatsPurchase,
  onValidateUserSeatsPurchase,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [numberOfUsers, setNumberOfUsers] = useState(0);

  const [overview, setOverview] = useState(null);

  const [changingTimeout, setChangingTimeout] = useState(0);

  useEffect(() => {
    setIsLoading(true);

    onFetchUserSeatsPricing(
      {
        new_subscription: false,
      },
      () => {
        setIsLoading(false);
      },
    );
  }, [onFetchUserSeatsPricing]);

  const maxUsers = useMemo(() => {
    const { users } = subscription || {};
    const { max } = users || {};

    return max;
  }, [subscription]);

  const delayedChange = usersCount => {
    if (changingTimeout) {
      clearTimeout(changingTimeout);
    }

    const newChangingTimeout = setTimeout(() => {
      setIsLoading(true);

      const params = {
        product_id: userSeatsPricing.product_info.id,
        quantity: usersCount,
      };

      onValidateUserSeatsPurchase(params, response => {
        setOverview(response);

        setIsLoading(false);
      });
    }, 700);

    setChangingTimeout(newChangingTimeout);
  };

  const handleChange = option => {
    const value = Number(option.value);

    setNumberOfUsers(value);

    delayedChange(value);
  };

  const usersOptions = useMemo(() => {
    const { product_info } = userSeatsPricing || {};
    const { remaining } = product_info || {};

    return Array.from({ length: remaining }).map((_, index) => {
      return {
        value: index + 1,
        label: `${index + 1} ${pluralize('usuário', index + 1)} ${pluralize('adicional', index + 1)}`,
      };
    });
  }, [userSeatsPricing]);

  const userTerm = useMemo(() => {
    if (numberOfUsers === 1) {
      return 'usuário adicional';
    }

    return 'usuários adicionais';
  }, [numberOfUsers]);

  const handleConfirm = useCallback(() => {
    if (!userSeatsPricing) {
      return;
    }

    const params = {
      product_id: userSeatsPricing.product_info.id,
      quantity: numberOfUsers,
      amount_due: overview ? overview.amount_due : 0,
    };

    const startCallback = () => {
      setIsLoading(true);
    };

    const successCallback = data => {
      setIsLoading(false);

      if (data && data.status === 'paid') {
        confirmDialog.open({
          icon: 'success',
          title: 'Sucesso!',
          message: 'Recebemos o seu pagamento e a sua assinatura foi atualizada.',
          confirmButtonText: 'Entendi',
          showCancelButton: false,
        });

        onChangeView('DEFAULT');

        return;
      }

      if (data && data.status !== 'paid') {
        onChangeView('DEFAULT');

        return;
      }

      if (subscription.status === 'trialing') {
        onChangeView('DEFAULT');

        confirmDialog.open({
          icon: 'success',
          title: 'Sucesso!',
          message: 'Usuários adicionais incluídos no seu teste gratuito.',
          confirmButtonText: 'Entendi',
          showCancelButton: false,
        });

        return;
      }
    };

    const errorCallback = () => {
      setIsLoading(false);
    };

    onConfirmUserSeatsPurchase(params, startCallback, successCallback, errorCallback);
  }, [
    numberOfUsers,
    userSeatsPricing,
    subscription,
    overview,
    onChangeView,
    onConfirmUserSeatsPurchase,
  ]);

  if (!userSeatsPricing.canPurchaseUsers && isLoading) {
    return (
      <>
        <CustomBreadcrumb>
          <CustomBreadcrumbItem onClick={() => onChangeView('DEFAULT')}>
            Assinatura
          </CustomBreadcrumbItem>
          <CustomBreadcrumbItem active>Usuários adicionais</CustomBreadcrumbItem>
        </CustomBreadcrumb>
        <Row>
          <Col>
            <LoadingIcon />
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <CustomBreadcrumb>
        <CustomBreadcrumbItem onClick={() => onChangeView('DEFAULT')}>
          Assinatura
        </CustomBreadcrumbItem>
        <CustomBreadcrumbItem active>Usuários adicionais</CustomBreadcrumbItem>
      </CustomBreadcrumb>
      <Row>
        <Col xs={12}>
          <CustomCard>
            <CustomCardHeader>
              <h3>Adquirir usuários adicionais</h3>
            </CustomCardHeader>
            <CustomCardBody className="d-flex flex-column justify-content-center align-items-start">
              {userSeatsPricing && !userSeatsPricing.canPurchaseUsers && (
                <SimpleAlert variant="danger" className="w-100">
                  <h5>Não é possível adquirir usuários adicionais.</h5>
                  <p className="m-0 p-0">
                    <span>{userSeatsPricing.message}</span>
                  </p>
                </SimpleAlert>
              )}
              <Select
                value={usersOptions.find(option => option.value === numberOfUsers)}
                onChange={handleChange}
                options={usersOptions}
                isSearchable={false}
                className="w-100"
                disabled={isLoading || !userSeatsPricing.canPurchaseUsers}
              />
            </CustomCardBody>
          </CustomCard>
          {isLoading && <LoadingIcon className="mt-3 mb-3" />}
          {!isLoading && overview && (
            <Confirmation
              isLoading={isLoading}
              lines={[
                {
                  previousValue: `${subscription.users.max} lugares`,
                  currentValue: `${maxUsers + numberOfUsers} lugares *`,
                },
                {
                  previousValue: `${FORMATTERS.NUMBER(subscription.total_amount)} / ${CYCLE[subscription.plan.cycle_months]}`,
                  currentValue: `${FORMATTERS.NUMBER(overview.new_subscription_amount)} / ${CYCLE[subscription.plan.cycle_months]}`,
                  hint: 'O novo valor da assinatura da empresa, após a inclusão dos usuários adicionais.',
                },
                {
                  previousValue: 'Valor à pagar hoje',
                  currentValue: FORMATTERS.NUMBER(overview.amount_due),
                  highlight: true,
                  hint: `Valor proporcional referente a compra de ${numberOfUsers} ${userTerm}.`,
                  hidden: overview.amount_due <= 0,
                },
              ]}
              showConfirmButton
              confirmButtonText={
                overview.amount_due > 0
                  ? `Confirmar e pagar ${FORMATTERS.NUMBER(overview.amount_due)}`
                  : 'Confirmar alteração'
              }
              onConfirm={handleConfirm}
            />
          )}
        </Col>
      </Row>
      {!isLoading && overview && (
        <Row className="mt-3">
          <Col>
            <small>
              <ul className="list-unstyled">
                <li>
                  <i>
                    * O número de lugares disponíveis será atualizado após a compensação
                    do pagamento proporcional, se houver.
                  </i>
                </li>
                <li>
                  <i>
                    A compra de usuários adicionais, assim como os planos de assinatura, é
                    feita por empresa.
                  </i>
                </li>
              </ul>
            </small>
          </Col>
        </Row>
      )}
    </>
  );
}

AdditionalUsers.defaultProps = {
  activeCompany: {},
};

AdditionalUsers.propTypes = {
  onFetchUserSeatsPricing: PropTypes.func.isRequired,
  activeCompany: PropTypes.object,
  onChangeView: PropTypes.func,
  userSeatsPricing: PropTypes.object,
};

export default AdditionalUsers;
