import Swal from 'sweetalert2';
import has from 'lodash/has';

import FORMATTERS from 'helpers/formatters';
import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';

const confirmUserSeatsPurchase = (params, startCallback, callback, errorCallback) => {
  return dispatch => {
    let message = '';

    let hasAmountDue = has(params, 'amount_due') && params.amount_due > 0;
    let userTerm = params.quantity === 1 ? 'usuário adicional' : 'usuários adicionais';

    if (hasAmountDue) {
      message = `Ao adquirir <strong>${params.quantity} ${userTerm}</strong>, será gerada uma fatura no valor proporcional de <strong>${FORMATTERS.NUMBER(params.amount_due)}</strong>.
              Durante este período, sua assinatura será temporariamente marcada como <strong>Vencida</strong> até que o pagamento seja confirmado. <br /> <br />
              <strong>Importante:</strong> Caso o pagamento não seja realizado a assinatura será <strong>cancelada automaticamente</strong> devido à regras do sistema parceiro de pagamentos. <br /> <br />
              Deseja prosseguir e gerar a fatura adicional?`;
    } else {
      message = `Ao confirmar, a assinatura da empresa será alterada para incluir <strong>${params.quantity} ${userTerm}</strong>.<br /><br /> Deseja continuar?`;
    }

    confirmDialog.warning({
      title: 'Usuários adicionais',
      html: message,
      confirmButtonText: 'Sim, confirmar!',
      icon: 'question',
      input: hasAmountDue ? 'text' : undefined,
      inputAttributes: {
        style: 'width: 80%;',
      },
      dangerMode: true,
      inputPlaceholder: 'Digite "li e concordo" sem aspas',
      preConfirm: inputValue => {
        if (hasAmountDue && inputValue !== 'li e concordo') {
          Swal.showValidationMessage('Digite "li e concordo" sem aspas.');
        }
      },
      onConfirm: () => {
        dispatch(request());

        if (startCallback) {
          startCallback();
        }

        Swal.fire({
          title: 'Processando...',
          html: 'Aguarde enquanto processamos sua solicitação.',
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();

            service
              .confirmUserSeatsPurchase(params)
              .then(response => {
                const { data } = response;

                dispatch(success(data));

                if (callback) {
                  callback(data);
                }

                Swal.close();
              })
              .catch(error => {
                dispatch(failure(error));

                if (errorCallback) {
                  errorCallback(error);
                }

                Swal.close();
              });
          },
        });
      },
      onCancel: () => {
        errorCallback();
      },
    });
  };

  function request() {
    return {
      type: constants.CONFIRM_USER_SEATS_PURCHASE_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.CONFIRM_USER_SEATS_PURCHASE_SUCCESS,
      payload: {
        data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.CONFIRM_USER_SEATS_PURCHASE_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default confirmUserSeatsPurchase;
