import React, { useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, useFormikContext } from 'formik';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { RiFileExcel2Line } from 'react-icons/ri';

import { Button } from '_components/_core';
import { useMediaQuery, TRANSACTION_TYPE_SUB_TYPE_OPTIONS } from 'helpers';
import checkBlockedPeriod from 'helpers/checkBlockedPeriod';
import { TransactionForm } from '_components/_shared';
import { hasPermissions } from '_components/_shared/PermissionsGate/utilities';
import alertActions from '_store/_actions/alert.actions';

import PieChartReport from './components/PieChartReport/PieChartReportContainer';
import DRE from './components/DREContainer';
import TableReport from './components/TableReportContainer';
import CashflowByTypeReport from './components/CashflowByTypeReportContainer';
import LineChartReport from './components/LineChartReportContainer';
import PrintControls from './components/PrintControls';
import PrintOptions from './components/PrintOptions/PrintOptions';
import PerformanceReport from './components/PerformanceReport/PerformanceReport';
import { Provider } from './components/ReportContext';
import { Provider as PrintOptionsProvider } from './components/PrintOptionsContext';
import TagsReport from './components/TagsReport/TagsReport';
import Filters from './components/Filters/Filters';
import ReportSelect from './components/ReportSelect/ReportSelect';

import { exportToExcel } from './utilities';

const SubmitListener = ({ submitTrigger }) => {
  const formik = useFormikContext();
  const [lastValues, updateState] = React.useState(formik.values);

  const submitForm = useCallback(
    debounce(
      () => {
        formik.submitForm();
      },
      100,
      { maxWait: 100 },
    ),
    [],
  );

  React.useEffect(() => {
    const valuesEqualLastValues = isEqual(lastValues, formik.values);

    if (!valuesEqualLastValues) {
      updateState(formik.values);
    }

    if (!valuesEqualLastValues && formik.isValid) {
      submitForm();
    }
  }, [formik.values, formik.initialValues, lastValues, submitForm, formik.isValid]);

  React.useEffect(() => {
    if (submitTrigger) {
      submitForm();
    }
  }, [submitTrigger, submitForm]);

  return null;
};

function Reports({
  activeCompany,
  reports,
  selected_account_id,
  accounts,
  allAccounts,
  balance,
  onGenerateTransactionReport,
  onFetchBankAccounts,
  onFetchBankAccountBalance,
  onFetchCostsCenter,
  onFetchTags,
  onFetchRecipients,
  onFetchCategories,
  blockedPeriod,
}) {
  const { isMobile, isTablet, isDesktopMedium, isDesktopLarge, isDesktopExtraLarge } =
    useMediaQuery();

  const userPermissions = useSelector(
    state => state.userPermissions.permissions[state.auth.user.id],
  );
  const dispatch = useDispatch();

  const [submitTrigger, setSubmitTrigger] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState({});

  const avaialableTypeSubTypes = useMemo(() => {
    if (!selectedTransaction) {
      return TRANSACTION_TYPE_SUB_TYPE_OPTIONS;
    }

    return TRANSACTION_TYPE_SUB_TYPE_OPTIONS.filter(
      option => option.value !== 'TRANSFER::',
    );
  }, [selectedTransaction]);

  const handleEditTransaction = useCallback(
    transaction => {
      const { type, sub_type } = transaction || {};

      const permissionMap = {
        'INCOME-null': 'aba_recebimento_edit',
        'EXPENSE-FIXED_EXPENSE': 'aba_despesa_fixa_edit',
        'EXPENSE-VARIABLE_EXPENSE': 'aba_despesa_variavel_edit',
        'EXPENSE-PEOPLE': 'aba_pessoal_edit',
        'EXPENSE-TAXES': 'aba_imposto_edit',
        'TRANSFER-SENT': 'aba_transferencia_edit',
        'TRANSFER-RECEIVED': 'aba_transferencia_edit',
      };

      const permissionName = permissionMap[`${type}-${sub_type}`];

      const allowed = hasPermissions({
        permissions: [permissionName],
        userPermissions,
        type: 'all',
      });

      if (!allowed) {
        dispatch(
          alertActions.error('Você não tem permissão para editar este lançamento.'),
        );

        return;
      }

      const periodAllowed = checkBlockedPeriod(
        blockedPeriod,
        transaction.event_date,
        transaction.paid,
      );

      if (!periodAllowed) {
        dispatch(
          alertActions.error(
            'Não é possível editar um lançamento que está em um período bloqueado.',
          ),
        );

        return;
      }

      setSelectedTransaction(transaction);
      setIsOpen(true);
    },
    [userPermissions, dispatch, blockedPeriod],
  );

  const handleToggleForm = useCallback(() => {
    const newState = !isOpen;

    if (!newState) {
      setSelectedTransaction({});
    }

    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleExportToExcel = useCallback(
    values => {
      if (!values.report_name) {
        alert('Por favor, selecione um relatório antes de exportar');

        return;
      }

      const { start_date, end_date } = values;

      const formattedPeriod = `${DateTime.fromISO(start_date).toFormat('dd/MM/yyyy')} até ${DateTime.fromISO(end_date).toFormat('dd/MM/yyyy')}`;

      exportToExcel({
        report_name: values.report_name,
        reports,
        accounts,
        allAccounts,
        selected_tags: values.selected_tags || null,
        formattedPeriod,
        date_type: values.date_type,
      });
    },
    [reports, accounts, allAccounts],
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const commonProps = useMemo(
    () => ({
      isMobile,
      activeCompany,
      reports,
      accounts,
      allAccounts,
      balance,
      onGenerateTransactionReport,
      onFetchBankAccounts,
      onFetchBankAccountBalance,
      onFetchCostsCenter,
      onEditTransaction: handleEditTransaction,
    }),
    [
      isMobile,
      activeCompany,
      reports,
      accounts,
      allAccounts,
      balance,
      handleEditTransaction,
      onFetchBankAccounts,
      onFetchBankAccountBalance,
      onFetchCostsCenter,
      onGenerateTransactionReport,
    ],
  );

  const renderActiveTab = useCallback(
    ({ values, setFieldValue, setValues }) => {
      const { report_name, start_date, end_date, account_ids, cost_center_ids } = values;

      const formattedPeriod = `${DateTime.fromISO(start_date).toFormat('dd/MM/yyyy')} - ${DateTime.fromISO(end_date).toFormat('dd/MM/yyyy')}`;

      const formProps = {
        formattedPeriod,
        account_ids,
        cost_center_ids,
        values,
        setFieldValue,
        setValues,
      };

      switch (report_name) {
        case 'expenses_by_description':
          return (
            <PieChartReport
              name="expenses_by_description"
              {...commonProps}
              {...formProps}
              colorType="EXPENSE"
            />
          );
        case 'income_by_description':
          return (
            <PieChartReport
              name="income_by_description"
              {...commonProps}
              {...formProps}
              colorType="INCOME"
            />
          );
        case 'expenses_by_type':
          return (
            <PieChartReport
              name="expenses_by_type"
              {...commonProps}
              {...formProps}
              colorType="EXPENSE"
            />
          );
        case 'expenses_by_category':
          return (
            <PieChartReport
              name="expenses_by_category"
              {...commonProps}
              {...formProps}
              colorType="EXPENSE"
            />
          );
        case 'income_by_category':
          return (
            <PieChartReport
              name="income_by_category"
              {...commonProps}
              {...formProps}
              colorType="INCOME"
            />
          );
        case 'demonstrative_results':
          return <DRE name="demonstrative_results" {...commonProps} {...formProps} />;
        case 'cashflow_statement':
          return (
            <TableReport name="cashflow_statement" {...commonProps} {...formProps} />
          );
        case 'cashflow_statement_by_type':
          return (
            <CashflowByTypeReport
              name="cashflow_statement_by_type"
              {...commonProps}
              {...formProps}
            />
          );
        case 'monthly_performance':
          return (
            <PerformanceReport
              name="monthly_performance"
              {...commonProps}
              {...formProps}
            />
          );
        case 'yearly_performance':
          return (
            <PerformanceReport
              name="yearly_performance"
              {...commonProps}
              {...formProps}
            />
          );
        case 'expenses_by_recipient':
          return (
            <PieChartReport
              name="expenses_by_recipient"
              {...commonProps}
              {...formProps}
              colorType="EXPENSE"
            />
          );
        case 'incomes_by_recipient':
          return (
            <PieChartReport
              name="incomes_by_recipient"
              {...commonProps}
              {...formProps}
              colorType="INCOME"
            />
          );
        case 'historical_income_expenses':
          return (
            <LineChartReport
              name="historical_income_expenses"
              {...commonProps}
              {...formProps}
            />
          );
        case 'incomes_by_cost_center':
          return (
            <PieChartReport
              name="incomes_by_cost_center"
              {...commonProps}
              {...formProps}
              colorType="INCOME"
            />
          );
        case 'expenses_by_cost_center':
          return (
            <PieChartReport
              name="expenses_by_cost_center"
              {...commonProps}
              {...formProps}
              colorType="EXPENSE"
            />
          );
        case 'expenses_by_day':
          return (
            <LineChartReport name="expenses_by_day" {...commonProps} {...formProps} />
          );
        case 'income_by_day':
          return <LineChartReport name="income_by_day" {...commonProps} {...formProps} />;
        case 'income_by_tags':
          return (
            <>
              <TagsReport
                name="income_by_tags"
                {...commonProps}
                {...formProps}
                colorType="INCOME"
              />
            </>
          );
        case 'expenses_by_tags':
          return (
            <>
              <TagsReport
                name="expenses_by_tags"
                {...commonProps}
                {...formProps}
                colorType="EXPENSE"
              />
            </>
          );
        default:
          return null;
      }
    },
    [commonProps],
  );

  useEffect(() => {
    onFetchBankAccounts();
    onFetchCostsCenter();
    onFetchTags();
    onFetchRecipients();
    onFetchCategories();
  }, [
    activeCompany,
    onFetchBankAccounts,
    onFetchCostsCenter,
    onFetchTags,
    onFetchRecipients,
    onFetchCategories,
  ]);

  const handleFilter = useCallback(
    values => {
      onGenerateTransactionReport(values, activeCompany);
    },
    [onGenerateTransactionReport, activeCompany],
  );

  const handleFinishEditing = useCallback(() => {
    setSubmitTrigger(DateTime.now().toMillis());

    handleToggleForm();
  }, [handleToggleForm]);

  const handleFinishEditingSplit = useCallback(() => {
    setSubmitTrigger(DateTime.now().toMillis());
  }, []);

  const handleReportSelected = useCallback((item, setValues) => {
    setValues(prev => ({
      ...prev,
      report_name: item.tab,
      category_id: null,
      category_name: null,
      sub_type: null,
      cost_center_id: null,
      cost_center_name: null,
      tag_ids: [],
      recipient_id: null,
      selected_tags: [],
    }));
  }, []);

  const renderContent = useCallback(
    (values, setValues, setFieldValue) => {
      if (isMobile || isTablet) {
        return (
          <>
            <Col xs={12}>
              <Row
                style={{
                  position: 'sticky',
                  top: 'calc(48px + var(--actions-bar-height, 0px))',
                  zIndex: 1,
                  backgroundColor: '#fff',
                  padding: '10px 0px',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                  borderRadius: '8px',
                }}
                className="no-print"
              >
                <Col xs={12}>
                  <div className="d-flex justify-content-between align-items-center">
                    <ReportSelect
                      isMobile={isMobile}
                      isTablet={isTablet}
                      isDesktopMedium={isDesktopMedium}
                      isDesktopLarge={isDesktopLarge}
                      isDesktopExtraLarge={isDesktopExtraLarge}
                      values={values}
                      setValues={setValues}
                      onReportSelect={handleReportSelected}
                    />
                    <span className="d-flex">
                      <ButtonGroup>
                        <Filters
                          isMobile={isMobile}
                          isTablet={isTablet}
                          isDesktopMedium={isDesktopMedium}
                          isDesktopLarge={isDesktopLarge}
                          isDesktopExtraLarge={isDesktopExtraLarge}
                        />
                        <PrintControls report_name={values.report_name} isMobile />
                        <Button
                          size="sm"
                          variant="default"
                          className="d-flex justify-content-center align-items-center"
                          onClick={() => handleExportToExcel(values)}
                        >
                          <RiFileExcel2Line size="1.2em" />
                        </Button>
                        <PrintOptions isMobile />
                      </ButtonGroup>
                    </span>
                  </div>
                  <div />
                </Col>
              </Row>
              <Row>
                <Col className="mt-3 report-wrapper-column" xs={12}>
                  {renderActiveTab({
                    values,
                    setFieldValue,
                    setValues,
                  })}
                </Col>
              </Row>
            </Col>
          </>
        );
      }

      return (
        <>
          <Col lg={isDesktopExtraLarge ? 2 : 3} className="no-print">
            <ReportSelect
              isMobile={isMobile}
              isTablet={isTablet}
              isDesktopMedium={isDesktopMedium}
              isDesktopLarge={isDesktopLarge}
              isDesktopExtraLarge={isDesktopExtraLarge}
              values={values}
              setValues={setValues}
              onReportSelect={handleReportSelected}
            />
          </Col>
          <Col lg={isDesktopExtraLarge ? 10 : 9} className="report-wrapper-column">
            <Filters
              isMobile={isMobile}
              isTablet={isTablet}
              isDesktopMedium={isDesktopMedium}
              isDesktopLarge={isDesktopLarge}
              isDesktopExtraLarge={isDesktopExtraLarge}
              printControls={<PrintControls report_name={values.report_name} />}
              printOptions={<PrintOptions />}
              generateExcel={handleExportToExcel}
              values={values}
            />
            {renderActiveTab({
              values,
              setFieldValue,
              setValues,
            })}
          </Col>
        </>
      );
    },
    [
      isMobile,
      isTablet,
      isDesktopMedium,
      isDesktopLarge,
      isDesktopExtraLarge,
      renderActiveTab,
      handleReportSelected,
      handleExportToExcel,
    ],
  );

  return (
    <Container fluid className="content-wrapper">
      <ReactTooltip />
      <Provider>
        <PrintOptionsProvider>
          <TransactionForm
            isOpen={isOpen}
            transaction={selectedTransaction}
            onToggleForm={handleToggleForm}
            availableTypeSubTypes={avaialableTypeSubTypes}
            onAfterSaveCallback={handleFinishEditing}
            onAfterSaveSplitCallback={handleFinishEditingSplit}
            mode="report-edit"
            transaction_id={selectedTransaction.id}
          />
          <Formik
            initialValues={{
              start_date: DateTime.now().startOf('month').toISODate(),
              end_date: DateTime.now().endOf('month').startOf('day').toISODate(),
              report_name: null,
              account_ids: [selected_account_id],
              selected_tags: [],
              cost_center_ids: [],
              tag_ids: [],
              category_id: null,
              category_name: null,
              sub_type: null,
              cost_center_id: null,
              cost_center_name: null,
              recipient_id: null,
              paid_status: ['paid', 'unpaid'],
              date_type: 'event_date',
            }}
            onSubmit={handleFilter}
            enableReinitialize
          >
            {({ values, setFieldValue, setValues }) => (
              <Row>
                <SubmitListener submitTrigger={submitTrigger} />
                {renderContent(values, setValues, setFieldValue)}
              </Row>
            )}
          </Formik>
        </PrintOptionsProvider>
      </Provider>
    </Container>
  );
}

Reports.defaultProps = {
  reports: {},
  tags: [],
  accounts: [],
  balance: {},
  allAccounts: [],
};

Reports.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  activeCompany: PropTypes.object,
  isLoading: PropTypes.bool,
  reports: PropTypes.object,
  tags: PropTypes.array,
  accounts: PropTypes.array,
  allAccounts: PropTypes.array,
  balance: PropTypes.object,
  onGenerateTransactionReport: PropTypes.func,
  onFetchBankAccounts: PropTypes.func,
  onFetchBankAccountBalance: PropTypes.func,
  onFetchCostsCenter: PropTypes.func,
  onFetchCategories: PropTypes.func,
  onFetchRecipients: PropTypes.func,
  selected_account_id: PropTypes.string,
  onFetchTags: PropTypes.func,
};

export default Reports;
