import React, { useCallback } from 'react';
import { IoHelpCircleOutline } from 'react-icons/io5';
import { LuArrowUpRight } from 'react-icons/lu';

import { Button, FloatingCard } from '_components/_core';

import { CustomFilterButton, StyledIframe } from './styles';

function HelpCenterDialog() {
  const [isOpen, setIsOpen] = React.useState(false);

  const handleOutsideRedirect = useCallback(() => {
    window.open('https://ajuda.zenply.com.br', '_blank');
  }, []);

  return (
    <>
      {/* <HelpButton onClick={() => setIsOpen(true)}>
        <LuHelpCircle size="1.2em" className="text-white" />
      </HelpButton> */}
      <CustomFilterButton onClick={() => setIsOpen(true)} variant="link">
        <IoHelpCircleOutline size="1.4em" />
      </CustomFilterButton>
      <FloatingCard
        title="Central de Ajuda"
        fullHeight
        isVisible={isOpen}
        onToggleVisibility={() => setIsOpen(!isOpen)}
        bodyClassName="p-0"
        withCloseButton
        overflowHidden
        side="right"
        width={900}
        footerContent={
          <div
            style={{ flex: 1 }}
            className="d-flex justify-content-between align-items-center"
          >
            <Button
              variant="link"
              className="m-0 p-0 d-flex justify-content-center align-items-center"
              onClick={handleOutsideRedirect}
            >
              Ir para central de ajuda completa
              <LuArrowUpRight className="ml-2" />
            </Button>

            <Button variant="outline-dark" onClick={() => setIsOpen(false)}>
              Fechar
            </Button>
          </div>
        }
      >
        <StyledIframe
          src="https://zenply.crisp.help/pt-br/"
          title="Ajuda"
          width="100%"
          height="100%"
        />
      </FloatingCard>
    </>
  );
}

export default HelpCenterDialog;
