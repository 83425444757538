import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { LuChevronLeft } from 'react-icons/lu';

import { LoadingIcon } from '_components/_shared';
import { Button } from '_components/_core';

import InvoiceHero from './components/InvoiceHero';
import InvoiceHeader from './components/InvoiceHeader';
import InvoiceNotaFiscal from './components/InvoiceNotaFiscal';
import InvoicePayment from './components/InvoicePayment';

function InvoiceDetails({
  originView,
  invoiceId,
  onChangeView,
  onFetchInvoice,
  onRefreshPaymentData,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [invoice, setInvoice] = useState(null);

  const handleRefreshPaymentData = useCallback(() => {
    setIsRefreshing(true);

    const params = {
      invoice_id: invoice.id,
    };

    const successCallback = data => {
      const { invoice: updatedInvoice } = data;

      setIsRefreshing(false);
      setInvoice(updatedInvoice);
    };

    const errorCallback = () => {
      setIsRefreshing(false);
    };

    onRefreshPaymentData(params, successCallback, errorCallback);
  }, [invoice, onRefreshPaymentData]);

  useEffect(() => {
    if (!invoiceId) {
      return;
    }

    setIsLoading(true);

    onFetchInvoice(invoiceId, foundInvoice => {
      setInvoice(foundInvoice);

      setIsLoading(false);
    });
  }, [onFetchInvoice, invoiceId]);

  return (
    <>
      <Button
        variant="link"
        className="m-0 p-0 d-flex justify-content-center mb-3"
        onClick={() => onChangeView(originView)}
      >
        <LuChevronLeft className="mr-2" />
        Voltar para faturas
      </Button>
      {isLoading && <LoadingIcon text="Carregando..." />}
      {invoice && (
        <>
          <InvoiceHeader invoice={invoice} />
          <InvoiceHero invoice={invoice} />
          <InvoicePayment
            invoice={invoice}
            isRefreshing={isRefreshing}
            onRefreshPayment={handleRefreshPaymentData}
            onSetInvoice={setInvoice}
            onFetchInvoice={onFetchInvoice}
          />
          <InvoiceNotaFiscal invoice={invoice} />
        </>
      )}
    </>
  );
}

InvoiceDetails.defaultProps = {
  payments: [],
  activeCompany: {},
  invoice: null,
  originView: 'DEFAULT',
};

InvoiceDetails.propTypes = {
  payments: PropTypes.array,
  onFetchPayments: PropTypes.func,
  activeCompany: PropTypes.object,
  onChangeView: PropTypes.func,
  invoice: PropTypes.object,
  originView: PropTypes.string,
  onFetchActiveCompany: PropTypes.func,
  onFetchCompanies: PropTypes.func,
  onUpdateStoreSubscription: PropTypes.func,
  onRefreshPaymentData: PropTypes.func,
  onFetchInvoice: PropTypes.func.isRequired,
};

export default InvoiceDetails;
