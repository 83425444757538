import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { LuArrowRight, LuCopy, LuPrinter, LuRefreshCcw } from 'react-icons/lu';
import { FaBarcode } from 'react-icons/fa';

import FORMATTERS from 'helpers/formatters';
import { Button } from '_components/_core';

import { RepeatInvoiceChargeModal } from '../..';

import {
  CustomCard,
  CustomCardBody,
  CustomCardFooter,
  Label,
  Value,
} from '../../../../../styles';

function SubscriptionRenewalDateCard({ subscription, onChangeView }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { pending_invoice, dates } = subscription;
  const { cancel_at } = dates || {};

  const renewalDate = DateTime.fromISO(subscription.dates.renew_at, { zone: 'utc' })
    .setZone('America/Sao_Paulo')
    .toFormat('dd/MM/yyyy');

  const formatDate = useCallback(date => {
    return DateTime.fromISO(date).setLocale('pt-BR').toFormat('dd/MM/yyyy');
  }, []);

  const renderViewDetails = useCallback(() => {
    return (
      <Button
        variant="link"
        className="m-0 p-0 d-flex align-items-center justify-content-center"
        onClick={() => {
          onChangeView('INVOICE_DETAILS', {
            invoiceId: pending_invoice.id,
            originView: 'DEFAULT',
          });
        }}
      >
        Ver detalhes
        <LuArrowRight className="ml-1" />
      </Button>
    );
  }, [pending_invoice, onChangeView]);

  const renderPrintBoleto = useCallback(() => {
    return (
      <Button
        variant="link"
        className="m-0 p-0 d-flex align-items-center justify-content-center"
        size="sm"
        onClick={() => {
          window.open(pending_invoice.metadata.pdf, '_blank');
        }}
      >
        <LuPrinter className="mr-2" />
        Imprimir boleto
      </Button>
    );
  }, [pending_invoice]);

  const renderCopyBoleto = useCallback(() => {
    return (
      <Button
        variant="link"
        className="m-0 p-0 ml-3 d-flex align-items-center justify-content-center"
        size="sm"
        onClick={async e => {
          e.preventDefault();
          e.stopPropagation();

          await navigator.clipboard.writeText(
            pending_invoice.metadata.boleto_identification_field,
          );
        }}
      >
        <LuCopy className="mr-2" />
        Copiar linha digitável
      </Button>
    );
  }, [pending_invoice]);

  const renderCardInfo = useCallback(() => {
    const { payment_method_description } = pending_invoice;

    return (
      <div>
        <small className="text-danger">
          Pagamento com{' '}
          <span className="text-capitalize">{payment_method_description}</span> recusado
        </small>
      </div>
    );
  }, [pending_invoice]);

  const renderRetryButton = useCallback(
    (text = 'Tentar novamente', icon = 'refresh', variant = 'white') => {
      return (
        <Button
          variant={variant}
          className="p-1 pl-2 pr-2 d-flex align-items-center justify-content-center"
          size="sm"
          onClick={() => setIsModalOpen(true)}
        >
          {text}
          {icon === 'refresh' && <LuRefreshCcw className="ml-2" />}
          {icon === 'boleto' && <FaBarcode className="ml-2" />}
        </Button>
      );
    },
    [],
  );

  const renderGenerateBoletoButton = useCallback(() => {
    return (
      <Button
        variant="white"
        className="p-1 pl-2 pr-2 d-flex align-items-center justify-content-center"
        size="sm"
        onClick={() => {}}
      >
        Gerar boleto <LuRefreshCcw className="ml-2" />
      </Button>
    );
  }, []);

  const info = useMemo(() => {
    const { status, pending_invoice } = subscription;

    let title = '';
    let content = () => '';
    let sideContent = () => '';
    let footerContent = () => null;

    let hasPendingInvoice = pending_invoice && pending_invoice.id;
    let isCancelling = dates && cancel_at;

    switch (status) {
      case 'active':
        title = isCancelling
          ? 'Sem faturas futuras'
          : hasPendingInvoice
            ? 'Fatura disponível'
            : 'Próximo vencimento';
        content = () => (
          <>
            {!hasPendingInvoice && (
              <>
                {isCancelling && <>-</>}
                {!isCancelling && (
                  <>
                    {renewalDate}
                    <small
                      className="ml-2"
                      style={{
                        fontSize: '12px',
                      }}
                    >
                      (
                      {DateTime.fromISO(subscription.dates.renew_at)
                        .setLocale('pt-BR')
                        .toRelative()}
                      ) &nbsp;
                    </small>
                  </>
                )}
              </>
            )}
            {hasPendingInvoice && (
              <>
                <span className="d-flex justify-content-center align-items-center text-warning">
                  {FORMATTERS.NUMBER(pending_invoice.total_amount)}
                </span>
              </>
            )}
          </>
        );
        sideContent = () => <>{hasPendingInvoice && renderViewDetails()}</>;

        if (hasPendingInvoice) {
          footerContent = () => (
            <>
              {pending_invoice.payment_method === 'UNDEFINED' && (
                <>
                  <div
                    style={{ flex: 1 }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <small className="text-danger">
                      {`Vence em ${FORMATTERS.DATE_DDMMYYYY(pending_invoice.due_date)}`}
                    </small>
                    {renderRetryButton('Pagar fatura', 'boleto')}
                  </div>
                </>
              )}
              {pending_invoice.payment_method === 'BOLETO' && (
                <>
                  <div
                    style={{ flex: 1 }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <small className="text-danger">
                      {`Vence em ${FORMATTERS.DATE_DDMMYYYY(pending_invoice.due_date)}`}
                    </small>
                    {renderGenerateBoletoButton()}
                  </div>
                </>
              )}
              {pending_invoice.payment_method === 'CREDIT_CARD' && (
                <>
                  <div
                    style={{ flex: 1 }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <small className="text-danger">
                      {`Vence em ${FORMATTERS.DATE_DDMMYYYY(pending_invoice.due_date)}`}
                    </small>
                    {renderRetryButton('Pagar fatura', 'boleto')}
                  </div>
                </>
              )}
            </>
          );
        }
        break;
      case 'canceled':
      case 'incomplete_expired':
        title = 'Cancelada em';
        content = () => `${formatDate(subscription.dates.canceled_at)}`;
        break;
      case 'trialing':
        title = 'Teste gratuito';
        content = () => `Até ${formatDate(subscription.dates.trial_end_at)}`;
        break;
      case 'trial-ended':
        title = 'Teste encerrado em';
        content = () => `${formatDate(subscription.dates.trial_end_at)}`;
        break;
      case 'past_due':
        title = hasPendingInvoice ? `Fatura pendente` : 'Assinatura vencida';
        content = () => (
          <>
            <span className="d-flex justify-content-center align-items-center text-warning">
              {FORMATTERS.NUMBER(pending_invoice.total_amount)}
            </span>
          </>
        );
        sideContent = () => <>{renderViewDetails()}</>;
        footerContent = () => (
          <>
            {pending_invoice.payment_method === 'BOLETO' && (
              <>
                {pending_invoice.status === 'open' && (
                  <div className="d-flex justify-content-start align-items-center pt-1">
                    {renderPrintBoleto()}
                    {renderCopyBoleto()}
                  </div>
                )}
                {pending_invoice.status === 'overdue' && (
                  <div
                    style={{ flex: 1 }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <small className="text-danger">
                      {`Venceu em ${FORMATTERS.DATE_DDMMYYYY(pending_invoice.due_date)}`}
                    </small>
                    {renderRetryButton('Gerar novo boleto')}
                  </div>
                )}
              </>
            )}
            {pending_invoice.payment_method === 'CREDIT_CARD' && (
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ flex: 1 }}
              >
                {renderCardInfo()}
                {renderRetryButton()}
              </div>
            )}
          </>
        );
        break;
      case 'incomplete':
        title = hasPendingInvoice ? `Fatura pendente` : 'Assinatura incompleta';
        content = () => (
          <>
            <span className="d-flex justify-content-center align-items-center">
              {FORMATTERS.NUMBER(pending_invoice.total_amount)}
            </span>
          </>
        );
        sideContent = () => <>{renderViewDetails()}</>;
        footerContent = () => (
          <>
            {pending_invoice.payment_method === 'BOLETO' && (
              <>
                {pending_invoice.status === 'open' && (
                  <div className="d-flex justify-content-start align-items-center pt-1">
                    {renderPrintBoleto()}
                    {renderCopyBoleto()}
                  </div>
                )}
                {pending_invoice.status === 'overdue' && (
                  <div
                    style={{ flex: 1 }}
                    className="d-flex justify-content-between align-items-center"
                  >
                    <small className="text-danger">
                      {`Venceu em ${FORMATTERS.DATE_DDMMYYYY(pending_invoice.due_date)}`}
                    </small>
                    {renderRetryButton('Gerar novo boleto')}
                  </div>
                )}
              </>
            )}
          </>
        );
        break;
      default:
        break;
    }

    return {
      title,
      content,
      sideContent,
      footerContent,
    };
  }, [
    formatDate,
    renewalDate,
    renderPrintBoleto,
    renderCopyBoleto,
    renderViewDetails,
    renderCardInfo,
    renderRetryButton,
    subscription,
    dates,
    cancel_at,
    renderGenerateBoletoButton,
  ]);

  if (!subscription) {
    return null;
  }

  return (
    <CustomCard>
      <RepeatInvoiceChargeModal
        invoice={subscription.pending_invoice}
        isVisible={isModalOpen}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
      />
      <CustomCardBody>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Label>{info.title}</Label>
            <Value variant="large" className="mt-2">
              {info.content()}
            </Value>
          </div>
          <div>{info.sideContent()}</div>
        </div>
      </CustomCardBody>
      {info.footerContent() && (
        <CustomCardFooter>{info.footerContent()}</CustomCardFooter>
      )}
    </CustomCard>
  );
}

SubscriptionRenewalDateCard.defaultProps = {
  subscription: null,
};

SubscriptionRenewalDateCard.propTypes = {
  subscription: PropTypes.object,
  onChangeView: PropTypes.func.isRequired,
};

export default SubscriptionRenewalDateCard;
