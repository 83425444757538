import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { IoArrowForward } from 'react-icons/io5';
import { useHistory, withRouter } from 'react-router-dom';

import { getQueryVariable } from 'helpers';
import { Button, FormTextField, FormPasswordField } from '_components/_core';
import FORMATTERS from 'helpers/formatters';

import { signUpFormSchema } from './utilities';
import { SectionHeader, Container, Card, Title, Text } from './styles';
import { LoadingIcon } from '_components/_shared';

const CompleteSignUpForm = ({ isLoading, onCompleteSignUp, onValidateInviteToken }) => {
  const history = useHistory();

  const [tokenValidation, setTokenValidation] = useState(null);

  useEffect(() => {
    const token = getQueryVariable('token');
    const email = getQueryVariable('email');

    if (token && email) {
      const params = {
        token,
        email,
      };

      onValidateInviteToken(params, found => {
        setTokenValidation(found);
      });
    }
  }, [onValidateInviteToken]);

  const handleCreateAccount = useCallback(
    values => {
      const user = {
        ...values,
        document_number: FORMATTERS.CLEAN_CPF_CNPJ(values.document_number),
      };

      onCompleteSignUp(user);
    },
    [onCompleteSignUp],
  );

  return (
    <>
      <Container>
        {!tokenValidation && (
          <Card>
            <LoadingIcon text="Aguarde..." />
          </Card>
        )}
        {tokenValidation && tokenValidation.valid && (
          <>
            {tokenValidation.new && (
              <Card>
                <Title>Complete seu cadastro.</Title>
                <Text>Para acessar o Zenply, você precisa completar seu cadastro.</Text>
                <Formik
                  enableReinitialize
                  initialValues={{
                    first_name: '',
                    last_name: '',
                    document_type: 'PF',
                    document_number: '00000000000',
                    email: getQueryVariable('email'),
                    email_confirmation: getQueryVariable('email'),
                    token: getQueryVariable('token'),
                    password: '',
                    password_confirmation: '',
                  }}
                  validationSchema={signUpFormSchema}
                  onSubmit={handleCreateAccount}
                >
                  {({ handleSubmit, isValid }) => (
                    <div className="mt-3">
                      <Form.Row>
                        <Form.Group as={Col} sm="12" md="6">
                          <Form.Label>Nome</Form.Label>
                          <FormTextField
                            name="first_name"
                            autoComplete="off"
                            placeholder="Nome"
                          />
                        </Form.Group>
                        <Form.Group as={Col} sm="12" md="6">
                          <Form.Label>Sobrenome</Form.Label>
                          <FormTextField
                            name="last_name"
                            autoComplete="off"
                            placeholder="Sobrenome"
                          />
                        </Form.Group>
                      </Form.Row>
                      <SectionHeader className="mt-3">Dados de Acesso</SectionHeader>
                      <hr className="mt-2" />
                      <Form.Row>
                        <Form.Group as={Col} xs={12} xl={6}>
                          <Form.Label>E-mail</Form.Label>
                          <FormTextField name="email" placeholder="E-mail" disabled />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} xl={6}>
                          <Form.Label>Confirmar E-mail</Form.Label>
                          <FormTextField
                            name="email_confirmation"
                            autoComplete="off"
                            placeholder="E-mail"
                            disabled
                          />
                        </Form.Group>
                      </Form.Row>
                      <Form.Row>
                        <Form.Group as={Col} xs={12} xl={6}>
                          <Form.Label>Senha</Form.Label>
                          <FormPasswordField
                            name="password"
                            autoComplete="off"
                            type="password"
                            placeholder="Criar uma senha"
                          />
                        </Form.Group>
                        <Form.Group as={Col} xs={12} xl={6}>
                          <Form.Label>Confirmar Senha</Form.Label>
                          <FormPasswordField
                            name="password_confirmation"
                            autoComplete="off"
                            type="password"
                            placeholder="Confirmar senha"
                          />
                        </Form.Group>
                      </Form.Row>
                      <Button
                        icon
                        type="button"
                        variant="success-2"
                        isLoading={isLoading}
                        disabled={!isValid || isLoading}
                        onClick={handleSubmit}
                      >
                        Cadastrar
                        <IoArrowForward />
                      </Button>
                    </div>
                  )}
                </Formik>
              </Card>
            )}
            {!tokenValidation.new && (
              <Card className="text-center">
                <Title>Cadastro confirmado!</Title>
                <Text className="mt-1">
                  Você já completou seu cadastro anteriormente, então agora pode acessar o
                  Zenply para aceitar este convite!
                </Text>
                <Button
                  variant="success-2"
                  className="mt-3"
                  onClick={() =>
                    history.push(
                      `/entrar?token=${getQueryVariable('token')}&email=${getQueryVariable('email')}`,
                    )
                  }
                >
                  Ir para Login
                </Button>
              </Card>
            )}
          </>
        )}
        {tokenValidation && !tokenValidation.valid && (
          <Card>
            <Title className="m-0 p-0">
              {tokenValidation.reason || 'Convite inválido ou expirado.'}
            </Title>
            <Button
              variant="success-2"
              className="mt-3"
              onClick={() => history.push('/entrar')}
            >
              Ir para Login
            </Button>
          </Card>
        )}
      </Container>
    </>
  );
};

CompleteSignUpForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onCompleteSignUp: PropTypes.func.isRequired,
  onValidateInviteToken: PropTypes.func.isRequired,
};

export default withRouter(CompleteSignUpForm);
