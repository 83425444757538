import styled from 'styled-components';

export const ActiveCompany = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;

  h4 {
    font-size: 18px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  small {
    font-size: 12px;
    color: rgb(101, 111, 125);
  }

  .edit-button {
    align-self: center;
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
