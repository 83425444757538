import React, { useCallback } from 'react';
import { Formik } from 'formik';
import { Form, Col, Row } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import { FaExclamationCircle } from 'react-icons/fa';

import { Button, FormRadioField, FormTextAreaField, Popover } from '_components/_core';
import FORMATTERS from 'helpers/formatters';

import { ValidationSchema } from './utilities';
import {
  CustomBreadcrumb,
  CustomBreadcrumbItem,
  CustomCard,
  CustomCardBody,
  Value,
} from '../../../../styles';
import { CompanyAvatar, CompanyListItem, PlanName, SubscriptionInfo } from './styles';

const CYCLE = {
  1: 'mensal',
  6: 'semestral',
  12: 'anual',
};

function CancelSubscription({
  onCancelSubscription,
  onChangeView,
  subscription,
  onFetchActiveCompany,
  onFetchCompanies,
}) {
  const handleCancelSubscription = useCallback(
    (values, { setSubmitting }) => {
      const params = {
        reason: values.reason,
        comment: values.comment,
        renew_at: subscription.dates.renew_at,
      };

      const sucessCallback = () => {
        setSubmitting(false);

        onChangeView('DEFAULT');
        onFetchActiveCompany();
        onFetchCompanies();
      };

      const errorCallback = () => {
        setSubmitting(false);
      };

      onCancelSubscription(params, sucessCallback, errorCallback);
    },
    [
      onCancelSubscription,
      onChangeView,
      onFetchActiveCompany,
      onFetchCompanies,
      subscription,
    ],
  );

  const renderAvatar = useCallback((company, size = 'normal') => {
    if (!company) {
      return null;
    }

    if (company.company_avatar_url) {
      return <CompanyAvatar src={company.company_avatar_url} size={size} />;
    }

    return (
      <div className="avatar">{`${company.trading_name.charAt(0).toUpperCase()}`}</div>
    );
  }, []);

  const renderList = useCallback(
    (companies, withMarginTop = false) => {
      if (isEmpty(companies)) {
        return <small className="text-muted">Nenhuma empresa encontrada</small>;
      }

      return (
        <div className={withMarginTop ? 'mt-1' : 'mt-0'}>
          {companies.map(company => (
            <CompanyListItem key={company.id} className="p-2 d-flex align-items-center">
              {renderAvatar(company)}
              <div
                className="ml-2 d-flex flex-column align-items-start justify-content-center"
                style={{ flex: 1 }}
              >
                <strong className="d-flex justify-content-center align-items-center">
                  {company.trading_name.toLowerCase()}
                </strong>
                <SubscriptionInfo>
                  <PlanName>{FORMATTERS.CPF_CNPJ(company.document_number)}</PlanName>
                </SubscriptionInfo>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                &nbsp;
              </div>
            </CompanyListItem>
          ))}
        </div>
      );
    },
    [renderAvatar],
  );

  const handleGoBack = useCallback(() => {
    onChangeView('DEFAULT');
  }, [onChangeView]);

  return (
    <>
      <CustomBreadcrumb>
        <CustomBreadcrumbItem onClick={() => onChangeView('DEFAULT')}>
          Assinatura
        </CustomBreadcrumbItem>
        <CustomBreadcrumbItem active>Cancelar assinatura</CustomBreadcrumbItem>
      </CustomBreadcrumb>
      <Row>
        <Col>
          <CustomCard>
            <CustomCardBody>
              <Form>
                <Form.Group>
                  <Form.Label>Sua assinatura atual</Form.Label>
                  <Value variant="large">
                    {FORMATTERS.NUMBER(subscription.total_amount)}&nbsp;
                    <small>{CYCLE[subscription.plan.cycle_months]}</small>
                  </Value>
                </Form.Group>
                {subscription && !isEmpty(subscription.related_companies) && (
                  <>
                    <hr />
                    <Form.Group className="mb-0">
                      <Form.Label className="d-flex justify-content-between align-items-center w-100">
                        <div style={{ flex: 1 }}>Empresas afetadas</div>
                        <Popover
                          trigger="hover"
                          content={
                            <p className="p-0 m-0">
                              Após o cancelamento, não será possível movimentar as
                              empresas abaixo.
                            </p>
                          }
                          placement="bottom-start"
                        >
                          <div className="d-flex align-items-center">
                            <FaExclamationCircle className="ml-auto text-warning" />
                          </div>
                        </Popover>
                      </Form.Label>
                      <div className="w-100">
                        {renderList(subscription.related_companies)}
                      </div>
                    </Form.Group>
                  </>
                )}
              </Form>
            </CustomCardBody>
          </CustomCard>
        </Col>
        <Col>
          <CustomCard>
            <CustomCardBody>
              <Formik
                initialValues={{
                  reason: '',
                  comment: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={handleCancelSubscription}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Form>
                    <Form.Row>
                      <Form.Group as={Col} xs={12} md={12}>
                        <Form.Label className="mb-3">Motivo do cancelamento:</Form.Label>
                        <FormRadioField
                          name="reason"
                          options={[
                            { value: 'unused', label: 'Não preciso mais' },
                            { value: 'too_expensive', label: 'É muito caro' },
                            { value: 'too_complex', label: 'É difícil de usar' },
                            {
                              value: 'switched_service',
                              label: 'Encontrei uma alternativa',
                            },
                            {
                              value: 'missing_features',
                              label: 'Não encontrei uma funcionalidade',
                            },
                            { value: 'other', label: 'Outro motivo' },
                          ]}
                          className={
                            'd-flex flex-column justify-content-start align-items-start'
                          }
                        />
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group as={Col} xs={12} md={12}>
                        <Form.Label>Comentário</Form.Label>
                        <FormTextAreaField
                          name="comment"
                          rows={3}
                          placeholder="Tem algum feedback adicional?"
                        />
                      </Form.Group>
                    </Form.Row>
                    <Button
                      onClick={handleSubmit}
                      variant="inverse-danger"
                      disabled={isSubmitting}
                    >
                      Cancelar assinatura
                    </Button>
                    <Button className="ml-2" variant="default" onClick={handleGoBack}>
                      Voltar
                    </Button>
                  </Form>
                )}
              </Formik>
            </CustomCardBody>
          </CustomCard>
        </Col>
      </Row>
    </>
  );
}

export default CancelSubscription;
