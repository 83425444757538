import HttpService from './http.service';

export default {
  fetchUserNotifications,
  readNotification,
  archiveNotification,
};

async function fetchUserNotifications(params) {
  return HttpService.get('/notifications', params);
}

async function readNotification(id) {
  return HttpService.put(`/notifications/${id}/read`);
}

async function archiveNotification(id) {
  return HttpService.put(`/notifications/${id}/archive`);
}
