import React, { useCallback, useMemo } from 'react';
import { LuChevronRight, LuCopy, LuPrinter, LuRefreshCcw } from 'react-icons/lu';
import { useHistory } from 'react-router-dom';

import { Button } from '_components/_core';

const BUTTON_TEXT = {
  trialing: 'Assinar',
  'trial-ended': 'Assinar',
  canceled: 'Assinar',
  incomplete_expired: 'Assinar',

  past_due: 'Ver detalhes',
  incomplete: 'Ver detalhes',
  active: 'Ver detalhes',
};

function Actions({ activeCompany, onModalToggle }) {
  const { subscription, pending_invoice } = activeCompany || {};
  const { status } = subscription || {};

  const history = useHistory();

  const noActionStatuses = ['trialing', 'trial-ended', 'canceled', 'incomplete_expired'];
  const withActionStatuses = ['active', 'past_due', 'incomplete'];

  const hasPendingInvoice = useMemo(() => {
    return pending_invoice && pending_invoice.id;
  }, [pending_invoice]);

  const handleCopy = useCallback(
    async e => {
      e.preventDefault();
      e.stopPropagation();

      const { metadata } = pending_invoice;
      const { boleto_identification_field } = metadata || {};

      await navigator.clipboard.writeText(boleto_identification_field);
    },
    [pending_invoice],
  );

  const handlePrintBoleto = useCallback(() => {
    const { metadata } = pending_invoice;
    const { pdf } = metadata || {};

    window.open(pdf, '_blank');
  }, [pending_invoice]);

  const handleRedirect = useCallback(() => {
    history.push('/configuracoes/assinatura');
  }, [history]);

  const handleGoToInvoice = useCallback(() => {
    history.push(
      `/configuracoes/assinatura?view=INVOICE_DETAILS&invoiceId=${pending_invoice.id}`,
    );
  }, [history, pending_invoice]);

  const renderSimpleButton = useCallback(() => {
    return (
      <Button
        onClick={handleRedirect}
        className="p-0 m-0 p-2 pl-3 pr-3 ml-3 d-flex justify-content-center align-items-center"
        variant="success-2"
      >
        {BUTTON_TEXT[status]}
        <LuChevronRight />
      </Button>
    );
  }, [handleRedirect, status]);

  const renderButtonByStatus = status => {
    if (status === 'incomplete' && !hasPendingInvoice) {
      return BUTTON_TEXT[status];
    }

    if (status === 'incomplete' && hasPendingInvoice) {
      const { payment_method } = pending_invoice;

      if (payment_method === 'BOLETO') {
        const { metadata } = pending_invoice;
        const { pdf } = metadata || {};

        if (!pdf) {
          return (
            <Button
              className="p-0 m-0 p-1 pl-2 pr-2 ml-2 d-flex justify-content-center align-items-center"
              variant="white"
              size="sm"
              onClick={onModalToggle}
            >
              <LuRefreshCcw className="mr-2" />
              Gerar boleto
            </Button>
          );
        }

        return (
          <>
            <Button
              className="p-0 m-0 p-1 pl-2 pr-2 ml-2 d-flex justify-content-center align-items-center"
              variant="white"
              size="sm"
              onClick={handlePrintBoleto}
            >
              <LuPrinter className="mr-2" />
              Imprimir
            </Button>
            <Button
              className="p-0 m-0 p-1 pl-2 pr-2 ml-2 d-flex justify-content-center align-items-center"
              variant="white"
              size="sm"
              onClick={handleCopy}
            >
              <LuCopy className="mr-2" />
              Linha digitável
            </Button>
          </>
        );
      }

      return renderSimpleButton();
    }

    if (status === 'past_due' && !hasPendingInvoice) {
      return BUTTON_TEXT[status];
    }

    if (status === 'past_due' && hasPendingInvoice) {
      const { payment_method } = pending_invoice;

      if (payment_method === 'BOLETO') {
        const { metadata } = pending_invoice;
        const { pdf } = metadata || {};

        if (!pdf) {
          return (
            <Button
              className="p-0 m-0 p-1 pl-2 pr-2 ml-2 d-flex justify-content-center align-items-center"
              variant="white"
              size="sm"
              onClick={onModalToggle}
            >
              <LuRefreshCcw className="mr-2" />
              Gerar boleto
            </Button>
          );
        }

        return (
          <>
            <Button
              className="p-0 m-0 p-1 pl-2 pr-2 ml-2 d-flex justify-content-center align-items-center"
              variant="white"
              size="sm"
              onClick={handlePrintBoleto}
            >
              <LuPrinter className="mr-2" />
              Imprimir
            </Button>
            <Button
              className="p-0 m-0 p-1 pl-2 pr-2 ml-2 d-flex justify-content-center align-items-center"
              variant="white"
              size="sm"
              onClick={handleCopy}
            >
              <LuCopy className="mr-2" />
              Linha digitável
            </Button>
          </>
        );
      }

      if (payment_method === 'CREDIT_CARD') {
        return (
          <>
            <Button
              className="p-0 m-0 p-1 pl-2 pr-2 ml-2 d-flex justify-content-center align-items-center"
              variant="white"
              size="sm"
              onClick={onModalToggle}
            >
              <LuRefreshCcw className="mr-2" />
              Tentar novamente
            </Button>
            <Button
              className="p-0 m-0 ml-2"
              variant="link"
              size="sm"
              onClick={handleGoToInvoice}
            >
              Ver detalhes
            </Button>
          </>
        );
      }
    }

    if (status === 'active' && hasPendingInvoice) {
      const { payment_method } = pending_invoice;

      if (payment_method === 'BOLETO') {
        const { metadata } = pending_invoice;
        const { pdf } = metadata || {};

        if (!pdf) {
          return (
            <Button
              className="p-0 m-0 p-1 pl-2 pr-2 ml-2 d-flex justify-content-center align-items-center"
              variant="white"
              size="sm"
              onClick={onModalToggle}
            >
              <LuRefreshCcw className="mr-2" />
              Gerar boleto
            </Button>
          );
        }

        return (
          <>
            <Button
              className="p-0 m-0 p-1 pl-2 pr-2 ml-2 d-flex justify-content-center align-items-center"
              variant="white"
              size="sm"
              onClick={handlePrintBoleto}
            >
              <LuPrinter className="mr-2" />
              Imprimir
            </Button>
            <Button
              className="p-0 m-0 p-1 pl-2 pr-2 ml-2 d-flex justify-content-center align-items-center"
              variant="white"
              size="sm"
              onClick={handleCopy}
            >
              <LuCopy className="mr-2" />
              Linha digitável
            </Button>
          </>
        );
      }

      if (payment_method === 'CREDIT_CARD') {
        return renderSimpleButton();
      }
    }

    return null;
  };

  if (noActionStatuses.includes(status)) {
    return renderSimpleButton();
  }

  if (withActionStatuses.includes(status)) {
    return renderButtonByStatus(status);
  }

  return null;
}

export default Actions;
