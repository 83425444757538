import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Formik } from 'formik';
import { Col, Form } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';

import FORMATTERS from 'helpers/formatters';
import { Button, FormSelectField, SimpleAlert } from '_components/_core';

import { AddPaymentMethodButton } from '..';

import { RepeatInvoiceChargeSchema } from './utilities';
import { ModalFooter, StyledModal } from './styles';

function RepeatInvoiceChargeModal({
  invoice,
  isVisible,
  paymentMethods = [],
  subscription,
  onModalToggle,
  onFetchPaymentMethods,
  onFetchSubscription,
  onPayInvoice,
  onAfterSaveCallback,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentResponse, setPaymentResponse] = useState(null);

  useEffect(() => {
    if (isVisible) {
      onFetchPaymentMethods();
    }
  }, [isVisible, onFetchPaymentMethods]);

  const paymentMethodOptions = useMemo(() => {
    if (isEmpty(paymentMethods)) {
      return [];
    }

    return paymentMethods.map(paymentMethod => ({
      value: paymentMethod.id,
      label: `${paymentMethod.payment_method_description.toUpperCase()}${paymentMethod.default ? ' (Padrão)' : ''}`,
    }));
  }, [paymentMethods]);

  const handleSubmitForm = values => {
    setIsLoading(true);

    const successCallback = responseData => {
      if (responseData.success) {
        onModalToggle();

        setIsLoading(false);

        onFetchSubscription();

        if (onAfterSaveCallback) {
          onAfterSaveCallback(responseData);
        }
      }

      setPaymentResponse(responseData);

      setIsLoading(false);
    };

    const errorCallback = responseData => {
      setIsLoading(false);

      setPaymentResponse(responseData);
    };

    onPayInvoice(
      {
        invoice_id: invoice.id,
        payment_method_id: values.payment_method_id,
        payment_method_description: paymentMethods.find(
          paymentMethod => paymentMethod.id === values.payment_method_id,
        ).payment_method_description,
        amount: invoice.total_amount,
      },
      successCallback,
      errorCallback,
    );
  };

  const getSelectedPaymentMethodName = useCallback(
    id => {
      const selected = paymentMethods.find(paymentMethod => paymentMethod.id === id);

      if (!selected) {
        return 'Pagar fatura';
      }

      return `Pagar com ${selected.payment_method_description}`;
    },
    [paymentMethods],
  );

  const renderModalFooter = useCallback(
    (handleSubmit, isValid, values) => (
      <ModalFooter>
        <Button variant="secondary" onClick={() => onModalToggle()}>
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="success-2"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={!isValid || isLoading}
        >
          {getSelectedPaymentMethodName(values.payment_method_id)}
        </Button>
      </ModalFooter>
    ),
    [onModalToggle, isLoading, getSelectedPaymentMethodName],
  );

  if (!invoice) return null;

  return (
    <Formik
      initialValues={{
        invoice_id: invoice.id,
        payment_method_id: null,
      }}
      validationSchema={RepeatInvoiceChargeSchema}
      onSubmit={handleSubmitForm}
      validateOnBlur
      enableReinitialize
    >
      {({ handleSubmit, isValid, values }) => (
        <>
          <StyledModal
            size="sm"
            title="Pagar fatura"
            isVisible={isVisible}
            toggleModal={onModalToggle}
            footer={renderModalFooter(handleSubmit, isValid, values)}
          >
            <Form onSubmit={handleSubmit}>
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>Número e Situação</Form.Label>
                  <p>
                    {invoice.provider_invoice_number}
                    <span className="ml-2">•</span>
                    {FORMATTERS.SUBSCRIPTION_INVOICE_STATUS(invoice.status)}
                  </p>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>Total e Vencimento</Form.Label>
                  <p>
                    <span
                      className="text-warning"
                      style={{
                        fontWeight: '500',
                      }}
                    >
                      {FORMATTERS.NUMBER(invoice.total_amount)}
                    </span>
                    <span className="ml-2 mr-2">•</span>
                    {FORMATTERS.DATE_DDMMYYYY(invoice.due_date)}
                  </p>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>Descrição</Form.Label>
                  <p>{invoice.description}</p>
                </Form.Group>
                <Form.Group as={Col} md="12">
                  <Form.Label>Selecione a forma de pagamento</Form.Label>
                  <FormSelectField
                    name="payment_method_id"
                    options={paymentMethodOptions}
                    isSearchable={false}
                    placeholder="Selecione a forma de pagamento"
                  />
                  <div className="mt-3">
                    <AddPaymentMethodButton
                      subscription={subscription}
                      withBillingModal={false}
                    />
                  </div>
                </Form.Group>
              </Form.Row>
            </Form>
            {paymentResponse && !paymentResponse.success && (
              <SimpleAlert variant="danger">{paymentResponse.message}</SimpleAlert>
            )}
          </StyledModal>
        </>
      )}
    </Formik>
  );
}

export default RepeatInvoiceChargeModal;
