import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { FcIdea } from 'react-icons/fc';

import { RepeatInvoiceChargeModal } from '_components/Settings/components/SettingsV2/components/Subscription/components';

import Message from './components/Message';
import Actions from './components/Actions';

import { Container } from './styles';

function ActionsBar({ activeCompany }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { subscription, pending_invoice } = activeCompany || {};
  const { status } = subscription || {};

  const hasPendingInvoice = useMemo(() => {
    return (
      subscription &&
      ['active', 'past_due'].includes(subscription.status) &&
      pending_invoice &&
      pending_invoice.id
    );
  }, [pending_invoice, subscription]);

  const onModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const root = document.getElementsByTagName('html')[0];

    if ((subscription && subscription.status !== 'active') || hasPendingInvoice) {
      root.style.cssText = `${root.style.cssText}--actions-bar-height: 45px;`;
    } else {
      root.style.cssText = `${root.style.cssText}--actions-bar-height: 0px;`;
    }
  }, [subscription, hasPendingInvoice]);

  if ((!status || status === 'active') && !hasPendingInvoice) {
    return null;
  }

  return (
    <Container className="action-bar no-print" variant={status}>
      <RepeatInvoiceChargeModal
        invoice={pending_invoice}
        isVisible={isModalOpen}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
      />
      <div className="d-flex justify-content-center align-items-center">
        <FcIdea size="1.2em" />
        <p className="ml-2">
          <Message activeCompany={activeCompany} />
        </p>
        <Actions activeCompany={activeCompany} onModalToggle={onModalToggle} />
      </div>
    </Container>
  );
}

ActionsBar.defaultProps = {
  activeCompany: {},
};

ActionsBar.propTypes = {
  activeCompany: PropTypes.object,
};

export default ActionsBar;
