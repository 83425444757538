import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Col, Row } from 'react-bootstrap';
import { IoArrowBack } from 'react-icons/io5';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import { Button } from '_components/_core';
import { Plan } from '_components/_shared';

import { PlansContainer, StyledCycleButton } from '../styles';

function SelectPlan({ plans, subscription, onChoosePlan, onBackToHome, activeCompany }) {
  const [billingCycle, setBillingCycle] = useState(1);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState(null);

  const isFounderPlansOnly = useMemo(() => {
    return plans.every(plan => plan.type === 'FOUNDER_PLAN');
  }, [plans]);

  const validPlans = useMemo(() => {
    if (isFounderPlansOnly) {
      return sortBy(plans, 'price');
    }

    return sortBy(
      plans.filter(plan => plan.cycle_months === billingCycle),
      'price',
    );
  }, [plans, billingCycle, isFounderPlansOnly]);

  const renderBackButton = useCallback(() => {
    if (!subscription || subscription.status === 'trialing') {
      return null;
    }

    return (
      <Button
        variant="link"
        className="m-0 p-0 mr-3 d-flex justify-content-center align-items-center"
        onClick={onBackToHome}
      >
        <IoArrowBack className="mr-1" />
        Voltar
      </Button>
    );
  }, [subscription, onBackToHome]);

  return (
    <>
      <div className="mt-3">
        <div className="mt-3 d-flex align-items-center">{renderBackButton()}</div>
        <h4 className="m-0 d-flex justify-content-center align-items-center">
          Selecione um plano de assinatura
        </h4>
      </div>
      <div className="mt-3 mb-3 d-flex justify-content-center align-items-center">
        {!isFounderPlansOnly && (
          <ButtonGroup className="ml-auto mr-auto mb-3">
            <StyledCycleButton
              variant="default"
              onClick={() => setBillingCycle(1)}
              active={billingCycle === 1}
            >
              Mensal
            </StyledCycleButton>
            <StyledCycleButton
              variant="default"
              onClick={() => setBillingCycle(6)}
              active={billingCycle === 6}
            >
              Semestral
            </StyledCycleButton>
            <StyledCycleButton
              variant="default"
              onClick={() => setBillingCycle(12)}
              active={billingCycle === 12}
            >
              Anual
            </StyledCycleButton>
          </ButtonGroup>
        )}
      </div>
      <Row className="d-flex align-items-center">
        <Col md={{ span: 12, offset: 0 }}>
          <PlansContainer>
            {validPlans.map((plan, index) => (
              <Plan
                key={plan.id}
                plan={plan}
                isActive={selectedPlanIndex === index}
                onSelectPlan={() => setSelectedPlanIndex(index)}
                onChoosePlan={() => onChoosePlan(plan.id)}
                allPlans={plans}
                hideDescription={isFounderPlansOnly}
              />
            ))}
          </PlansContainer>
        </Col>
      </Row>
      {!isEmpty(validPlans) && (
        <Row className="mt-3">
          <Col>
            {activeCompany && !activeCompany.personal_account && (
              <>
                <small>Plano válido para 1 conta (empresa)</small> <br />
              </>
            )}
            {activeCompany && activeCompany.personal_account && (
              <>
                <small>Conta pessoal é limitada a uma assinatura por usuário</small>{' '}
                <br />
              </>
            )}
            <small>A economia exibida é calculada em relação ao plano mensal</small>
          </Col>
        </Row>
      )}
    </>
  );
}

SelectPlan.propTypes = {
  plans: PropTypes.array.isRequired,
  onChoosePlan: PropTypes.func.isRequired,
  onBackToHome: PropTypes.func.isRequired,
  subscription: PropTypes.object,
};

export default SelectPlan;
