import { connect } from 'react-redux';
import { compose } from 'redux';

import withPermissions from '_components/_shared/PermissionsGate/withPermissionGateContainer';

import Developers from './Developers';

const mapStateToProps = state => ({
  activeCompany: state.company.activeCompany,
  user: state.auth.user,
});

export default compose(
  connect(mapStateToProps, null),
  withPermissions(['access_api'], 'all', true, 'component'),
)(Developers);
