import Swal from 'sweetalert2';

import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const deleteTransaction = (transaction, callback) => {
  return async dispatch => {
    let title = 'Excluir Transação';
    let message =
      'Tem certeza que deseja excluir esta transação? Esta operação não pode ser revertida.';
    let options = {};
    // biome-ignore lint/correctness/noUnusedVariables: <explanation>
    let preConfirm = null;
    let input = null;
    let inputPlaceholder = '';

    if (transaction.payment_plan === 'RECURRENT') {
      title = 'Esta transação se repete';
      message = 'Você deseja excluir os demais itens?';

      options = {
        'only-this': '<strong>Não</strong>, excluir somente este',
        'this-and-next': '<strong>Sim</strong>, excluir os próximos',
        'previous-and-next': '<strong>Sim</strong>, excluir os anteriores e os próximos',
      };
    }

    if (transaction.payment_plan === 'INSTALMENT') {
      title = 'Exclusão de item parcelado';
      message =
        'Essa transação é parte de um parcelamento. <br /> <strong> O que você deseja excluir? </strong>';

      options = {
        'only-this': 'Somente esta parcela',
        'this-and-next': 'Esta parcela e as próximas',
        'previous-and-next': 'As parcelas anteriores e as próximas',
      };
    }

    if (transaction.frequency_number === 1) {
      delete options['previous-and-next'];
    }

    if (transaction.split_id !== null) {
      dispatch(
        alertActionCreators.error(
          'Este item faz parte de um detalhamento e não pode ser excluído aqui. Você deve excluir o item principal para excluir este item.',
        ),
      );

      return;
    }

    confirmDialog.warning({
      title,
      message,
      icon: 'warning',
      input: 'radio',
      confirmButtonText: 'Sim, excluir!',
      inputValue: 'only-this',
      inputOptions: options,
      inputValidator: value => {
        if (transaction.payment_plan === 'ONE_TIME') {
          return null;
        }

        if (!value) {
          return 'Você deve escolher uma opção';
        }

        return null;
      },
      preConfirm: value => {
        if (value === 'previous-and-next') {
          input = 'text';
          inputPlaceholder = 'Digite "li e entendi" sem aspas';

          return new Promise(resolve => {
            confirmDialog.warning({
              title: 'Confirmação necessária',
              message: `<p class="text-danger" id="error-message">A opção "excluir itens anteriores e próximos" pode afetar o seu saldo, se houverem itens anteriores marcados como pagos.</p><p>Tem certeza que deseja continuar?</p>`,
              input,
              inputPlaceholder,
              inputAttributes: {
                style: 'width: 80%;',
              },
              confirmButtonText: 'Confirmar',
              preConfirm: inputValue => {
                const errorMessageElement = document.getElementById('error-message');
                if (inputValue !== 'li e entendi') {
                  if (errorMessageElement) {
                    Swal.showValidationMessage(
                      'Por favor, digite "li e entendi" sem aspas para confirmar a exclusão.',
                    );
                  }
                  return false;
                } else {
                  if (errorMessageElement) {
                    errorMessageElement.textContent = '';
                  }
                  resolve();
                  return true;
                }
              },
            });
          });
        }
        return Promise.resolve();
      },

      onConfirm: async result => {
        const { value: deleteBehavior } = result;

        dispatch(request(transaction.id));

        try {
          const response = await service.remove(transaction.id, { deleteBehavior });
          dispatch(success(response));
          dispatch(alertActionCreators.success('Transação excluída com sucesso!'));

          if (callback) {
            callback();
          }
        } catch (error) {
          dispatch(failure(transaction.id, error));
        }
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return {
      type: constants.DELETE_COMPANY_TRANSACTION_REQUEST,
      payload: {
        id,
      },
    };
  }

  function success(response) {
    return {
      type: constants.DELETE_COMPANY_TRANSACTION_SUCCESS,
      payload: response.data,
    };
  }

  function failure(id, error) {
    return {
      type: constants.DELETE_COMPANY_TRANSACTION_FAILURE,
      payload: {
        id,
        error,
      },
    };
  }
};

export default deleteTransaction;
