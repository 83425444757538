import styled from 'styled-components';

import Button from '_components/_core/Button';

export const HelpButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.5em;
  border-radius: 50%;
  background-color: rgb(17, 146, 79);
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1.2em;
  margin-left: 0.5em;
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 42px;
  right: 20px;
  z-index: 999;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const StyledIframe = styled.iframe`
  border: none;
  overflow: hidden;
`;

export const CustomFilterButton = styled(Button)`
  font-size: 0.8rem;
  vertical-align: top;
  font-weight: 500;
  color: #0D0E10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin: 0 !important;
  margin-right: 8px;
  background-color: #F9FBFC;
  border: 1px solid #E8E7EA;
  border-radius: 8px;

  :hover, :focus {
    border: 1px solid #E8E7EA;
    text-decoration: none;
    color: #0D0E10;
  }

  &&& {
    .dropleft .dropdown-toggle::before {
      display: none;
    }

    &::before {
      display: none !important;
    }
  }

  &::after {
    content: none !important;
  }
`;
