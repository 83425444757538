import styled, { css } from 'styled-components';

export const Container = styled.div`
`;

export const StyledTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border-radius: 6px;
  color: #002b64;

  ${({ variant }) =>
    variant === 'info' &&
    css`
    background-color: #ebf4fa;
    border: 0.2px solid #0069b4;

    p {
      color: #002b64;    
    }
  `}

  ${({ variant }) =>
    variant === 'danger' &&
    css`
    background-color: #fcedeb;
    border: 0.2px solid #e64d43;

    p {
     color: #002b64;
    }
  `}

  ${({ variant }) =>
    variant === 'warning' &&
    css`
    background-color: #faf5dc;
    border: 0.2px solid #fcca00;

    p {
     color: #002b64;
    }
  `}

  ${({ variant }) =>
    variant === 'success' &&
    css`
    background-color: #e6f4ea;
    border: 0.2px solid #38A915;

    p {
     color: #002b64;
    }
  `}

  ${({ variant }) =>
    variant === 'incomplete_expired' &&
    css`
    background-color: #fcedeb;
    border: 0.2px solid #e64d43;

    p {
     color: #002b64;
    }
  `}

  ${({ variant }) =>
    variant === 'past_due' &&
    css`
    background-color: #fcedeb;
    border: 0.2px solid #e64d43;

    p {
     color: #002b64;
    }
  `}

  
  ${({ variant }) =>
    variant === 'trial-ended' &&
    css`
    background-color: #faf5dc;
    border: 0.2px solid #fcca00;

    p {
      color: #703d00;    
    }
  `}

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const StyledContent = styled.div`

`;

export const StyledName = styled.span`
  font-weight: 700;
  color: #38A915;  
`;

export const StyledText = styled.p`
  font-weight: 400;
  margin-top: 2px;
  margin-bottom: 0;
  white-space: initial;
`;
