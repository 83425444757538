import { connect } from 'react-redux';

import companyActionCreators from '_store/_actions/company';
import ibgeActionCreators from '_store/_actions/ibge';
import subscriptionActionCreators from '_store/_actions/subscription';

import CompanyFormModal from './CompanyFormModal';

const mapStateToProps = state => ({
  isSearching: state.company.isSearching,
});

const mapDispatchToProps = {
  onAddFinancialCompany: companyActionCreators.addFinancialCompany,
  onUpdateFinancialCompany: companyActionCreators.updateFinancialCompany,
  onFetchCompany: companyActionCreators.getCompanyById,
  onFetchMarketSegments: companyActionCreators.fetchMarketSegments,
  onSearchCompanyByCnpj: companyActionCreators.searchCompany,
  onClearSearchedCompany: companyActionCreators.clearSearchedCompany,
  onFetchCities: ibgeActionCreators.fetchCities,
  onFetchManagerSubscription: subscriptionActionCreators.fetchManagerSubscription,
  onFetchPersonalAccount: subscriptionActionCreators.fetchPersonalAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyFormModal);
