import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import pluralize from 'pluralize';
import { useHistory } from 'react-router-dom';

import { Tag } from '_components/_core';

import { Content } from './styles';

function CompanyInvitations({ invitations }) {
  const history = useHistory();

  const handleRedirect = () => {
    history.push('/selecionar-empresa');
  };

  if (isEmpty(invitations)) {
    return null;
  }

  return (
    <Tag variant="warning" className="ml-2">
      <Content onClick={handleRedirect}>
        {`${invitations.length} ${pluralize('convite', invitations.length)} ${pluralize('recebido', invitations.length)}!`}
      </Content>
    </Tag>
  );
}

CompanyInvitations.defaultProps = {
  invitations: [],
  isLoading: false,
};

CompanyInvitations.propTypes = {
  invitations: PropTypes.array,
  isLoading: PropTypes.bool,
  onFetchInvitations: PropTypes.func.isRequired,
  onAcceptInvitation: PropTypes.func.isRequired,
  onDeclineInvitation: PropTypes.func.isRequired,
};
export default CompanyInvitations;
