import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';
import FORMATTERS from 'helpers/formatters';

const cancelSubscription = (params, callback, errorCallback) => {
  return dispatch => {
    const { renew_at } = params;

    const options = {
      'cancel-at-period-end': `Cancelar ao final do período (${FORMATTERS.DATE_DDMMYYYY_FROM_UTC_TO_ZONE(renew_at)})`,
      'cancel-now': 'Cancelar imediatamente',
    };

    confirmDialog.warning({
      title: 'Cancelar assinatura',
      message:
        'Você deseja cancelar sua assinatura no final do período contratado ou imediatamente?',
      icon: 'warning',
      input: 'radio',
      inputAttributes: {
        style: 'display: flex; justify-content: center; align-items: center;',
      },
      confirmButtonText: 'Cancelar assinatura',
      cancelButtonText: 'Voltar',
      inputValue: null,
      inputOptions: options,
      inputValidator: value => {
        if (!value) {
          return 'Você deve escolher uma opção';
        }

        return null;
      },
      dangerMode: true,

      onConfirm: result => {
        dispatch(request());

        const { value: cancelBehavior } = result;

        service
          .cancelSubscription({
            ...params,
            cancelBehavior,
          })
          .then(response => {
            const { data } = response;

            dispatch(success(data));

            if (callback) {
              callback(data);
            }
          })
          .catch(error => {
            dispatch(failure(error));

            if (errorCallback) {
              errorCallback(error);
            }
          });
      },
      onCancel: () => {
        errorCallback();
      },
    });
  };

  function request() {
    return {
      type: constants.CANCEL_SUBSCRIPTION_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.CANCEL_SUBSCRIPTION_SUCCESS,
      payload: {
        data,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.CANCEL_SUBSCRIPTION_FAILURE,
      payload: {
        error,
      },
    };
  }
};

export default cancelSubscription;
