import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { Col } from 'react-bootstrap';
import { LuBuilding, LuCreditCard } from 'react-icons/lu';
import { Formik } from 'formik';
import { DateTime } from 'luxon';
import { FaExclamationCircle, FaQuestionCircle, FaSearch } from 'react-icons/fa';
import { IoHelpCircleOutline } from 'react-icons/io5';
import isEmpty from 'lodash/isEmpty';

import { useMediaQuery } from 'helpers';
import {
  Button,
  FormTextField,
  FormCpfCnpjField,
  FloatingCard,
  Tabs,
  FormSelectField,
  Popover,
} from '_components/_core';
import {
  AddressFormFields,
  FormMarketSegmentField,
  LoadingIcon,
} from '_components/_shared';

import { CompanySchema } from './utilities';
import { ModalFooter, SubscriptionSelectContainer, RequeridedIndicator } from './styles';

const PLACEHOLDERS = {
  document_type_CPF: 'CPF',
  document_type_CNPJ: 'CNPJ',
  document_type_UNDEFINED: 'Sem documento',
  first_name_CPF: 'Nome completo',
  first_name_CNPJ: 'Razão Social',
  last_name_CPF: 'Apelido',
  last_name_CNPJ: 'Nome Fantasia',
  first_name_UNDEFINED: 'Nome completo',
  last_name_UNDEFINED: 'Apelido',
};

let PERSONAL =
  'acesso individual, para controle de finanças pessoais e possui menos recursos.';
let COMPANY =
  'pode convidar novos usuários, serve para controlar finanças empresariais e possui todos os recursos.';

const CompanyFormModal = ({
  isVisible,
  // isLoading: oldIsLoading,
  isSearching,
  company_id,
  selectedCompany: oldCompany,
  onAddFinancialCompany,
  onUpdateFinancialCompany,
  onFetchCompany,
  onFetchMarketSegments,
  onToggleForm,
  onClearSearchedCompany,
  onSearchCompanyByCnpj,
  onFetchCities,
  onBeforeSaveCallback,
  onAfterSaveCallback,
  onAfterSaveCallbackWithReset,
  onFetchManagerSubscription,
  onFetchPersonalAccount,
}) => {
  const { isMobile } = useMediaQuery();

  const [newCompany, setNewCompany] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [managerSubscription, setManagerSubscription] = useState(null);
  const [personalAccount, setPersonalAccount] = useState(null);

  useEffect(() => {
    onFetchMarketSegments();
  }, [onFetchMarketSegments]);

  useEffect(() => {
    if (!isVisible) {
      return;
    }

    if (!company_id) {
      setNewCompany({});
      return;
    }

    setIsLoading(true);

    onFetchCompany(company_id, found_company => {
      setNewCompany(found_company);

      setIsLoading(false);
    });
  }, [company_id, onFetchCompany, isVisible]);

  useEffect(() => {
    onFetchManagerSubscription(managerSubscription => {
      setManagerSubscription(managerSubscription);
    });
  }, [onFetchManagerSubscription]);

  useEffect(() => {
    if (isVisible) {
      onFetchPersonalAccount(found => {
        setPersonalAccount(found);
      });
    }
  }, [isVisible, onFetchPersonalAccount]);

  const handleCompanySearch = useCallback(
    (values, setFieldValue, validateForm) => {
      onClearSearchedCompany();

      onSearchCompanyByCnpj(values.document_number, searchedCompany => {
        onFetchCities(searchedCompany.address_state_ibge, () => {
          setFieldValue('company_name', searchedCompany.company_name);
          setFieldValue('trading_name', searchedCompany.trading_name);
          setFieldValue('address_zip_code', searchedCompany.address_zip_code);
          setFieldValue('address_street', searchedCompany.address_street);
          setFieldValue('address_number', searchedCompany.address_number);
          setFieldValue('address_district', searchedCompany.address_district);
          setFieldValue('address_complement', searchedCompany.complement);
          setFieldValue('address_city', searchedCompany.address_city);
          setFieldValue('address_city_ibge', Number(searchedCompany.address_city_ibge));
          setFieldValue('address_state', searchedCompany.address_state);
          setFieldValue('address_state_ibge', Number(searchedCompany.address_state_ibge));

          validateForm();
        });
      });
    },
    [onSearchCompanyByCnpj, onFetchCities, onClearSearchedCompany],
  );

  const getDocumentTypeOptions = useCallback(values => {
    if (values.account_type === 'PJ') {
      return [
        { value: 'CPF', label: 'CPF' },
        { value: 'CNPJ', label: 'CNPJ' },
        { value: 'UNDEFINED', label: 'Sem documento' },
      ];
    }

    if (values.account_type === 'PF') {
      return [
        { value: 'CPF', label: 'CPF' },
        { value: 'UNDEFINED', label: 'Sem documento' },
      ];
    }

    return [
      { value: 'CPF', label: 'CPF' },
      { value: 'CNPJ', label: 'CNPJ' },
      { value: 'UNDEFINED', label: 'Sem documento' },
    ];
  }, []);

  const initialValues = useMemo(() => {
    if (!company_id && isEmpty(oldCompany)) {
      return {
        company_name: '',
        trading_name: '',
        document_number: '',
        address_state_ibge: '',
        address_city_ibge: '',
        address_state: '',
        address_city: '',
        address_street: '',
        address_number: '',
        address_complement: '',
        address_district: '',
        address_zip_code: '',
        market_segment_id: null,
        status_irs: 'ATIVA',
        date_founded: DateTime.now().toFormat('yyyy-MM-dd'),
        document_type: 'CNPJ',
        balance_type: 'POSITIVO',
        subscription: 'existing',
      };
    }

    return {
      company_name: newCompany ? newCompany.company_name : '',
      trading_name: newCompany ? newCompany.trading_name : '',
      document_number: newCompany ? newCompany.document_number : '',
      address_state_ibge: newCompany ? newCompany.address_state_ibge : '',
      address_city_ibge: newCompany ? newCompany.address_city_ibge : '',
      address_state: newCompany ? newCompany.address_state : '',
      address_city: newCompany ? newCompany.address_city : '',
      address_street: newCompany ? newCompany.address_street : '',
      address_number: newCompany ? newCompany.address_number : '',
      address_complement: newCompany ? newCompany.address_complement : '',
      address_district: newCompany ? newCompany.address_district : '',
      address_zip_code: newCompany ? newCompany.address_zip_code : '',
      market_segment_id: newCompany ? newCompany.market_segment_id : '',
      status_irs: 'ATIVA',
      date_founded: DateTime.now().toFormat('yyyy-MM-dd'),
      document_type: newCompany ? newCompany.document_type : '',
      balance_type: 'POSITIVO',
    };
  }, [company_id, newCompany, oldCompany]);

  const selectedCompany = useMemo(() => {
    if (company_id) {
      return newCompany;
    }

    return oldCompany;
  }, [company_id, newCompany, oldCompany]);

  const displayAccountType = useMemo(() => {
    if (selectedCompany && selectedCompany.id) {
      return false;
    }

    if (!personalAccount) {
      return false;
    }

    const { allowAddPersonalAccount } = personalAccount;

    if (!allowAddPersonalAccount) {
      return false;
    }

    return true;
  }, [personalAccount, selectedCompany]);

  const handleSubmitForm = useCallback(
    (values, { resetForm }) => {
      setIsLoading(true);

      if (onBeforeSaveCallback) {
        onBeforeSaveCallback(values);
      }

      if (selectedCompany && selectedCompany.id) {
        const formattedValues = {
          ...values,
          type: values.document_type === 'CPF' ? 'SELF_EMPLOYED' : 'COMPANY',
        };

        onUpdateFinancialCompany(selectedCompany.id, formattedValues, updatedCompany => {
          if (onAfterSaveCallback) {
            onAfterSaveCallback(updatedCompany);
          }

          if (onAfterSaveCallbackWithReset) {
            onAfterSaveCallbackWithReset(updatedCompany);

            resetForm();
            onToggleForm();
          }

          if (!onBeforeSaveCallback && !onAfterSaveCallback) {
            resetForm();
            onToggleForm();
          }

          setIsLoading(false);
        });
      } else {
        const formattedValues = {
          ...values,
          type: values.document_type === 'CPF' ? 'SELF_EMPLOYED' : 'COMPANY',
        };

        const callback = createdCompany => {
          if (onAfterSaveCallback) {
            onAfterSaveCallback(createdCompany);
          }

          if (onAfterSaveCallbackWithReset) {
            onAfterSaveCallbackWithReset(createdCompany);

            resetForm();
            onToggleForm();
          }

          if (!onBeforeSaveCallback && !onAfterSaveCallback) {
            resetForm();
            onToggleForm();
          }
          setIsLoading(false);
        };

        const errorCallback = () => {
          setIsLoading(false);
        };

        onAddFinancialCompany(formattedValues, false, callback, errorCallback);
      }
    },
    [
      onToggleForm,
      onUpdateFinancialCompany,
      onAddFinancialCompany,
      selectedCompany,
      onAfterSaveCallback,
      onAfterSaveCallbackWithReset,
      onBeforeSaveCallback,
    ],
  );

  const renderModalFooter = useCallback(
    (handleSubmit, isValid) => (
      <ModalFooter>
        <Button
          type="submit"
          variant="success-2"
          className="mr-2 flex-fill"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={!isValid || isLoading}
        >
          Salvar alterações
        </Button>
        <Button className="flex-fill" variant="inverse-dark" onClick={onToggleForm}>
          Cancelar
        </Button>
      </ModalFooter>
    ),
    [onToggleForm, isLoading],
  );

  const getTabs = useCallback(
    ({ values, setFieldValue, validateForm }) => {
      const items = [
        {
          id: 'COMPANY_INFO',
          title: 'Dados de exibição',
          icon: <LuBuilding className="mr-2" />,
          content: (
            <>
              <hr />
              {displayAccountType && (
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Tipo de conta</Form.Label>
                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <FormSelectField
                          name="account_type"
                          label="Tipo de conta"
                          options={[
                            { value: 'PJ', label: 'Empresarial' },
                            { value: 'PF', label: 'Pessoal' },
                          ]}
                          isSearchable={false}
                          onChange={({ value }) => {
                            setFieldValue('account_type', value);

                            if (value === 'PF') {
                              setFieldValue('market_segment_id', 83);
                              setFieldValue('document_number', '00000000000');
                              setFieldValue('document_type', 'UNDEFINED');
                            } else {
                              setFieldValue('market_segment_id', null);
                            }
                          }}
                        />
                      </div>
                      <div className="ml-3">
                        <Popover
                          content={
                            <p>
                              <strong>Conta pessoal</strong>: {PERSONAL}
                              <br /> <br />
                              <strong>Conta empresarial</strong>: {COMPANY}
                            </p>
                          }
                        >
                          <FaQuestionCircle className="text-muted" />
                        </Popover>
                      </div>
                    </div>
                  </Form.Group>
                </Form.Row>
              )}
              <Form.Row>
                <Form.Group as={Col} md="3">
                  <Form.Label>
                    Tipo de documento
                    <RequeridedIndicator>*</RequeridedIndicator>
                  </Form.Label>
                  <FormSelectField
                    name="document_type"
                    label="Tipo de documento"
                    options={getDocumentTypeOptions(values)}
                    isSearchable={false}
                    onChange={({ value }) => {
                      setFieldValue('document_type', value);

                      if (value === 'UNDEFINED') {
                        setFieldValue('document_number', '00000000000');
                      }
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} md="9">
                  <div className="d-flex justify-content-between align-items-start">
                    <div
                      style={{
                        flex: 1,
                      }}
                      className="d-flex flex-column align-items-start justify-content-center"
                    >
                      <Form.Label>
                        {values.document_type === 'UNDEFINED'
                          ? 'Sem documento'
                          : values.document_type}
                        <RequeridedIndicator>*</RequeridedIndicator>
                      </Form.Label>
                      <FormCpfCnpjField
                        name="document_number"
                        type={values.document_type === 'CNPJ' ? 'CNPJ' : 'CPF'}
                        placeholder="Digite o número do documento"
                        disabled={values.document_type === 'UNDEFINED'}
                      />
                    </div>
                    {values.document_type === 'CNPJ' && (
                      <Button
                        variant="default"
                        className="ml-2 mt-4"
                        isLoading={isSearching}
                        disabled={isSearching}
                        onClick={() =>
                          handleCompanySearch(values, setFieldValue, validateForm)
                        }
                      >
                        <FaSearch />
                      </Button>
                    )}
                  </div>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} md="6" className="mb-4">
                  <Form.Label>
                    {PLACEHOLDERS[`first_name_${values.document_type}`]}
                    <RequeridedIndicator>*</RequeridedIndicator>
                  </Form.Label>
                  <FormTextField
                    name="company_name"
                    placeholder={PLACEHOLDERS[`first_name_${values.document_type}`]}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" className="mb-4">
                  <Form.Label>
                    {PLACEHOLDERS[`last_name_${values.document_type}`]}
                    <RequeridedIndicator>*</RequeridedIndicator>
                  </Form.Label>
                  <FormTextField
                    name="trading_name"
                    placeholder={PLACEHOLDERS[`last_name_${values.document_type}`]}
                  />
                </Form.Group>
              </Form.Row>
              <hr />
              <AddressFormFields
                columnSizes={{
                  cep: 6,
                  searchButton: 4,
                  street: isMobile ? 6 : 4,
                  number: isMobile ? 6 : 4,
                  district: isMobile ? 6 : 4,
                  complement: isMobile ? 6 : 4,
                  city: isMobile ? 12 : 4,
                  state: isMobile ? 12 : 4,
                }}
                requiredFields={[
                  'address_city_ibge',
                  'address_state',
                  'address_state_ibge',
                  'address_city',
                ]}
              />
              <hr />
              <Form.Row>
                <Col>
                  <FormMarketSegmentField disabled={values.account_type === 'PF'} />
                </Col>
              </Form.Row>
            </>
          ),
        },
        {
          id: 'SUBSCRIPTION',
          title: 'Assinatura',
          icon: <LuCreditCard className="mr-2" />,
          content: (
            <>
              <hr />
              <div className="d-flex flex-column">
                <SubscriptionSelectContainer
                  onClick={() => setFieldValue('subscription', 'existing')}
                >
                  <div>
                    <Form.Check
                      type="radio"
                      name="subscription"
                      id="existing"
                      checked={values.subscription === 'existing'}
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                  <div className="ml-3 d-flex flex-column">
                    <h4>Assinatura conjunta</h4>
                    <span>
                      Incluir a empresa na assinatura conjunta atual.
                      {managerSubscription && managerSubscription.limitMessage && (
                        <span
                          className={
                            managerSubscription.available_seats > 0
                              ? 'text-info'
                              : 'text-danger'
                          }
                        >
                          &nbsp;({managerSubscription.limitMessage})
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="ml-auto">
                    <Popover
                      title="Assinatura conjunta"
                      content={
                        <p className="p-0 m-0">
                          <span className="text-danger">
                            As assinaturas conjuntas não são mais suportadas.
                          </span>{' '}
                          <br />
                          <br />
                          Caso você tenha lugares disponíveis na assinatura, ainda pode
                          utilizar esta opção, caso contrário utilize a opção abaixo "Nova
                          assinatura".
                        </p>
                      }
                      placement="bottom-end"
                      trigger="hover"
                      rootClose
                    >
                      <div className="d-flex align-items-center">
                        <FaExclamationCircle className="text-warning" size={20} />
                      </div>
                    </Popover>
                  </div>
                </SubscriptionSelectContainer>
                <SubscriptionSelectContainer
                  onClick={() => setFieldValue('subscription', 'new')}
                  className="mt-3"
                >
                  <div>
                    <Form.Check
                      type="radio"
                      name="subscription"
                      id="new"
                      checked={values.subscription === 'new'}
                    />
                  </div>
                  <div className="ml-3 d-flex flex-column">
                    <h4>Nova assinatura</h4>
                    <span>
                      Criar uma nova assinatura para a empresa e testar gratuitamente por
                      15 dias.
                    </span>
                  </div>
                  <div className="ml-auto">
                    <Popover
                      title="Nova assinatura"
                      content={
                        <p className="p-0 m-0">
                          Uma nova assinatura será criada para a empresa, e você poderá
                          testar gratuitamente por 15 dias. <br /> <br />
                          Após o período de teste, poderá definir dados de cobrança, plano
                          e ciclo de faturamento diferentes de sua assinatura atual.
                        </p>
                      }
                      placement="bottom-end"
                      trigger="hover"
                      rootClose
                    >
                      <div className="d-flex align-items-center">
                        <IoHelpCircleOutline className="text-muted" size={20} />
                      </div>
                    </Popover>
                  </div>
                </SubscriptionSelectContainer>
              </div>
            </>
          ),
        },
      ];

      if (company_id) {
        return items.filter(item => item.id !== 'SUBSCRIPTION');
      } else {
        if (
          !managerSubscription ||
          (managerSubscription && !managerSubscription.allowAddCompany)
        ) {
          return items.filter(item => item.id !== 'SUBSCRIPTION');
        }
      }

      return items;
    },
    [
      isSearching,
      isMobile,
      handleCompanySearch,
      managerSubscription,
      company_id,
      getDocumentTypeOptions,
      displayAccountType,
    ],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CompanySchema}
      onSubmit={handleSubmitForm}
      enableReinitialize
    >
      {({ handleSubmit, isValid, values, setFieldValue, validateForm }) => (
        <>
          <FloatingCard
            title={company_id ? 'Editar empresa' : 'Cadastrar nova empresa'}
            fullHeight
            isVisible={isVisible}
            onToggleVisibility={onToggleForm}
            footerContent={renderModalFooter(handleSubmit, isValid)}
            withCloseButton
            width={900}
            bodyClassName="p-3"
          >
            {isLoading && (
              <div className="h-100 d-flex justify-content-center">
                <LoadingIcon text="Aguarde ..." />
              </div>
            )}
            {!isLoading && (
              <>
                <Form onSubmit={handleSubmit}>
                  <Tabs
                    tabs={getTabs({
                      values,
                      setFieldValue,
                      validateForm,
                    })}
                    variant="pills"
                  />
                </Form>
              </>
            )}
          </FloatingCard>
        </>
      )}
    </Formik>
  );
};

export default CompanyFormModal;

CompanyFormModal.defaultProps = {
  company_id: null,
  selectedCompany: {},
  isSearching: false,
};

CompanyFormModal.propTypes = {
  isVisible: PropTypes.bool,
  onToggleForm: PropTypes.func.isRequired,
  onAddFinancialCompany: PropTypes.func.isRequired,
  onUpdateFinancialCompany: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object,
  company_id: PropTypes.string,
  onFetchCompany: PropTypes.func.isRequired,
  onFetchMarketSegments: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  onBeforeSaveCallback: PropTypes.func,
  onAfterSaveCallback: PropTypes.func,
  onAfterSaveCallbackWithReset: PropTypes.func,
  onFetchPersonalAccount: PropTypes.func.isRequired,
};
