import { useCallback, useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { isEmpty } from 'lodash';

import actionCreators from '_store/_actions/companyTransactions';

const useTransactions = () => {
  const dispatch = useDispatch();

  const [changingTimeout, setChangingTimeout] = useState(0);

  const activeCompany = useSelector(state => state.company.activeCompany);
  const selectedDate = useSelector(state => state.companyTransactions.selectedDate);
  const isLoading = useSelector(state => state.companyTransactions.isLoading);
  const transactions = useSelector(state => state.companyTransactions.transactions);
  const selectedAccountIds = useSelector(
    state => state.companyTransactions.selected_account_ids,
  );
  const accounts = useSelector(state => state.companyTransactions.accounts);
  const allAccounts = useSelector(state => state.companyTransactions.allAccounts);
  const duplicatedTransactionIds = useSelector(
    state => state.companyTransactions.recentlyDuplicatedTransactionsIds,
  );

  useEffect(() => {
    if (changingTimeout) {
      return () => clearTimeout(changingTimeout);
    }
  }, [changingTimeout]);

  const month = useMemo(() => {
    const { month: luxonMonth } = selectedDate;

    return luxonMonth;
  }, [selectedDate]);

  const year = useMemo(() => {
    const { year: luxonYear } = selectedDate;

    return luxonYear;
  }, [selectedDate]);

  const selected_account_id = useMemo(() => {
    if (isEmpty(selectedAccountIds)) {
      return null;
    }

    if (selectedAccountIds.length === 1) {
      return selectedAccountIds[0];
    }

    if (selectedAccountIds.length > 1) {
      const mainAccount = accounts.find(account => account.is_principal);

      return mainAccount.id;
    }

    return null;
  }, [accounts, selectedAccountIds]);

  const account_id = useMemo(() => {
    if (isEmpty(selectedAccountIds)) {
      return null;
    }

    if (selectedAccountIds.length === 1) {
      return selectedAccountIds[0];
    }

    if (selectedAccountIds.length > 1) {
      const mainAccount = accounts.find(account => account.is_principal);

      return mainAccount.id;
    }

    return null;
  }, [accounts, selectedAccountIds]);

  const handleChangeSelectedAccountIds = useCallback(
    account_ids => {
      dispatch(actionCreators.setSelectedAccountIds(account_ids));
    },
    [dispatch],
  );

  const handleLoadBankAccounts = useCallback(() => {
    dispatch(actionCreators.fetchAllAccounts());
  }, [dispatch]);

  const handleLoadCategories = useCallback(() => {
    dispatch(actionCreators.fetchAllCategories());
  }, [dispatch]);

  const handleLoadRecipients = useCallback(() => {
    dispatch(actionCreators.fetchAllRecipients());
  }, [dispatch]);

  const handleRefreshBalance = useCallback(
    forceDate => {
      const all_account_ids = accounts.map(account => account.id);

      const params = {
        account_ids: all_account_ids,
        month: forceDate ? forceDate.month : month,
        year: forceDate ? forceDate.year : year,
      };

      dispatch(actionCreators.fetchCashflow(params));
      dispatch(actionCreators.fetchBankAccountBalance(params));
    },
    [dispatch, accounts, month, year],
  );

  const handleCreateTransaction = useCallback(
    (values, callback) => {
      dispatch(
        actionCreators.createTransaction(values, created_transaction => {
          handleRefreshBalance();

          if (callback) {
            callback(created_transaction);
          }
        }),
      );
    },
    [dispatch, handleRefreshBalance],
  );

  const delayedChangeDate = date => {
    if (changingTimeout) {
      clearTimeout(changingTimeout);
    }

    const newChangingTimeout = setTimeout(() => {
      dispatch(actionCreators.setSelectedDate(date));

      handleRefreshBalance(date);
    }, 475);

    setChangingTimeout(newChangingTimeout);
  };

  const handleChangeDate = date => {
    delayedChangeDate(date);
  };

  const handleCreateEmptyTransaction = useCallback(
    (type, subType, callback) => {
      const selectedMonthYear = selectedDate.toFormat('yyyy-MM');
      const currentMonthYear = DateTime.now().toFormat('yyyy-MM');

      let event_date = null;

      if (selectedMonthYear === currentMonthYear) {
        event_date = DateTime.now().toFormat('yyyy-MM-dd');
      } else {
        event_date = selectedDate.startOf('month').toISODate();
      }

      const values = {
        type,
        sub_type: subType,
        account_id,
        category_id: null,
        recipient_id: null,
        description: '',
        event_date,
        amount: 0,
        paid: false,
      };

      dispatch(
        actionCreators.createEmptyTransaction(values, created_transaction => {
          if (callback) {
            callback(created_transaction);
          }
        }),
      );
    },
    [dispatch, account_id, selectedDate],
  );

  const handleUpdateTransaction = useCallback(
    (id, values, callback) => {
      const updatedValues = {
        ...values,
        selectedDate,
      };

      dispatch(
        actionCreators.editTransaction(id, updatedValues, () => {
          handleRefreshBalance();

          if (callback) {
            callback();
          }
        }),
      );
    },
    [dispatch, selectedDate, handleRefreshBalance],
  );

  const handleCreateUpdateTransaction = useCallback(
    (values, callback) => {
      const { id, ...transaction } = values;

      if (id) {
        handleUpdateTransaction(id, transaction);
      } else {
        handleCreateTransaction(transaction, callback);
      }
    },
    [handleCreateTransaction, handleUpdateTransaction],
  );

  const handleCreateTransferTransaction = useCallback(
    (values, callback) => {
      dispatch(
        actionCreators.createTransferTransaction(values, created => {
          handleRefreshBalance();

          if (callback) {
            callback(created);
          }
        }),
      );
    },
    [dispatch, handleRefreshBalance],
  );

  const handleDeleteTransaction = useCallback(
    (transaction, callback) => {
      dispatch(
        actionCreators.deleteTransaction(transaction, () => {
          handleRefreshBalance();

          if (callback) {
            callback();
          }
        }),
      );
    },
    [dispatch, handleRefreshBalance],
  );

  const handleDeleteMultipleTransactions = useCallback(
    (params, callback) => {
      dispatch(
        actionCreators.deleteMultipleTransactions(params, () => {
          handleRefreshBalance();

          if (callback) {
            callback();
          }
        }),
      );
    },
    [dispatch, handleRefreshBalance],
  );

  const handleDuplicateTransaction = useCallback(
    (id, type, callback) => {
      dispatch(
        actionCreators.duplicateTransaction(id, type, () => {
          handleRefreshBalance();

          if (callback) {
            callback();
          }
        }),
      );
    },
    [dispatch, handleRefreshBalance],
  );

  const handleDuplicateMultipleTransactions = useCallback(
    (params, callback) => {
      dispatch(
        actionCreators.duplicateMultipleTransactions(params, () => {
          handleRefreshBalance();

          if (callback) {
            callback();
          }
        }),
      );
    },
    [dispatch, handleRefreshBalance],
  );

  const handleTransactionPaid = useCallback(
    (id, paid, callback = null) => {
      dispatch(
        actionCreators.toggleTransactionPaid(id, paid, () => {
          if (callback) {
            callback(id);
          }

          handleRefreshBalance();
        }),
      );
    },
    [dispatch, handleRefreshBalance],
  );

  const handleToggleMultipleTransactionsPaid = useCallback(
    (params, callback) => {
      dispatch(
        actionCreators.toggleMultipleTransactionsPaid(params, () => {
          handleRefreshBalance();

          if (callback) {
            callback();
          }
        }),
      );
    },
    [dispatch, handleRefreshBalance],
  );

  const handleLoadParameters = useCallback(() => {
    if (activeCompany) {
      handleLoadBankAccounts();
      handleLoadCategories();
      handleLoadRecipients();
    }
  }, [activeCompany, handleLoadBankAccounts, handleLoadCategories, handleLoadRecipients]);

  return {
    isLoading,
    selectedDate,
    onChangeDate: handleChangeDate,

    // Transactions
    transactions,
    duplicatedTransactionIds,
    onCreateEmptyTransaction: handleCreateEmptyTransaction,
    onCreateTransaction: handleCreateTransaction,
    onUpdateTransaction: handleUpdateTransaction,
    onCreateUpdateTransaction: handleCreateUpdateTransaction,
    onDeleteTransaction: handleDeleteTransaction,
    onDeleteMultipleTransactions: handleDeleteMultipleTransactions,
    onToggleTransactionPaid: handleTransactionPaid,
    onDuplicateTransaction: handleDuplicateTransaction,
    onDuplicateMultipleTransactions: handleDuplicateMultipleTransactions,
    onToggleMultipleTransactionsPaid: handleToggleMultipleTransactionsPaid,

    onLoadCategories: handleLoadCategories,
    onLoadRecipients: handleLoadRecipients,

    onCreateTransferTransaction: handleCreateTransferTransaction,

    // Bank Accounts
    account_id,
    selected_account_id,
    selectedAccountIds,
    accounts,
    allAccounts,
    onRefreshBalance: handleRefreshBalance,
    onLoadBankAccounts: handleLoadBankAccounts,
    onChangeSelectedAccountIds: handleChangeSelectedAccountIds,

    onLoadParameters: handleLoadParameters,
  };
};

export default useTransactions;
