export const notificationConstants = {
  FETCH_USER_NOTIFICATIONS_REQUEST: 'FETCH_USER_NOTIFICATIONS_REQUEST',
  FETCH_USER_NOTIFICATIONS_SUCCESS: 'FETCH_USER_NOTIFICATIONS_SUCCESS',
  FETCH_USER_NOTIFICATIONS_FAILURE: 'FETCH_USER_NOTIFICATIONS_FAILURE',

  READ_USER_NOTIFICATION_REQUEST: 'READ_USER_NOTIFICATION_REQUEST',
  READ_USER_NOTIFICATION_SUCCESS: 'READ_USER_NOTIFICATION_SUCCESS',
  READ_USER_NOTIFICATION_FAILURE: 'READ_USER_NOTIFICATION_FAILURE',

  ARCHIVE_USER_NOTIFICATION_REQUEST: 'ARCHIVE_USER_NOTIFICATION_REQUEST',
  ARCHIVE_USER_NOTIFICATION_SUCCESS: 'ARCHIVE_USER_NOTIFICATION_SUCCESS',
  ARCHIVE_USER_NOTIFICATION_FAILURE: 'ARCHIVE_USER_NOTIFICATION_FAILURE',
};

export default notificationConstants;
