import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import pluralize from 'pluralize';

import FORMATTERS from 'helpers/formatters';

function Message({ activeCompany }) {
  const { subscription, pending_invoice } = activeCompany || {};
  const { status, trial_end_at } = subscription || {};

  const noActionStatuses = ['trialing', 'trial-ended', 'canceled', 'incomplete_expired'];
  const withActionStatuses = ['active', 'past_due', 'incomplete'];

  const remaining_trial_days = useMemo(() => {
    return (
      Number(
        DateTime.fromISO(trial_end_at, { zone: 'utc' }).diffNow('days').toFormat('dd'),
      ) + 1
    );
  }, [trial_end_at]);

  const prefix = useMemo(() => {
    if (remaining_trial_days === 0) {
      return 'Seu teste gratuito termina';
    }

    if (!remaining_trial_days) {
      return '';
    }

    return 'Seu teste gratuito termina em';
  }, [remaining_trial_days]);

  const days = useMemo(() => {
    if (remaining_trial_days === 0) {
      return 'hoje';
    }

    if (!remaining_trial_days) {
      return '';
    }

    return `${remaining_trial_days} ${pluralize('dia', remaining_trial_days)}`;
  }, [remaining_trial_days]);

  const trialMessage = useMemo(() => {
    if (!days || !prefix) {
      return '';
    }

    return `${prefix} ${days}.`;
  }, [days, prefix]);

  const formatDate = date => {
    return FORMATTERS.DATE_DDMMYYYY_FROM_UTC_TO_ZONE(date, 'America/Sao_Paulo');
  };

  const hasPendingInvoice = useMemo(() => {
    return pending_invoice && pending_invoice.id;
  }, [pending_invoice]);

  const MESSAGE = useMemo(
    () => ({
      trialing: trialMessage,
      'trial-ended':
        'Seu teste gratuito terminou. Assine um plano para continuar usando o Zenply!',
      canceled:
        'Sua assinatura está cancelada, assine um plano para continuar usando o Zenply.',
      past_due:
        'Sua assinatura está vencida, efetue o pagamento para continuar usando o Zenply.',
      incomplete:
        'Assinatura incompleta, finalize o pagamento para continuar usando o Zenply.',
      incomplete_expired:
        'Sua assinatura expirou por falta de pagamento. Assine um plano para continuar usando o Zenply.',
      active: '',
    }),
    [trialMessage],
  );

  const renderMessageByStatus = status => {
    if (status === 'incomplete' && !hasPendingInvoice) {
      return MESSAGE[status];
    }

    if (status === 'incomplete' && hasPendingInvoice) {
      const { payment_method, due_date } = pending_invoice;

      if (payment_method === 'BOLETO') {
        return `Boleto de assinatura disponível, vence em ${formatDate(due_date)}.`;
      }

      return MESSAGE[status];
    }

    if (status === 'past_due' && !hasPendingInvoice) {
      return MESSAGE[status];
    }

    if (status === 'past_due' && hasPendingInvoice) {
      const { payment_method, due_date, payment_method_description } = pending_invoice;

      if (payment_method === 'BOLETO') {
        return `Boleto disponível para pagamento, vence em ${formatDate(due_date)}.`;
      }

      if (payment_method === 'CREDIT_CARD') {
        return `Pagamento com ${payment_method_description} falhou.`;
      }
    }

    if (status === 'active' && hasPendingInvoice) {
      const { payment_method, due_date } = pending_invoice;

      if (payment_method === 'BOLETO') {
        return `Boleto disponível para pagamento, vence em ${formatDate(due_date)}.`;
      }

      if (payment_method === 'CREDIT_CARD') {
        return `Nova fatura disponível, vence em ${formatDate(due_date)}.`;
      }
    }

    return MESSAGE[status];
  };

  if (noActionStatuses.includes(status)) {
    return MESSAGE[status];
  }

  if (withActionStatuses.includes(status)) {
    return renderMessageByStatus(status);
  }

  return null;
}

export default Message;
