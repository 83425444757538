import constants from '../../_constants/auth.constants';
import service from '../../../services/auth.service';

const validateInviteToken = (params, callback) => {
  return dispatch => {
    dispatch(request(params));

    service
      .validateInviteToken(params)
      .then(({ data }) => {
        if (callback) {
          callback(data);
        }

        dispatch(success(data));
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request(user) {
    return { type: constants.VALIDATE_INVITE_TOKEN_REQUEST, payload: { user } };
  }
  function success(response) {
    return { type: constants.VALIDATE_INVITE_TOKEN_SUCCESS, payload: { response } };
  }
  function failure(errors) {
    return { type: constants.VALIDATE_INVITE_TOKEN_FAILURE, payload: { errors } };
  }
};

export default validateInviteToken;
