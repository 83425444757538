import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { FaBarcode } from 'react-icons/fa';
import { LuCopy, LuPrinter, LuRefreshCcw } from 'react-icons/lu';

import { Button } from '_components/_core';

import { RepeatInvoiceChargeModal } from '../..';
import { Box, BoxLabel, BoxText, BoxValue } from '../styles';

function InvoicePaymentBoleto({ invoice, onSetInvoice, onFetchInvoice }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { status, payment_method_description, metadata } = invoice;
  const { pdf } = metadata || {};

  const handleCopy = useCallback(
    async e => {
      e.preventDefault();
      e.stopPropagation();

      const { metadata } = invoice;
      const { boleto_identification_field } = metadata || {};

      await navigator.clipboard.writeText(boleto_identification_field);
    },
    [invoice],
  );

  const handleRefreshInvoice = () => {
    onFetchInvoice(invoice.id, foundInvoice => {
      onSetInvoice(foundInvoice);
    });
  };

  if (status === 'overdue') {
    return (
      <>
        <RepeatInvoiceChargeModal
          invoice={invoice}
          isVisible={isModalOpen}
          onModalToggle={() => setIsModalOpen(!isModalOpen)}
          onAfterSaveCallback={handleRefreshInvoice}
        />
        <Row>
          <Col>
            <div>
              <BoxText>O boleto venceu, gere um boleto para efetuar o pagamento.</BoxText>
            </div>
            <Button
              variant="default"
              className="d-flex justify-content-center align-items-center mt-3"
              onClick={() => setIsModalOpen(true)}
            >
              <LuRefreshCcw className="mr-2" />
              Gerar novo boleto
            </Button>
          </Col>
        </Row>
      </>
    );
  }

  if (status === 'draft') {
    return (
      <>
        <Row>
          <Col>
            <div>
              <BoxText>
                O Boleto para pagamento será gerado e ficará disponível dentro de 1 hora.
              </BoxText>
            </div>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col xs={12} md={3}>
          <Box>
            <BoxLabel>Situação</BoxLabel>
            <BoxValue status={status}>
              {invoice.status !== 'paid' ? 'Pendente' : 'Pago'}
            </BoxValue>
          </Box>
        </Col>
        <Col className="mt-3 mt-md-0" xs={12} md={4}>
          <Box>
            <BoxLabel>Forma de pagamento</BoxLabel>
            <BoxValue status={status}>
              <span className="d-flex justify-content-start align-items-center text-capitalize">
                <FaBarcode className="mr-2" />
                {payment_method_description}
              </span>
            </BoxValue>
          </Box>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col
          xs={12}
          md={12}
          className="d-flex justify-content-start align-items-center mb-3 mb-md-0"
        >
          <Button
            className="p-0 m-0 p-1 pl-2 pr-2 ml-2 d-flex justify-content-center align-items-center"
            variant="white"
            size="sm"
            onClick={() => {
              window.open(pdf, '_blank');
            }}
          >
            <LuPrinter className="mr-2" />
            Imprimir boleto
          </Button>
          <Button
            className="p-0 m-0 p-1 pl-2 pr-2 ml-2 d-flex justify-content-center align-items-center"
            variant="white"
            size="sm"
            onClick={handleCopy}
          >
            <LuCopy className="mr-2" />
            Copiar linha digitável
          </Button>
        </Col>
      </Row>
    </>
  );
}

InvoicePaymentBoleto.defaultProps = {
  invoice: null,
};

InvoicePaymentBoleto.propTypes = {
  invoice: PropTypes.object,
  onSetInvoice: PropTypes.func.isRequired,
  onRefreshPaymentData: PropTypes.func.isRequired,
};

export default InvoicePaymentBoleto;
