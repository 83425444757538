export const authConstants = {
  LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE',
  SIGN_UP_REQUEST: 'SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'SIGN_UP_FAILURE',
  LOGOUT: 'AUTH_LOGOUT',
  SIGN_UP_FORM_CLEAR: 'SIGN_UP_FORM_CLEAR',
  PASSWORD_RESET_REQUEST: 'PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_SUCCESS: 'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_FAILURE: 'PASSWORD_RESET_FAILURE',
  PASSWORD_UPDATE_REQUEST: 'PASSWORD_UPDATE_REQUEST',
  PASSWORD_UPDATE_SUCCESS: 'PASSWORD_UPDATE_SUCCESS',
  PASSWORD_UPDATE_FAILURE: 'PASSWORD_UPDATE_FAILURE',
  COMPLETE_SIGN_UP_REQUEST: 'COMPLETE_SIGN_UP_REQUEST',
  COMPLETE_SIGN_UP_SUCCESS: 'COMPLETE_SIGN_UP_SUCCESS',
  COMPLETE_SIGN_UP_FAILURE: 'COMPLETE_SIGN_UP_FAILURE',
  EMAIL_CONFIRMATION_REQUEST: 'EMAIL_CONFIRMATION_REQUEST',
  EMAIL_CONFIRMATION_SUCCESS: 'EMAIL_CONFIRMATION_SUCCESS',
  EMAIL_CONFIRMATION_FAILURE: 'EMAIL_CONFIRMATION_FAILURE',
  REQUEST_EMAIL_CONFIRM_TOKEN_REQUEST: 'REQUEST_EMAIL_CONFIRM_TOKEN_REQUEST',
  REQUEST_EMAIL_CONFIRM_TOKEN_SUCCESS: 'REQUEST_EMAIL_CONFIRM_TOKEN_SUCCESS',
  REQUEST_EMAIL_CONFIRM_TOKEN_FAILURE: 'REQUEST_EMAIL_CONFIRM_TOKEN_FAILURE',
  VALIDATE_INVITE_TOKEN_REQUEST: 'VALIDATE_INVITE_TOKEN_REQUEST',
  VALIDATE_INVITE_TOKEN_SUCCESS: 'VALIDATE_INVITE_TOKEN_SUCCESS',
  VALIDATE_INVITE_TOKEN_FAILURE: 'VALIDATE_INVITE_TOKEN_FAILURE',
};

export default authConstants;
