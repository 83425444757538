import Swal from 'sweetalert2';

import { DateTime } from 'luxon';
import isEmpty from 'lodash/isEmpty';

import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActionCreators from '../alert.actions';

const getAlertParams = ({ payment_plan, frequency_number }) => {
  let title = 'Esta transação se repete';
  let message = 'Você deseja alterar os demais itens?';
  let options = {};

  if (payment_plan === 'RECURRENT') {
    title = 'Esta transação se repete';
    message = 'Você deseja alterar os demais itens?';

    options = {
      'only-this': 'Não, alterar somente este',
      'this-and-next': 'Sim, alterar os próximos',
      'previous-and-next': 'Sim, alterar os anteriores e os próximos',
    };
  }

  if (payment_plan === 'INSTALMENT') {
    title = 'Alteração de item parcelado';
    message =
      'Essa transação é parte de um parcelamento. <br /> <strong> O que você deseja alterar? </strong>';

    options = {
      'only-this': 'Somente esta parcela',
      'this-and-next': 'Esta parcela e as próximas',
      'previous-and-next': 'As parcelas anteriores e as próximas',
    };
  }

  if (frequency_number === 1) {
    delete options['previous-and-next'];
  }

  return {
    title,
    message,
    options,
  };
};

const editTransaction = (id, values, callback) => {
  return async dispatch => {
    const { event_date, selectedDate, payment_plan, split_id } = values;

    const isRecurring = payment_plan === 'RECURRENT' || payment_plan === 'INSTALMENT';
    const isChildSplit = !isEmpty(split_id);

    const { title, message, options } = getAlertParams(values);

    const handleEditTransaction = (updateBehavior = null) => {
      let isSameMonth = true;

      if (selectedDate) {
        const luxonEventDate = DateTime.fromISO(event_date, { zone: 'utc' });

        const selectedMonthYear = selectedDate.toFormat('yyyy-MM');
        const eventMonthYear = luxonEventDate.toFormat('yyyy-MM');

        isSameMonth = selectedMonthYear === eventMonthYear;
      }

      dispatch(request(id, values, isSameMonth));

      if (!isSameMonth) {
        dispatch(alertActionCreators.info('Transação movida para outro mês!'));
      }

      service
        .updateTransaction(id, {
          ...values,
          updateBehavior,
        })
        .then(response => {
          dispatch(success(response.data, selectedDate, { id, ...values }));

          if (callback) {
            callback(response.data);
          }
        })
        .catch(error => {
          dispatch(failure(error));
        });
    };

    if (!isRecurring || (isRecurring && isChildSplit)) {
      handleEditTransaction();
      return;
    }

    confirmDialog.info({
      title,
      message,
      icon: 'info',
      input: 'radio',
      confirmButtonText: 'Alterar',
      inputValue: 'only-this',
      inputOptions: options,
      inputValidator: value => {
        if (payment_plan === 'ONE_TIME') {
          return null;
        }

        if (!value) {
          return 'Você deve escolher uma opção';
        }

        return null;
      },
      preConfirm: value => {
        if (value === 'previous-and-next') {
          const input = 'text';
          const inputPlaceholder = 'Digite "li e entendi" sem aspas';

          return new Promise(resolve => {
            confirmDialog.warning({
              title: 'Confirmação necessária',
              message: `<p class="text-danger" id="error-message">A opção "alterar itens anteriores e próximos" pode afetar o seu saldo, se houverem itens anteriores marcados como pagos.</p><p>Tem certeza que deseja continuar?</p>`,
              input,
              inputPlaceholder,
              inputAttributes: {
                style: 'width: 80%;',
              },
              confirmButtonText: 'Confirmar',
              preConfirm: inputValue => {
                const errorMessageElement = document.getElementById('error-message');
                if (inputValue !== 'li e entendi') {
                  if (errorMessageElement) {
                    Swal.showValidationMessage(
                      'Por favor, digite "li e entendi" sem aspas para confirmar a alteração.',
                    );
                  }
                  return false;
                } else {
                  if (errorMessageElement) {
                    errorMessageElement.textContent = '';
                  }
                  resolve();
                  return true;
                }
              },
            });
          });
        }
        return Promise.resolve();
      },
      onConfirm: result => {
        const { value: updateBehavior } = result;

        handleEditTransaction(updateBehavior);
      },
      onCancel: () => {},
    });
  };

  function request(id, values, isSameMonth) {
    return {
      type: constants.UPDATE_COMPANY_TRANSACTION_REQUEST,
      payload: {
        id,
        values,
        isSameMonth,
      },
    };
  }
  function success(transactions, selectedDate, originalValues) {
    return {
      type: constants.UPDATE_COMPANY_TRANSACTION_SUCCESS,
      payload: {
        transactions,
        selectedDate,
        originalValues,
      },
    };
  }
  function failure(error) {
    return {
      type: constants.UPDATE_COMPANY_TRANSACTION_FAILURE,
      payload: error,
    };
  }
};

export default editTransaction;
