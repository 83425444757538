import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company-transactions.constants';
import service from '../../../services/company-transactions.service';
import alertActions from '../alert.actions';

const unarchiveBankAccount = id => {
  return async dispatch => {
    confirmDialog.open({
      title: 'Desarquivar Conta Bancária',
      message:
        'Tem certeza que deseja desarquivar esta conta bancária? Ela voltará a ser exibida na lista de contas bancárias.',
      confirmButtonText: 'Sim, desarquivar!',
      icon: 'info',

      onConfirm: async () => {
        dispatch(request(id));

        try {
          const response = await service.unarchiveBankAccount(id);

          dispatch(success(response.data));
          dispatch(alertActions.success('Conta bancária desarquivada com sucesso'));
        } catch (error) {
          dispatch(failure(error));
        }
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return {
      type: constants.UNARCHIVE_BANK_ACCOUNT_REQUEST,
      payload: { id },
    };
  }
  function success(account) {
    return {
      type: constants.UNARCHIVE_BANK_ACCOUNT_SUCCESS,
      payload: account,
    };
  }
  function failure(error) {
    return {
      type: constants.UNARCHIVE_BANK_ACCOUNT_FAILURE,
      payload: error,
    };
  }
};

export default unarchiveBankAccount;
