import React, { useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  LuComponent,
  LuCreditCard,
  LuMoreHorizontal,
  LuStar,
  LuTrash,
} from 'react-icons/lu';
import { FaBarcode } from 'react-icons/fa';

import { useMediaQuery } from 'helpers';
import { LoadingIcon } from '_components/_shared';
import { Button, Dropdown, Tag } from '_components/_core';

import { AddPaymentMethodButton } from '..';

import { CustomBreadcrumb, CustomBreadcrumbItem, Value } from '../../../../styles';
import { CurrentPlan, PlanItem, PaymentMethodsContainer, RadioContainer } from './styles';

const PAYMENT_METHOD_ICON = {
  CREDIT_CARD: <LuCreditCard size={28} className="mr-3" />,
  PIX: <LuComponent size={28} className="mr-3" />,
  BOLETO: <FaBarcode size={28} className="mr-3" />,
};

function ChangePaymentMethod({
  subscription,
  onChangeView,
  paymentMethods,
  isLoading,
  onFetchPaymentMethods,
  onSetDefaultPaymentMethod,
  onDeletePaymentMethod,
}) {
  const { isMobile, isTablet } = useMediaQuery();

  useEffect(() => {
    onFetchPaymentMethods();
  }, [onFetchPaymentMethods]);

  const handleSetDefault = useCallback(
    paymentMethodId => {
      onSetDefaultPaymentMethod(paymentMethodId);
    },
    [onSetDefaultPaymentMethod],
  );

  const handleDelete = useCallback(
    paymentMethodId => {
      onDeletePaymentMethod(paymentMethodId);
    },
    [onDeletePaymentMethod],
  );

  return (
    <>
      <CustomBreadcrumb>
        <CustomBreadcrumbItem onClick={() => onChangeView('DEFAULT')}>
          Assinatura
        </CustomBreadcrumbItem>
        <CustomBreadcrumbItem active>Alterar forma de pagamento</CustomBreadcrumbItem>
      </CustomBreadcrumb>
      <Row className="mt-4">
        <Col md={isMobile || isTablet ? 12 : 6}>
          {isLoading && <LoadingIcon text="Carregando..." />}
          {!isLoading && (
            <>
              <PaymentMethodsContainer>
                {paymentMethods.map(paymentMethod => {
                  return (
                    <PlanItem key={paymentMethod.id}>
                      <div
                        className="d-flex justify-content-start align-items-center"
                        style={{
                          flex: 1,
                        }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          {PAYMENT_METHOD_ICON[paymentMethod.type]}
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          {paymentMethod.default && <CurrentPlan></CurrentPlan>}
                          <Value className="text-capitalize">
                            {paymentMethod.payment_method_description}
                          </Value>

                          {paymentMethod.default && (
                            <Tag
                              variant="primary"
                              className="m-0 p-0 ml-3 pl-2 pr-2 pt-1 pb-1"
                            >
                              Padrão
                            </Tag>
                          )}
                          {subscription.payment_method === paymentMethod.value && (
                            <>
                              {subscription.payment_method === 'CREDIT_CARD' && (
                                <>
                                  <small className="text-capitalize text-muted">
                                    {subscription.payment_method_description}
                                  </small>
                                </>
                              )}
                            </>
                          )}
                        </div>
                        <RadioContainer className="ml-auto">
                          <Dropdown
                            placement={'bottom-end'}
                            options={[
                              {
                                icon: <LuStar className="text-yellow" />,
                                label: 'Definir como padrão',
                                onClick: () => handleSetDefault(paymentMethod.id),
                                disabled: paymentMethod.default,
                              },
                              {
                                icon: <LuTrash className="text-danger" />,
                                label: 'Remover',
                                onClick: () => handleDelete(paymentMethod.id),
                                disabled:
                                  paymentMethod.default || paymentMethods.length === 1,
                              },
                            ]}
                          >
                            <Button
                              className="m-0 p-0 pl-2 pr-2 pt-1 pb-1"
                              variant="default"
                            >
                              <LuMoreHorizontal />
                            </Button>
                          </Dropdown>
                        </RadioContainer>
                      </div>
                    </PlanItem>
                  );
                })}
                <AddPaymentMethodButton subscription={subscription} />
              </PaymentMethodsContainer>
            </>
          )}
        </Col>
      </Row>
    </>
  );
}

export default ChangePaymentMethod;
