import { DateTime } from 'luxon';
import { notificationConstants } from '../_constants';

const INITIAL_STATE = {
  total: 0,
  notifications: [],
  isLoading: false,
};

export function notifications(state = INITIAL_STATE, action) {
  switch (action.type) {
    case notificationConstants.FETCH_USER_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case notificationConstants.FETCH_USER_NOTIFICATIONS_SUCCESS: {
      const { total, data } = action.payload;

      return {
        ...state,
        total,
        notifications: data,
        isLoading: false,
      };
    }
    case notificationConstants.FETCH_USER_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case notificationConstants.READ_USER_NOTIFICATION_REQUEST:
      const { id } = action.payload;

      const notifications = state.notifications.map(notification =>
        notification.id === id
          ? { ...notification, read_at: DateTime.now().toISO() }
          : notification,
      );

      return {
        ...state,
        isLoading: true,
        notifications,
      };
    case notificationConstants.READ_USER_NOTIFICATION_SUCCESS: {
      const { id } = action.payload;

      const notifications = state.notifications.map(notification =>
        notification.id === id
          ? { ...notification, read_at: DateTime.now().toISO() }
          : notification,
      );

      return {
        ...state,
        isLoading: false,
        notifications,
      };
    }
    case notificationConstants.READ_USER_NOTIFICATION_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case notificationConstants.ARCHIVE_USER_NOTIFICATION_REQUEST: {
      const { id } = action.payload;

      const notifications = state.notifications.filter(
        notification => notification.id !== id,
      );

      return {
        ...state,
        isLoading: true,
        notifications,
      };
    }
    case notificationConstants.ARCHIVE_USER_NOTIFICATION_SUCCESS: {
      const { id } = action.payload;

      const notifications = state.notifications.filter(
        notification => notification.id !== id,
      );

      return {
        ...state,
        isLoading: false,
        notifications,
      };
    }
    default:
      return state;
  }
}
