import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger } from 'react-bootstrap';

import { StyledPopover } from './styles';

function CustomPopover({ title, content, placement, trigger, children, ...props }) {
  const popover = (
    <StyledPopover>
      {title && <StyledPopover.Title as="h6">{title}</StyledPopover.Title>}
      <StyledPopover.Content>
        {typeof content === 'string' && <p className="p-0 m-0">{content}</p>}
        {typeof content !== 'string' && content}
      </StyledPopover.Content>
    </StyledPopover>
  );

  return (
    <OverlayTrigger placement={placement} trigger={trigger} {...props} overlay={popover}>
      <div style={{ cursor: 'pointer', flex: 1 }}>{children}</div>
    </OverlayTrigger>
  );
}

CustomPopover.defaultProps = {
  placement: 'bottom',
  trigger: 'hover',
};

CustomPopover.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node.isRequired,
  placement: PropTypes.oneOf([
    'top',
    'right',
    'bottom',
    'left',
    'auto',
    'top-start',
    'top-end',
    'right-start',
    'right-end',
    'bottom-start',
    'bottom-end',
    'left-start',
    'left-end',
  ]),
  children: PropTypes.node.isRequired,
  trigger: PropTypes.oneOf(['click', 'hover', 'focus']),
};

export default CustomPopover;
