import * as Yup from 'yup';
import { ptShort } from 'yup-locale-pt';

import { REQUIRED } from 'helpers/validation-messages';

Yup.setLocale(ptShort);

const signUpFormSchema = Yup.object().shape({
  first_name: Yup.string().min(3).max(255).required(REQUIRED),
  last_name: Yup.string().min(1).max(255).required(REQUIRED),
  email: Yup.string().max(255).email().required(REQUIRED),
  email_confirmation: Yup.string()
    .max(255)
    .email()
    .oneOf([Yup.ref('email')], 'Os e-mails não conferem')
    .required(REQUIRED),
  password: Yup.string().max(60).required(REQUIRED),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password')], 'As senhas não conferem')
    .required(REQUIRED),
});

export default signUpFormSchema;
