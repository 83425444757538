import { connect } from 'react-redux';

import CashflowByTypeReport from './CashflowByTypeReport';

const mapStateToProps = state => ({
  isLoading: state.companyTransactions.isLoading,
  accounts: state.companyTransactions.accounts,
  allAccounts: state.companyTransactions.allAccounts,
});

export default connect(mapStateToProps, null)(CashflowByTypeReport);
