import React, { useCallback } from 'react';
import { LuKey, LuUnplug, LuWebhook } from 'react-icons/lu';
import { Col, Container, Row } from 'react-bootstrap';

import { isFeatureEnabled } from 'helpers';
import { Tabs } from '_components/_core';

import { ApiKeys, Webhooks, Integrations } from './components';

function Developers({ user }) {
  const getTabs = useCallback(() => {
    const tabs = [
      {
        id: 'api-keys',
        title: 'Chaves de API',
        icon: <LuKey className="mr-2" />,
        content: <ApiKeys />,
      },
      {
        id: 'webhooks',
        title: 'Webhooks',
        icon: <LuWebhook className="mr-2" />,
        content: <Webhooks />,
      },
    ];

    if (isFeatureEnabled('integrations', user.flags)) {
      tabs.unshift({
        id: 'integracoes',
        title: 'Integrações',
        icon: <LuUnplug className="mr-2" />,
        content: <Integrations />,
      });
    }

    return tabs;
  }, [user]);

  return (
    <Container>
      <Row>
        <Col>
          <h4 className="mt-2 mb-3">API e Integrações</h4>
          <Tabs variant="secondary" tabs={getTabs()} />
        </Col>
      </Row>
    </Container>
  );
}

export default Developers;
