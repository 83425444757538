import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { SelectPaymentMethod, SelectPlan } from './components';

function Checkout({
  activeCompany,
  plans,
  subscription,
  onFetchPlans,
  onChangeView,
  onCreateCheckout,
  onFetchSubscription,
  onUpdateStoreSubscription,
  onFetchCompanies,
  onFetchActiveCompany,
  onFetchUserSeatsPricing,
}) {
  const [step, setStep] = useState(1);
  const [planId, setPlanId] = useState(null);

  useEffect(() => {
    onFetchPlans();
  }, [onFetchPlans, activeCompany]);

  const handleChoosePlan = useCallback(planId => {
    setPlanId(planId);
    setStep(2);
  }, []);

  const handleBackToPlans = useCallback(() => {
    setStep(1);
    setPlanId(null);
  }, []);

  const handleBackToHome = useCallback(() => {
    onChangeView('DEFAULT');
  }, [onChangeView]);

  const commonProps = useMemo(
    () => ({
      plans,
      planId,
      activeCompany,
      onChoosePlan: handleChoosePlan,
      onBackToPlans: handleBackToPlans,
      onBackToHome: handleBackToHome,
      onCreateCheckout,
      onFetchSubscription,
      subscription,
      onUpdateStoreSubscription,
      onFetchCompanies,
      onFetchActiveCompany,
      onChangeView,
      onFetchUserSeatsPricing,
    }),
    [
      plans,
      planId,
      activeCompany,
      handleChoosePlan,
      handleBackToPlans,
      handleBackToHome,
      subscription,
      onCreateCheckout,
      onFetchSubscription,
      onUpdateStoreSubscription,
      onFetchCompanies,
      onFetchActiveCompany,
      onChangeView,
      onFetchUserSeatsPricing,
    ],
  );

  const STEPS = useMemo(
    () => ({
      1: <SelectPlan {...commonProps} />,
      2: <SelectPaymentMethod {...commonProps} />,
    }),
    [commonProps],
  );

  return STEPS[step];
}

Checkout.defaultProps = {
  activeCompany: {},
  plans: [],
};

Checkout.propTypes = {
  plans: PropTypes.array,
  activeCompany: PropTypes.object,
  onFetchPlans: PropTypes.func,
  onChangeView: PropTypes.func,
  onCreateCheckout: PropTypes.func.isRequired,
  onUpdateStoreSubscription: PropTypes.func.isRequired,
  onFetchCompanies: PropTypes.func.isRequired,
};

export default Checkout;
