import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

import FORMATTERS from 'helpers/formatters';

import { CustomCard, CustomCardBody, CustomCardHeader } from '../../../../../styles';
import { Box, BoxLabel, BoxText, BoxValue } from '../styles';

function InvoiceHero({ invoice }) {
  return (
    <Row className="mt-3">
      <Col>
        <CustomCard>
          <CustomCardHeader>
            <h3>Dados da fatura - {invoice.provider_invoice_number}</h3>
          </CustomCardHeader>
          <CustomCardBody>
            <Row>
              <Col xs={12} md={3}>
                <Box>
                  <BoxLabel>Valor total</BoxLabel>
                  <BoxValue status={invoice.status}>
                    {FORMATTERS.NUMBER(invoice.total_amount)}
                  </BoxValue>
                </Box>
              </Col>
              <Col xs={12} md={3} className="mt-3 mt-md-0">
                <Box>
                  <BoxLabel>Data de vencimento</BoxLabel>
                  <BoxValue status={invoice.status}>
                    {FORMATTERS.DATE_DDMMYYYY(invoice.due_date)}
                  </BoxValue>
                </Box>
              </Col>
              <Col xs={6} md={3}>
                &nbsp;
              </Col>
              <Col xs={6} md={3}>
                &nbsp;
              </Col>
            </Row>
            <Row className="mt-0 mt-md-3">
              <Col>
                <Box>
                  <BoxLabel>Descrição</BoxLabel>
                  <BoxText>{invoice.description}</BoxText>
                </Box>
              </Col>
            </Row>
          </CustomCardBody>
        </CustomCard>
      </Col>
    </Row>
  );
}

InvoiceHero.defaultProps = {
  invoice: null,
};

InvoiceHero.propTypes = {
  invoice: PropTypes.object,
};

export default InvoiceHero;
