import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import { FormSelectField } from '_components/_core';

import { RequeridedIndicator } from './styles';

function FormMarketSegmentField({ segments, disabled }) {
  const formattedSegments = useMemo(
    () =>
      segments.map(segment => ({
        value: segment.id,
        label: segment.description,
      })),
    [segments],
  );

  return (
    <>
      <Form.Group>
        <Form.Label>
          Ramo de atuação
          <RequeridedIndicator>*</RequeridedIndicator>
        </Form.Label>
        <FormSelectField
          name="market_segment_id"
          placeholder="Selecione..."
          options={formattedSegments}
          disabled={disabled}
        />
      </Form.Group>
    </>
  );
}

FormMarketSegmentField.defaultProps = {
  segments: [],
  disabled: false,
};

FormMarketSegmentField.propTypes = {
  segments: PropTypes.any,
  disabled: PropTypes.bool,
};

export default FormMarketSegmentField;
