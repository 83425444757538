import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { IoAdd } from 'react-icons/io5';
import { FaPlus } from 'react-icons/fa';
import Container from 'react-bootstrap/Container';
import classNames from 'classnames';

import { Button, PageHeader, TableLookup, Tabs } from '_components/_core';

import { AccountModal } from './components';
import { DEFAULT_COLUMNS, TABLE_COLUMNS } from './utilities';

function Accounts({
  isLoading,
  accounts,
  activeCompany,
  onFetchTransactionAccounts,
  onDeleteTransactionAccount,
  onSetFavoriteBankAccount,
  onArchiveBankAccount,
  onUnarchiveBankAccount,
  isCompact,
}) {
  const [sorting, setSorting] = useState({
    field: 'description',
    order: 'asc',
  });

  useEffect(() => {
    onFetchTransactionAccounts();
  }, [onFetchTransactionAccounts, activeCompany]);

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddAccount = useCallback(() => {
    setSelectedAccount(null);

    setIsModalOpen(true);
  }, []);

  const handleEditAccount = useCallback(
    id => {
      setSelectedAccount(null);

      const account = accounts.find(account => account.id === id);

      setSelectedAccount(account);
      setIsModalOpen(true);
    },
    [accounts],
  );

  const handleDeleteAccount = useCallback(
    id => {
      const account = accounts.find(account => account.id === id);

      onDeleteTransactionAccount(account.id, account.description);
    },
    [accounts, onDeleteTransactionAccount],
  );

  const handleFavoriteAccount = useCallback(
    id => {
      onSetFavoriteBankAccount(id);
    },
    [onSetFavoriteBankAccount],
  );

  const handleArchiveUnarchiveAccount = useCallback(
    id => {
      const account = accounts.find(account => account.id === id);

      if (account.archived_at) {
        onUnarchiveBankAccount(account.id);
      } else {
        onArchiveBankAccount(account.id);
      }
    },
    [accounts, onArchiveBankAccount, onUnarchiveBankAccount],
  );

  const handleSorting = useCallback(({ field, order }) => {
    setSorting({
      field,
      order,
    });
  }, []);

  const filteredData = useMemo(() => {
    return {
      ativas: accounts.filter(account => account.archived_at === null),
      arquivadas: accounts.filter(account => account.archived_at !== null),
      todas: accounts,
    };
  }, [accounts]);

  const sortedData = useMemo(() => {
    const sortData = data => orderBy(data, sorting.field, sorting.order);

    return {
      ativas: sortData(filteredData.ativas),
      arquivadas: sortData(filteredData.arquivadas),
      todas: sortData(filteredData.todas),
    };
  }, [filteredData, sorting]);

  const renderTable = useCallback(
    data => {
      return (
        <TableLookup
          keyName="id"
          data={data}
          onEdit={handleEditAccount}
          onDelete={handleDeleteAccount}
          onArchive={handleArchiveUnarchiveAccount}
          onUnarchive={handleArchiveUnarchiveAccount}
          onFavorite={handleFavoriteAccount}
          sorting={sorting}
          onSorting={handleSorting}
          columns={TABLE_COLUMNS}
          defaultColumns={DEFAULT_COLUMNS}
          favoriteHint="Marcar como Conta Principal"
          archiveHint="Arquivar Conta Bancária"
          unarchiveHint="Desarquivar Conta Bancária"
        />
      );
    },
    [
      handleEditAccount,
      handleDeleteAccount,
      handleArchiveUnarchiveAccount,
      handleFavoriteAccount,
      sorting,
      handleSorting,
    ],
  );

  const getTabs = useCallback(() => {
    return [
      {
        id: 'ativas',
        title: `Ativas (${sortedData.ativas.length})`,
        content: renderTable(sortedData.ativas),
      },
      {
        id: 'arquivadas',
        title: `Arquivadas (${sortedData.arquivadas.length})`,
        content: renderTable(sortedData.arquivadas),
      },
      {
        id: 'todas',
        title: `Todas (${sortedData.todas.length})`,
        content: renderTable(sortedData.todas),
      },
    ];
  }, [sortedData, renderTable]);

  return (
    <Container
      fluid={!isCompact}
      className={classNames({
        'content-wrapper': !isCompact,
      })}
    >
      {!isCompact && (
        <PageHeader
          title="Contas Bancárias"
          variant="small"
          sideContent={
            <Button
              variant="dark"
              icon={<IoAdd size="1.2em" />}
              iconPosition="left"
              disabled={isLoading}
              onClick={handleAddAccount}
              name="add-account-button"
            >
              Nova Conta
            </Button>
          }
        />
      )}
      {isCompact && (
        <div className="d-flex justify-content-between align-items-end mb-3">
          <h4 className="m-0">Contas bancárias</h4>
          <Button
            variant="success-2"
            onClick={handleAddAccount}
            className="d-flex align-items-center"
          >
            Nova Conta
            <FaPlus className="ml-2" />
          </Button>
        </div>
      )}
      <AccountModal
        isVisible={isModalOpen}
        account={selectedAccount}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
      />
      <Tabs variant="secondary" tabs={getTabs()} />
    </Container>
  );
}

Accounts.defaultProps = {
  isCompact: false,
};

Accounts.propTypes = {
  activeCompany: PropTypes.object,
  accounts: PropTypes.any,
  isLoading: PropTypes.func,
  onFetchTransactionAccounts: PropTypes.func.isRequired,
  onDeleteTransactionAccount: PropTypes.func.isRequired,
  onSetFavoriteBankAccount: PropTypes.func.isRequired,
  onArchiveBankAccount: PropTypes.func.isRequired,
  onUnarchiveBankAccount: PropTypes.func.isRequired,
  isCompact: PropTypes.bool,
};

export default Accounts;
