import React from 'react';
import classNames from 'classnames';
import { LuArrowRight } from 'react-icons/lu';
import { FaQuestionCircle } from 'react-icons/fa';

import { Button, Popover } from '_components/_core';

import { Details } from './styles';
import {
  CustomCard,
  CustomCardBody,
  CustomCardFooter,
  Value,
} from '_components/Settings/components/SettingsV2/styles';

function Confirmation({
  lines,
  isLoading,
  showConfirmButton,
  disableConfirmButton,
  confirmButtonText,
  onConfirm,
}) {
  const InfoItem = ({
    title,
    content,
    onClick,
    contentStyle,
    isLoading,
    isLoadingMessage,
    className,
  }) => (
    <>
      <Value
        className={classNames({
          'w-100 d-flex justify-content-between align-items-center': true,
          [className]: className,
        })}
      >
        {title}
        {onClick && (
          <Button
            variant="link"
            size="small"
            className={classNames({
              'm-0 p-0 ml-auto d-flex justify-content-center align-items-center': true,
              'text-muted': isLoading,
            })}
            disabled={isLoading}
            onClick={onClick}
          >
            {isLoading ? isLoadingMessage : 'Alterar'}
          </Button>
        )}
      </Value>
      <Value variant="light" className="mt-1" style={contentStyle}>
        {content}
      </Value>
    </>
  );

  return (
    <>
      <CustomCard className="mt-3">
        <CustomCardBody className="pb-0">
          <InfoItem
            title="Resumo e confirmação"
            contentStyle={{ flex: 1, width: '100%' }}
            content={
              <div className="ml-auto mr-auto">
                <Details className="mt-4">
                  {lines
                    .filter(l => !l.hidden)
                    .map((line, index) => (
                      <li
                        key={index}
                        style={{
                          borderTop: line.highlight ? '1px solid #dee2e6' : 'none',
                          paddingTop: line.highlight ? '0.5rem' : '0',
                        }}
                      >
                        <span className="d-flex justify-content-center align-items-center">
                          <p
                            className={classNames({
                              'previous-value': true,
                              'no-strike': line.highlight,
                              'text-warning': line.highlight,
                            })}
                          >
                            {line.previousValue}
                          </p>
                          <LuArrowRight
                            size={14}
                            className={classNames({
                              'mx-2': true,
                              'text-warning': line.highlight,
                            })}
                          />
                          <div className="d-flex justify-content-center align-items-center">
                            <p
                              className={classNames({
                                highlight: true,
                                'd-flex justify-content-start align-items-center': true,
                                'text-warning': line.highlight,
                              })}
                            >
                              {line.currentValue}
                              {line.hint && (
                                <div className="ml-2 d-flex justify-content-center align-items-center">
                                  <Popover content={line.hint} placement="right">
                                    <FaQuestionCircle />
                                  </Popover>
                                </div>
                              )}
                            </p>
                          </div>
                        </span>
                      </li>
                    ))}
                </Details>
              </div>
            }
          />
        </CustomCardBody>
        {!isLoading && (
          <CustomCardFooter className="d-flex justify-content-center align-items-center pt-3 pb-3">
            {showConfirmButton && (
              <Button
                variant="success-2"
                className="d-flex justify-content-center align-items-center float-right"
                onClick={onConfirm}
                disabled={disableConfirmButton}
                isLoading={isLoading}
              >
                {confirmButtonText}
                <LuArrowRight size="16" className="ml-2" />
              </Button>
            )}
          </CustomCardFooter>
        )}
      </CustomCard>
    </>
  );
}

export default Confirmation;
