import isEmpty from 'lodash/isEmpty';
import * as XLSX from 'sheetjs-style';

import FORMATTERS from 'helpers/formatters';
import { CELL_TYPES } from './constants';

import getTransactionDescription from '../getTransactionDescription';
import getTransactionDate from '../getTransactionDate';

const reportToExcel = ({ report_name, reports, allAccounts, date_type }) => {
  const { results } = reports[report_name];

  if (isEmpty(results.transactions)) {
    alert('Não há transações para exportar.');

    return;
  }

  const spreadsheetData = [
    [
      'Saldo Anterior',
      'null',
      'null',
      'null',
      'null',
      'null',
      'null',
      results.initial_balance,
    ],
    [null, null, null, null, null, null, null, null],
    [
      'Pago?',
      'Data',
      'Descrição',
      'Recebido de / Pago a',
      'Categoria',
      'Conta Bancária',
      'Valor',
      'Saldo',
    ],
  ];

  const transactionsType = [null, null, null, null];
  const transactionsSubType = [null, null, null, null];

  results.transactions.map(item => {
    const transaction = item;

    transactionsType.push(transaction.type);
    transactionsSubType.push(transaction.sub_type);

    const {
      paid,
      type,
      sub_type,
      amount,
      balance,
      recipient = {},
      account = {},
    } = transaction;

    const isIncome = type === 'INCOME' || sub_type === 'RECEIVED';

    let recipient_name = '-';

    if (transaction.type !== 'TRANSFER') {
      recipient_name = recipient.name || '-';
    }

    if (transaction.type === 'TRANSFER' && transaction.transfer_details) {
      recipient_name = FORMATTERS.REPORT_CASH_FLOW_TRANSFER(
        transaction,
        allAccounts,
        false,
      );
    }

    spreadsheetData.push([
      paid ? 'Sim' : 'Não',
      getTransactionDate(transaction, date_type),
      getTransactionDescription(transaction),
      recipient_name,
      FORMATTERS.REPORT_CATEGORY_NAME(transaction, '-'),
      account.description || '-',
      isIncome ? amount : amount * -1,
      balance,
    ]);

    return {
      'Pago?': paid ? 'Sim' : 'Não',
      Data: getTransactionDate(transaction, date_type),
      Descrição: getTransactionDescription(transaction),
      'Recebido de / Pago a': recipient_name,
      Categoria: FORMATTERS.REPORT_CATEGORY_NAME(transaction, '-'),
      'Conta Bancária': account.description || '-',
      Valor: isIncome ? amount : amount * -1,
      Saldo: balance,
    };
  });

  spreadsheetData.push(
    [null, null, null, null, null, null, null, null],
    [
      'Saldo Anterior',
      'null',
      'null',
      'null',
      'null',
      'null',
      'null',
      results.initial_balance || 0,
    ],
    [
      'Total de Entradas no Período',
      'null',
      'null',
      'null',
      'null',
      'null',
      'null',
      results.total_incomes || 0,
    ],
    [
      'Total de Saídas no Período',
      'null',
      'null',
      'null',
      'null',
      'null',
      'null',
      results.total_expenses * -1 || 0,
    ],
    [
      'Balanço no Período',
      'null',
      'null',
      'null',
      'null',
      'null',
      'null',
      results.total_balance || 0,
    ],
    [
      'Saldo Final',
      'null',
      'null',
      'null',
      'null',
      'null',
      'null',
      results.final_balance || 0,
    ],
  );

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(spreadsheetData);

  worksheet['!cols'] = [
    { width: 7 },
    { width: 12 },
    { width: 35 },
    { width: 25 },
    { width: 25 },
    { width: 15 },
    { width: 13 },
    { width: 13 },
  ];

  worksheet.H1.t = 'n';
  worksheet.H1.z = '#,##0.00';

  const invalidLines = [1, 2, 3, spreadsheetData.length - 5];
  const finalLines = [
    spreadsheetData.length,
    spreadsheetData.length - 1,
    spreadsheetData.length - 2,
    spreadsheetData.length - 3,
    spreadsheetData.length - 4,
  ];

  for (let i = 1; i <= spreadsheetData.length; i += 1) {
    if (finalLines.includes(i)) {
      worksheet[`A${i}`].s = CELL_TYPES.HEADERS;
      worksheet[`B${i}`].s = CELL_TYPES.HEADERS;
      worksheet[`C${i}`].s = CELL_TYPES.HEADERS;
      worksheet[`D${i}`].s = CELL_TYPES.HEADERS;
      worksheet[`E${i}`].s = CELL_TYPES.HEADERS;
      worksheet[`F${i}`].s = CELL_TYPES.HEADERS;
      worksheet[`G${i}`].s = CELL_TYPES.HEADERS;
      worksheet[`H${i}`].s = CELL_TYPES.HEADERS;
      worksheet[`H${i}`].t = 'n';
      worksheet[`H${i}`].z = '#,##0.00';
    } else if (!invalidLines.includes(i)) {
      worksheet[`G${i}`].s =
        spreadsheetData[i - 1][6] > 0
          ? CELL_TYPES.INCOME_cashflow_statement
          : CELL_TYPES.EXPENSE_cashflow_statement;
      // worksheet[`H${i}`].s = CELL_TYPES[transactionsType[i]];
      worksheet[`G${i}`].t = 'n';
      worksheet[`G${i}`].z = '#,##0.00';
      worksheet[`H${i}`].t = 'n';
      worksheet[`H${i}`].z = '#,##0.00';
    } else if (i === 1 || i === 3) {
      worksheet[`A${i}`].s = CELL_TYPES.HEADERS;
      worksheet[`B${i}`].s = CELL_TYPES.HEADERS;
      worksheet[`C${i}`].s = CELL_TYPES.HEADERS;
      worksheet[`D${i}`].s = CELL_TYPES.HEADERS;
      worksheet[`E${i}`].s = CELL_TYPES.HEADERS;
      worksheet[`F${i}`].s = CELL_TYPES.HEADERS;
      worksheet[`G${i}`].s = CELL_TYPES.HEADERS;
      worksheet[`H${i}`].s = CELL_TYPES.HEADERS;
    }
  }

  worksheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } },
    {
      s: { r: spreadsheetData.length - 5, c: 0 },
      e: { r: spreadsheetData.length - 5, c: 6 },
    },
    {
      s: { r: spreadsheetData.length - 4, c: 0 },
      e: { r: spreadsheetData.length - 4, c: 6 },
    },
    {
      s: { r: spreadsheetData.length - 3, c: 0 },
      e: { r: spreadsheetData.length - 3, c: 6 },
    },
    {
      s: { r: spreadsheetData.length - 2, c: 0 },
      e: { r: spreadsheetData.length - 2, c: 6 },
    },
    {
      s: { r: spreadsheetData.length - 1, c: 0 },
      e: { r: spreadsheetData.length - 1, c: 6 },
    },
  ];

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Extrato');

  XLSX.writeFile(workbook, 'Zenply_Extrato.xlsx', {
    type: 'array',
    bookType: 'xlsx',
  });
};

export default reportToExcel;
