import styled, { css } from 'styled-components';

export const CompanyListItem = styled.div`
  height: 50px;
  border-radius: 6px;
  padding: 8px;
  margin-top: 0px;

  &:hover {
    background-color: #f5f5f5;
  }

  ${({ active }) =>
    active &&
    css`
    background-color: #f5f5f5;  
  `}

  div.avatar {
    height: 32px;
    width: 32px;
    margin-right: 6px;
    font-size: .875rem;
    border-radius: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e7ebe9;
    color: rgb( 48, 90, 80 );
    font-weight: 600;
  }

  strong {
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
  }

  small {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #99A1B7;
  }
`;

export const SubscriptionInfo = styled.small`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const PlanName = styled.span`

`;

export const UserCount = styled.span`

${({ status }) =>
  status === 'active' &&
  css`
      color: #00ab6f;
  `}

  ${({ status }) =>
    status === 'trialing' &&
    css`
      color: #0997d9;
  `}

  ${({ status }) =>
    status === 'trial-ended' &&
    css`
      color: #d46b08;
  `}

  ${({ status }) =>
    status === 'canceled' &&
    css`
      color: #b82a23;
  `}
`;

export const Dot = styled.span`
  padding-left: 3px;
  padding-right: 3px;
`;

export const CompanyAvatar = styled.img`
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  margin-right: 8px;

  ${({ size }) =>
    size === 'small' &&
    css`
    width: 20px;
    height: 20px;
  `}
`;
