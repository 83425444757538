import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { isEmpty, orderBy } from 'lodash';
import { LoadingIcon } from '_components/_shared';
import { LuArrowDown, LuArrowRight } from 'react-icons/lu';
import { FaChevronDown, FaQuestionCircle } from 'react-icons/fa';
import classNames from 'classnames';

import { sanitizePlanName } from 'helpers';
import FORMATTERS from 'helpers/formatters';
import { Button, Dropdown, Popover, SimpleAlert, Tag } from '_components/_core';

import {
  CustomBreadcrumb,
  CustomBreadcrumbItem,
  CustomCard,
  CustomCardBody,
  CustomCardFooter,
  Label,
  Value,
} from '../../../../styles';

import {
  CurrentPlan,
  Details,
  PlanItem,
  PlansContainer,
  RadioContainer,
  StyledCol,
} from './styles';

const CYCLE = {
  1: 'por mês',
  6: 'por semestre',
  12: 'por ano',
};

function ChangePlan({
  activeCompany,
  subscription,
  onFetchPlans,
  onChangeView,
  plans,
  onValidatePlanChange,
  onConfirmPlanChange,
}) {
  useEffect(() => {
    onFetchPlans();
  }, [onFetchPlans, activeCompany]);

  const [selectedPlanId, setSelectedPlanId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSwitchingPlan, setIsSwitchingPlan] = useState(false);
  const [overview, setOverview] = useState(null);

  const [changingTimeout, setChangingTimeout] = useState(0);

  const selectedPlan = useMemo(() => {
    return plans.find(plan => plan.id === selectedPlanId);
  }, [plans, selectedPlanId]);

  const validPlans = useMemo(() => {
    if (!subscription || isEmpty(plans)) {
      return [];
    }

    const { cycle_months } = subscription.plan;

    const mnemonicOrder = [
      'PESSOAL',
      'EMPRESARIAL',
      'BASICO',
      'INTERMEDIARIO',
      'AVANCADO',
    ];
    const mnemonicOrderFn = plan => mnemonicOrder.indexOf(plan.mnemonic_short);

    return orderBy(plans, [mnemonicOrderFn, 'price'], ['asc', 'asc']);
  }, [subscription, plans]);

  const plansOptions = useMemo(() => {
    return validPlans.map(plan => ({
      label: (
        <div className="d-flex flex-column justify-content-center align-items-start">
          <div>
            <strong>{sanitizePlanName(plan.name)}</strong>
          </div>
          <div className="mt-1">{`${FORMATTERS.NUMBER(plan.price)} ${CYCLE[plan.cycle_months]}`}</div>
        </div>
      ),
      onClick: () => handlePlanSelected(plan.id),
      disabled: plan.id === subscription.plan.id,
    }));
  }, [validPlans, subscription]);

  const delayedHandlePlanSelected = planId => {
    if (changingTimeout) {
      clearTimeout(changingTimeout);
    }

    const newChangingTimeout = setTimeout(() => {
      setIsLoading(true);

      const params = {
        new_plan_id: planId,
      };

      onValidatePlanChange(params, response => {
        setOverview(response);

        setIsLoading(false);
      });
    }, 700);

    setChangingTimeout(newChangingTimeout);
  };

  const handlePlanSelected = planId => {
    setSelectedPlanId(planId);

    delayedHandlePlanSelected(planId);
  };

  const handleConfirmChange = useCallback(() => {
    setIsSwitchingPlan(true);

    const plan = plans.find(p => p.id === selectedPlanId);

    const params = {
      new_plan_id: selectedPlanId,
      new_plan_name: plan.name,
      amount_due: overview.amount_due,
    };

    const errorCallback = () => {
      setIsSwitchingPlan(false);
    };

    onConfirmPlanChange(
      params,
      () => {
        setIsSwitchingPlan(false);

        onChangeView('DEFAULT');
      },
      errorCallback,
    );
  }, [onConfirmPlanChange, onChangeView, selectedPlanId, overview, plans]);

  const InfoItem = ({
    title,
    content,
    onClick,
    contentStyle,
    isLoading,
    isLoadingMessage,
    className,
  }) => (
    <>
      <Value
        className={classNames({
          'w-100 d-flex justify-content-between align-items-center': true,
          [className]: className,
        })}
      >
        {title}
        {onClick && (
          <Button
            variant="link"
            size="small"
            className={classNames({
              'm-0 p-0 ml-auto d-flex justify-content-center align-items-center': true,
              'text-muted': isLoading,
            })}
            disabled={isLoading}
            onClick={onClick}
          >
            {isLoading ? isLoadingMessage : 'Alterar'}
          </Button>
        )}
      </Value>
      <Value variant="light" className="mt-1" style={contentStyle}>
        {content}
      </Value>
    </>
  );

  if (!['active', 'trialing'].includes(subscription.status)) {
    return (
      <>
        <CustomBreadcrumb>
          <CustomBreadcrumbItem onClick={() => onChangeView('DEFAULT')}>
            Assinatura
          </CustomBreadcrumbItem>
          <CustomBreadcrumbItem active>Alterar plano</CustomBreadcrumbItem>
        </CustomBreadcrumb>
        <SimpleAlert variant="info">
          Só é possível alterar o plano após o período de teste.
        </SimpleAlert>
      </>
    );
  }

  return (
    <>
      <CustomBreadcrumb>
        <CustomBreadcrumbItem onClick={() => onChangeView('DEFAULT')}>
          Assinatura
        </CustomBreadcrumbItem>
        <CustomBreadcrumbItem active>Alterar plano</CustomBreadcrumbItem>
      </CustomBreadcrumb>
      <Row>
        <StyledCol sm={12}>
          <PlansContainer>
            <PlanItem className="mb-0">
              <div
                className="d-flex justify-content-start align-items-center"
                style={{ flex: 1 }}
              >
                <div>
                  <Label>{subscription.plan.name}</Label>
                  <Value variant="large">
                    {FORMATTERS.NUMBER(subscription.plan.price)}&nbsp;
                    <small>{CYCLE[subscription.plan.cycle_months]}</small>
                  </Value>
                </div>
                <div className="ml-4">
                  <CurrentPlan>
                    <Tag className="m-0 p-0 pl-2 pr-2 pt-1 pb-1" variant="warning">
                      Plano atual
                    </Tag>
                  </CurrentPlan>
                </div>
                <RadioContainer className="ml-auto">
                  <input type="radio" name="current_plan" disabled checked />
                </RadioContainer>
              </div>
            </PlanItem>
          </PlansContainer>
          <LuArrowRight size={38} className="text-muted mx-3 d-none d-md-block" />
          <LuArrowDown size={38} className="text-muted my-3 d-md-none text-center" />
          <PlansContainer>
            <Dropdown
              options={plansOptions}
              style={{
                width: 300,
              }}
              placement="bottom-start"
            >
              <PlanItem className="mb-0">
                <div
                  className="d-flex justify-content-start align-items-center"
                  style={{
                    flex: 1,
                  }}
                >
                  {selectedPlan && (
                    <div>
                      <Label>{selectedPlan.name}</Label>
                      <Value variant="large">
                        {FORMATTERS.NUMBER(selectedPlan.price)}
                        &nbsp;
                        <small>{CYCLE[selectedPlan.cycle_months]}</small>
                      </Value>
                    </div>
                  )}
                  {!selectedPlan && (
                    <div>
                      <Label>Nenhum plano selecionado</Label>
                      <Value variant="large">-</Value>
                    </div>
                  )}
                  {selectedPlan && (
                    <div className="ml-4">
                      <CurrentPlan>
                        <Tag className="m-0 p-0 pl-2 pr-2 pt-1 pb-1" variant="info">
                          Novo plano
                        </Tag>
                      </CurrentPlan>
                    </div>
                  )}
                  <RadioContainer className="ml-auto">
                    <FaChevronDown size={20} className="mr-3" />
                  </RadioContainer>
                </div>
              </PlanItem>
            </Dropdown>
          </PlansContainer>
        </StyledCol>
      </Row>
      <Row>
        <Col>
          {(overview || isLoading) && (
            <CustomCard className="mt-3">
              <CustomCardBody className="pb-0">
                <InfoItem
                  title="Resumo e confirmação"
                  contentStyle={{ flex: 1, width: '100%' }}
                  content={
                    <>
                      {isLoading && <LoadingIcon className="mt-4 mb-4" />}
                      {!isLoading && overview && (
                        <>
                          {!isEmpty(overview.warnings) && (
                            <SimpleAlert variant="warning">
                              <h5>Não é possível trocar para este plano</h5>
                              <ul>
                                {overview.warnings.map((warning, index) => (
                                  <li key={index}>{warning}</li>
                                ))}
                              </ul>
                            </SimpleAlert>
                          )}
                          <div className="ml-auto mr-auto">
                            <Details className="mt-4">
                              <li>
                                <span className="d-flex justify-content-center align-items-center">
                                  <p className="previous-value">
                                    {overview.old_plan.name}
                                  </p>
                                  <LuArrowRight size={14} className="mx-2" />
                                  <p className="highlight">{overview.new_plan.name}</p>
                                </span>
                              </li>
                              {overview.old_plan.max_transactions_per_month > 0 &&
                                overview.new_plan.max_transactions_per_month > 0 && (
                                  <li>
                                    <span className="d-flex justify-content-center align-items-center">
                                      <p className="previous-value">
                                        {overview.old_plan.max_transactions_per_month}
                                      </p>
                                      <LuArrowRight size={14} className="mx-2" />
                                      <p className="highlight">
                                        {overview.new_plan.max_transactions_per_month}{' '}
                                        transações por mês
                                      </p>
                                    </span>
                                  </li>
                                )}
                              <li>
                                <span className="d-flex justify-content-center align-items-center">
                                  <p className="previous-value">
                                    {overview.old_plan.max_users}
                                  </p>
                                  <LuArrowRight size={14} className="mx-2" />
                                  <p className="highlight">
                                    {overview.new_plan.max_users} usuários
                                  </p>
                                </span>
                              </li>
                              <li>
                                <span className="d-flex justify-content-center align-items-center">
                                  <p className="previous-value">
                                    {`${overview.old_plan.max_storage_size_megabytes / 1000} GB`}
                                  </p>
                                  <LuArrowRight size={14} className="mx-2" />
                                  <p className="highlight">
                                    {`${overview.new_plan.max_storage_size_megabytes / 1000} GB`}{' '}
                                    armazenamento
                                  </p>
                                </span>
                              </li>
                              {overview.can_change && (
                                <li>
                                  <span className="d-flex justify-content-center align-items-center">
                                    <p className="previous-value">
                                      {FORMATTERS.NUMBER(subscription.total_amount)}/
                                      {CYCLE[subscription.plan.cycle_months]}
                                    </p>
                                    <LuArrowRight size={14} className="mx-2" />
                                    <p className="highlight">
                                      {FORMATTERS.NUMBER(
                                        overview.new_subscription_amount,
                                      )}
                                      /{CYCLE[overview.new_plan.cycle_months]}
                                    </p>
                                  </span>
                                </li>
                              )}
                              {overview.amount_due > 0 && (
                                <li
                                  style={{
                                    borderTop: '1px solid #dee2e6',
                                    paddingTop: '0.5rem',
                                  }}
                                >
                                  <span className="d-flex justify-content-center align-items-center">
                                    <p className="previous-value no-strike text-warning">
                                      Valor à pagar hoje:
                                    </p>
                                    <LuArrowRight
                                      size={14}
                                      className="mx-2 text-warning"
                                    />
                                    <div className="d-flex justify-content-center align-items-center">
                                      <p className="highlight text-warning">
                                        {`${FORMATTERS.NUMBER(overview.amount_due)} (proporcional)`}
                                      </p>
                                      <Popover content="Diferença entre seu plano atual e o novo plano escolhido, calculado com base nos dias restantes até a próxima renovação.">
                                        <FaQuestionCircle />
                                      </Popover>
                                    </div>
                                  </span>
                                </li>
                              )}
                            </Details>
                          </div>
                        </>
                      )}
                    </>
                  }
                />
              </CustomCardBody>
              {!isLoading && (
                <CustomCardFooter className="d-flex justify-content-center align-items-center pt-3 pb-3">
                  {overview && (
                    <Button
                      disabled={!overview.can_change || isLoading || isSwitchingPlan}
                      variant="success-2"
                      isLoading={isSwitchingPlan}
                      onClick={handleConfirmChange}
                    >
                      Confirmar alteração de plano
                    </Button>
                  )}
                </CustomCardFooter>
              )}
            </CustomCard>
          )}
        </Col>
      </Row>
    </>
  );
}

export default ChangePlan;
