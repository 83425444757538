import styled, { css } from 'styled-components';
import { Button } from '_components/_core';
import { CustomCard } from '../../Settings/components/SettingsV2/styles';

export const PlanContainer = styled(CustomCard)`
  &:hover {
    border-color: #38a915;
    cursor: pointer;
  }

  ${({ active }) =>
    active &&
    css`
    border-color: #38a915;
  `}
`;

export const PlanDescription = styled.small`
  color: #6c757d;
  font-size: .677rem;
  font-weight: 500;
  display: block;
`;

export const PlanDiscount = styled.span`
  background-color: #d7ffbc;
  border-radius: 8px;
  padding: 4px 16px;
  position: absolute;
  right: 16px;
  top: -16px;
`;

export const PlanFeatures = styled.ul`
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    display: flex;
    flex-direction: column;
    margin-bottom: .5rem;
    font-weight: 500;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const PlanName = styled.h4`
  color: #071437;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PlanNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PlanPrice = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  color: #071437;
`;

export const StyledButton = styled(Button)`

`;

export const CustomCardBody = styled.div`
  padding: 24px 16px;

  ${({ noPadding }) =>
    noPadding &&
    css`
    padding: 0;
  `}

`;
