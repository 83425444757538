import { connect } from 'react-redux';

import authActionCreators from '_store/_actions/auth';

import CompleteSignUp from './CompleteSignUp';

const mapStateToProps = state => ({
  isLoading: state.auth.isLoading,
});

const mapDispatchToProps = {
  onCompleteSignUp: authActionCreators.completeSignUp,
  onValidateInviteToken: authActionCreators.validateInviteToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompleteSignUp);
