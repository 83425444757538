import styled, { css } from 'styled-components';

export const InvoiceHeader = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;

  div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div:last-child {
    padding: 16px;
  }
`;

export const Box = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #F1F1F4;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    padding: 0;
    margin-top: 4px;
    font-size: 16px;
  }

  small {
    margin-top: 4px;
    color: #6c757d;    
  }

`;

export const BoxLabel = styled.strong`
  font-size: 14px;
  font-weight: 500;
  color: #071437;
`;

export const BoxValue = styled.span`
  margin-top: 8px;
  font-size: 18px;
  font-weight: 500;
  color: #ec6b00;

  ${({ status }) =>
    status === 'paid' &&
    css`
      color: #198754;
  `}

  ${({ status }) =>
    status === 'warning' &&
    css`
    color: #ec6b00;
  `}

  ${({ status }) =>
    status === 'danger' &&
    css`
      color: #dc3545;
  `}

  ${({ status }) =>
    status === 'overdue' &&
    css`
      color: #dc3545;
  `}

  ${({ status }) =>
    status === 'void' &&
    css`
      color: #455560;
  `}  
`;

export const BoxText = styled.p`
  margin: 0;
  padding: 0;
  margin-top: 4px;
  font-size: 16px;
`;

export const BoxSmall = styled.small`
  margin-top: 4px;
  color: #6c757d;
`;

export const Badge = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 1rem;
  height: 1rem;
  border: 0px;
  border-radius: 9999px;
  box-shadow: 0 0 0 1px #ffffff;
  background-color: #ec6b00;
  gap: 0.25rem;
  transition: box-shadow 0.25s;

  ${({ status }) =>
    status === 'paid' &&
    css`
      background-color: #198754;
  `}

  ${({ status }) =>
    status === 'warning' &&
    css`
  background-color: #ec6b00;
  `}

  ${({ status }) =>
    status === 'danger' &&
    css`
      background-color: #dc3545;
  `}

  ${({ status }) =>
    status === 'danger' &&
    css`
      background-color: #dc3545;
  `}

  ${({ status }) =>
    status === 'overdue' &&
    css`
      background-color: #dc3545;
  `}

  ${({ status }) =>
    status === 'void' &&
    css`
      background-color: #455560;
  `}  
`;

export const Text = styled.span`
  font-weight: 400;
`;
