import { connect } from 'react-redux';

import companyTransactionsActionCreators from '_store/_actions/companyTransactions';

import TableReport from './TableReport';

const mapStateToProps = state => ({
  isLoading: state.companyTransactions.isLoading,
  reports: state.companyTransactions.reports,
  activeCompany: state.company.activeCompany,
  accounts: state.companyTransactions.accounts,
  allAccounts: state.companyTransactions.allAccounts,
});

const mapDispatchToProps = {
  onGenerateTransactionReport:
    companyTransactionsActionCreators.generateTransactionReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableReport);
