import { io } from 'socket.io-client';

class WebSocketManager {
  static instances = new Map();

  constructor(namespace) {
    if (WebSocketManager.instances.has(namespace)) {
      return WebSocketManager.instances.get(namespace);
    }

    this.namespace = namespace;
    this.socket = null;
    this.rooms = new Set();
    this.eventCallbacks = new Map();

    WebSocketManager.instances.set(namespace, this);

    return this;
  }

  connect() {
    if (this.socket && this.socket.connected) {
      console.warn('Conexão já existe. Ignorando nova tentativa.');
      return;
    }

    this.socket = io(`${process.env.REACT_APP_API_URL}/${this.namespace}`, {
      transports: ['websocket'],
    });

    this.socket.on('connect', () => {
      console.log(`Conectado ao namespace: ${this.namespace}`);
      this.rejoinRooms();
    });

    this.socket.on('disconnect', () => {
      console.log(`Desconectado do namespace: ${this.namespace}`);

      this.socket = null;
    });

    this.socket.on('connect_error', error => {
      console.error('Erro de conexão:', error);
    });
  }

  subscribe(event, callback) {
    if (!this.socket) {
      console.error('Socket não conectado. Ignorando inscrição.');
      return;
    }

    if (!this.eventCallbacks.has(event)) {
      this.eventCallbacks.set(event, callback);

      this.socket.on(event, data => {
        console.log(`Recebido evento ${event}:`, data);

        callback(data);
      });
    }
  }

  emit(event, data) {
    if (this.socket) {
      this.socket.emit(event, data);
    }
  }

  joinRoom(room) {
    if (this.socket && !this.rooms.has(room)) {
      this.rooms.add(room);
      this.socket.emit('subscribe', room);
      console.log(`Ingressou na sala: ${JSON.stringify(room)}`);
    }
  }

  leaveRoom(room) {
    if (this.socket && this.rooms.has(room)) {
      this.rooms.delete(room);
      this.socket.emit('unsubscribe', room);
      console.log(`Saiu da sala: ${JSON.stringify(room)}`);
    }
  }

  rejoinRooms() {
    if (!this.socket) {
      console.error('Socket não conectado. Ignorando reingresso nas salas.');
      return;
    }

    this.rooms.forEach(room => {
      this.socket.emit('subscribe', room);
      console.log(`Reingressou na sala: ${JSON.stringify(room)}`);
    });
  }

  disconnect() {
    if (this.socket) {
      this.rooms.clear();
      this.eventCallbacks.clear();
      this.socket.disconnect();
      this.socket = null;
    }
  }
}

export default WebSocketManager;
