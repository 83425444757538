import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { LuBuilding2, LuChevronDown, LuChevronLeft, LuUsers2 } from 'react-icons/lu';
import { FaExclamationCircle } from 'react-icons/fa';
import classNames from 'classnames';

import FORMATTERS from 'helpers/formatters';
import { Popover } from '_components/_core';

import { CustomCard, Label, Value } from '../../../../../styles';

const TUTORIAL = 'https://ajuda.zenply.com.br/assinaturas/como-trocar-de-assinatura';

function SubscriptionUsageCards({ subscription }) {
  const [showCompanies, setShowCompanies] = useState(false);

  const { type, companies, related_companies } = subscription;

  if (!subscription) {
    return null;
  }

  return (
    <>
      <Row className="mt-3">
        {!subscription.personal_account && (
          <Col sm={12}>
            <CustomCard className="p-3 d-flex justify-content-center align-items-start">
              <div className="d-flex justify-content-center align-items-center">
                <LuUsers2 size={32} className="mr-2 text-muted" />
                <div className="ml-1">
                  <Label>Usuários</Label>
                  <Value>
                    {`${subscription.users.used} de ${subscription.users.max} utilizados`}
                  </Value>
                </div>
              </div>
            </CustomCard>
          </Col>
        )}
        {type === 'MANAGER' && companies.max > 1 && (
          <Col sm={12} className="mt-3">
            <CustomCard className="p-3 d-flex justify-content-center align-items-start">
              <div
                className="d-flex justify-content-between align-items-center w-100"
                style={{ flex: 1 }}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <LuBuilding2 size={32} className="mr-2 text-muted" />
                  <div className="ml-1">
                    <Label>Empresas</Label>
                    <Value>
                      {`${subscription.companies.used} de ${subscription.companies.max} utilizadas`}
                    </Value>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <Popover
                    title="Assinatura conjunta"
                    content={
                      <p className="p-0 m-0">
                        Esta é uma assinatura conjunta, um modelo antigo de assinaturas do
                        Zenply. <br />
                        <br />
                        Empresas nesse modelo possuem a mesma quantidade de usuários,
                        dados de cobrança e ciclo de faturamento.
                        <br />
                        <br />
                        Caso queira assinar cada empresa individualmente,{' '}
                        <a href={TUTORIAL} target="_blank" rel="noreferrer">
                          siga este tutorial
                        </a>{' '}
                        para trocar o seu modelo de assinaturas.
                      </p>
                    }
                    placement="bottom-end"
                    trigger="click"
                    rootClose
                  >
                    <div className="d-flex align-items-center">
                      <FaExclamationCircle className="text-info" size={20} />
                    </div>
                  </Popover>
                  {showCompanies ? (
                    <LuChevronDown
                      size={20}
                      onClick={() => setShowCompanies(!showCompanies)}
                      className="ml-3"
                      style={{ cursor: 'pointer' }}
                    />
                  ) : (
                    <LuChevronLeft
                      size={20}
                      onClick={() => setShowCompanies(!showCompanies)}
                      className="ml-3"
                      style={{ cursor: 'pointer' }}
                    />
                  )}
                </div>
              </div>
              <div
                className={classNames({
                  'd-none': !showCompanies,
                  'w-100': true,
                  'mt-1': showCompanies,
                })}
              >
                {related_companies.map(company => (
                  <div
                    key={company.id}
                    className="d-flex justify-content-between align-items-center"
                    style={{
                      borderTop: '1px solid #f0f0f0',
                      paddingTop: '8px',
                      marginTop: '8px',
                    }}
                  >
                    <div className="d-flex flex-column justify-content-center align-items-start">
                      <small>{company.trading_name}</small>
                      <div className="text-muted">
                        <small>{FORMATTERS.CPF_CNPJ(company.document_number)}</small>
                      </div>
                    </div>
                    <div>&nbsp;</div>
                  </div>
                ))}
              </div>
            </CustomCard>
          </Col>
        )}
      </Row>
    </>
  );
}

SubscriptionUsageCards.defaultProps = {
  subscription: null,
};

SubscriptionUsageCards.propTypes = {
  subscription: PropTypes.object,
  onChangeView: PropTypes.func.isRequired,
};

export default SubscriptionUsageCards;
