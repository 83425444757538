import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';

export default function MaskInput({
  maskType,
  isValid,
  isInvalid,
  guide = false,
  innerRef,
  ...restProps
}) {
  let mask = [];

  if (maskType === 'CPF') {
    mask = [/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /\d/, /\d/];
  } else if (maskType === 'CNPJ') {
    mask = [/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/];
  } else if (maskType === 'DATE') {
    mask = [/[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  } else if (maskType === 'PHONE') {
    mask = (userInput) => {
      const numbers = userInput.match(/\d/g);
      let numberLength = 0;
      if (numbers) {
        numberLength = numbers.join('').length;
      }

      if (numberLength > 10) {
        return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
      }
      return ['(', /[1-9]/, /[1-9]/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    };
  } else if (maskType === 'ZIP_CODE') {
    mask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/];
  } else if (maskType === 'CNAE') {
    mask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, '/', /[0-9]/, /[0-9]/];
  }

  return (
    <MaskedInput
      {...restProps}
      mask={mask}
      guide={guide}
      isValid={isValid}
      isInvalid={isInvalid}
      ref={innerRef}
      type="tel"
      autoComplete="off"
    />
  );
}

MaskInput.defaultProps = {
  guide: false,
  isValid: false,
  isInvalid: false,
  innerRef: null,
};

MaskInput.propTypes = {
  maskType: PropTypes.string.isRequired,
  guide: PropTypes.bool,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  innerRef: PropTypes.object,
};
