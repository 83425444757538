import constants from '../../_constants/subscription.constants';
import service from '../../../services/subscription.service';

const fetchPersonalAccount = callback => {
  return dispatch => {
    dispatch(request());

    service
      .fetchPersonalAccount()
      .then(response => {
        const { data } = response;

        dispatch(success(data));

        if (callback) {
          callback(data);
        }
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request() {
    return {
      type: constants.FETCH_PERSONAL_ACCOUNT_REQUEST,
      payload: {},
    };
  }
  function success(data) {
    return {
      type: constants.FETCH_PERSONAL_ACCOUNT_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.FETCH_PERSONAL_ACCOUNT_FAILURE,
      payload: { error },
    };
  }
};

export default fetchPersonalAccount;
