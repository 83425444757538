import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { FaQuestionCircle } from 'react-icons/fa';

import FORMATTERS from 'helpers/formatters';
import {
  Button,
  FloatingCard,
  FormCpfCnpjField,
  FormSelectField,
  FormTextField,
  Popover,
} from '_components/_core';
import { AddressFormFields } from '_components/_shared';

import { BillingInformationSchema } from './utilities';
import {
  ModalFooter,
  SwitchContainer,
  RequiredIndicator,
  StyledFormCheck,
} from './styles';

function BillingInformation({
  isVisible,
  activeCompany,
  onModalToggle,
  subscription,
  onUpdateBillingInformation,
  onFetchSubscription,
  onAfterSaveCallback,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const { billing_info } = subscription;

  const handleSubmitForm = values => {
    setIsLoading(true);

    onUpdateBillingInformation(
      {
        ...values,
        address_zip_code: FORMATTERS.CLEAN_CEP(values.address_zip_code),
        document_number: FORMATTERS.CLEAN_CPF_CNPJ(values.document_number),
      },
      () => {
        onFetchSubscription();
        onModalToggle();

        setIsLoading(false);

        if (onAfterSaveCallback) {
          onAfterSaveCallback();
        }
      },
    );
  };

  const renderModalFooter = useCallback(
    handleSubmit => (
      <ModalFooter>
        <Button variant="secondary" onClick={() => onModalToggle()}>
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="dark"
          onClick={handleSubmit}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Salvar alterações
        </Button>
      </ModalFooter>
    ),
    [onModalToggle, isLoading],
  );

  const fillActiveCompanyData = useCallback(
    setFieldValue => {
      setFieldValue('name', activeCompany.company_name);
      setFieldValue('document_type', activeCompany.document_type);
      setFieldValue('document_number', activeCompany.document_number);
      setFieldValue('phone_number', activeCompany.phone_number);
      setFieldValue('address_zip_code', activeCompany.address_zip_code);
      setFieldValue('address_street', activeCompany.address_street);
      setFieldValue('address_number', activeCompany.address_number);
      setFieldValue('address_district', activeCompany.address_district);
      setFieldValue('address_complement', activeCompany.address_complement);
      setFieldValue('address_city', activeCompany.address_city);
      setFieldValue('address_city_ibge', activeCompany.address_city_ibge);
      setFieldValue('address_state', activeCompany.address_state);
      setFieldValue('address_state_ibge', activeCompany.address_state_ibge);
    },
    [activeCompany],
  );

  if (!subscription) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        name: billing_info.name,
        document_type:
          billing_info.document_type || (subscription.personal_account ? 'CPF' : 'CNPJ'),
        document_number: billing_info.document_number,
        phone_number: billing_info.phone_number,
        email_primary: billing_info.email_primary,
        email_secondary: billing_info.email_secondary,
        address_zip_code: billing_info.address_zip_code,
        address_street: billing_info.address_street,
        address_number: billing_info.address_number,
        address_district: billing_info.address_district,
        address_complement: billing_info.address_complement,
        address_city: billing_info.address_city,
        address_city_ibge: billing_info.address_city_ibge,
        address_state: billing_info.address_state,
        address_state_ibge: billing_info.address_state_ibge,
        address_country: 'BR',
      }}
      validationSchema={BillingInformationSchema}
      onSubmit={handleSubmitForm}
      enableReinitialize
    >
      {({ handleSubmit, isValid, values, setFieldValue, setErrors, validateForm }) => (
        <>
          <FloatingCard
            title="Alterar dados de cobrança"
            fullHeight
            isVisible={isVisible}
            onToggleVisibility={onModalToggle}
            footerContent={renderModalFooter(handleSubmit, isValid)}
            withCloseButton
            width={900}
            bodyClassName="p-3"
          >
            <Form onSubmit={handleSubmit}>
              {!subscription.personal_account && (
                <Form.Row>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Dados de cobrança</Form.Label>
                    <SwitchContainer>
                      <StyledFormCheck
                        type="checkbox"
                        id="use_active_company"
                        label="Usar os mesmos dados de exibição da empresa"
                        onChange={e => {
                          if (e.target.checked) {
                            fillActiveCompanyData(setFieldValue);
                          }

                          setFieldValue('use_active_company', e.target.checked);
                          validateForm();
                        }}
                      />
                    </SwitchContainer>
                  </Form.Group>
                </Form.Row>
              )}
              <Form.Row>
                <Form.Group as={Col} md="6">
                  <Form.Label>
                    Tipo de documento
                    <RequiredIndicator>*</RequiredIndicator>
                  </Form.Label>
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <FormSelectField
                        name="document_type"
                        options={[
                          { value: 'CPF', label: 'CPF' },
                          { value: 'CNPJ', label: 'CNPJ' },
                        ]}
                        onChange={option => {
                          setFieldValue('document_type', option.value);
                        }}
                        disabled={subscription.personal_account}
                      />
                    </div>
                    {subscription.personal_account && (
                      <div className="ml-3">
                        <Popover content="Para contas pessoais o tipo de documento para nota fiscal deve ser CPF">
                          <FaQuestionCircle className="text-muted" />
                        </Popover>
                      </div>
                    )}
                  </div>
                </Form.Group>
                <Form.Group as={Col} md="6">
                  <Form.Label>
                    Número do documento<RequiredIndicator>*</RequiredIndicator>
                  </Form.Label>
                  <FormCpfCnpjField
                    name="document_number"
                    type={values.document_type}
                    placeholder="Digite o número do documento"
                  />
                </Form.Group>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    {values.document_type === 'CPF' ? 'Nome completo' : 'Razão social'}
                  </Form.Label>
                  <FormTextField
                    name="name"
                    placeholder={
                      values.document_type === 'CPF' ? 'Nome completo' : 'Razão social'
                    }
                  />
                </Form.Group>
              </Form.Row>
              <hr />
              <Form.Row>
                <Form.Group as={Col} md="12">
                  <Form.Label>
                    E-mail de cobrança<RequiredIndicator>*</RequiredIndicator>
                  </Form.Label>
                  <FormTextField
                    name="email_primary"
                    placeholder="E-mail de cobrança"
                    type="email"
                    disabled
                  />
                </Form.Group>
              </Form.Row>
              <hr />
              <AddressFormFields
                requiredFields={[
                  'address_zip_code',
                  'address_street',
                  'address_number',
                  'address_district',
                  'address_city',
                  'address_city_ibge',
                  'address_state',
                  'address_state_ibge',
                ]}
                columnSizes={{
                  cep: 4,
                  searchButton: 8,
                  street: 4,
                  number: 4,
                  district: 4,
                  complement: 4,
                  city: 4,
                  state: 4,
                }}
              />
            </Form>
          </FloatingCard>
        </>
      )}
    </Formik>
  );
}

BillingInformation.defaultProps = {
  variant: 'button',
};

BillingInformation.propTypes = {
  variant: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  subscription: PropTypes.object,
  onModalToggle: PropTypes.func.isRequired,
  onUpdateBillingInformation: PropTypes.func.isRequired,
  onFetchSubscription: PropTypes.func.isRequired,
  onFetchCep: PropTypes.func.isRequired,
  onAfterSaveCallback: PropTypes.func.isRequired,
};

export default BillingInformation;
