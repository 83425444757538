export const subscriptionConstants = {
  FETCH_SUBSCRIPTION_PLANS_REQUEST: 'FETCH_SUBSCRIPTION_PLANS_REQUEST',
  FETCH_SUBSCRIPTION_PLANS_SUCCESS: 'FETCH_SUBSCRIPTION_PLANS_SUCCESS',
  FETCH_SUBSCRIPTION_PLANS_FAILURE: 'FETCH_SUBSCRIPTION_PLANS_FAILURE',
  CREATE_CHECKOUT_SESSION_REQUEST: 'CREATE_CHECKOUT_SESSION_REQUEST',
  CREATE_CHECKOUT_SESSION_SUCCESS: 'CREATE_CHECKOUT_SESSION_SUCCESS',
  CREATE_CHECKOUT_SESSION_FAILURE: 'CREATE_CHECKOUT_SESSION_FAILURE',
  FETCH_SUBSCRIPTION_STATUS_REQUEST: 'FETCH_SUBSCRIPTION_STATUS_REQUEST',
  FETCH_SUBSCRIPTION_STATUS_SUCCESS: 'FETCH_SUBSCRIPTION_STATUS_SUCCESS',
  FETCH_SUBSCRIPTION_STATUS_FAILURE: 'FETCH_SUBSCRIPTION_STATUS_FAILURE',
  CREATE_AUTHORIZED_CHECKOUT_SESSION_REQUEST:
    'CREATE_AUTHORIZED_CHECKOUT_SESSION_REQUEST',
  CREATE_AUTHORIZED_CHECKOUT_SESSION_SUCCESS:
    'CREATE_AUTHORIZED_CHECKOUT_SESSION_SUCCESS',
  CREATE_AUTHORIZED_CHECKOUT_SESSION_FAILURE:
    'CREATE_AUTHORIZED_CHECKOUT_SESSION_FAILURE',
  UPDATE_PLAN_REQUEST: 'UPDATE_PLAN_REQUEST',
  UPDATE_PLAN_SUCCESS: 'UPDATE_PLAN_SUCCESS',
  UPDATE_PLAN_FAILURE: 'UPDATE_PLAN_FAILURE',
  CREATE_UPDATE_PAYMENT_SESSION_REQUEST: 'CREATE_UPDATE_PAYMENT_SESSION_REQUEST',
  CREATE_UPDATE_PAYMENT_SESSION_SUCCESS: 'CREATE_UPDATE_PAYMENT_SESSION_SUCCESS',
  CREATE_UPDATE_PAYMENT_SESSION_FAILURE: 'CREATE_UPDATE_PAYMENT_SESSION_FAILURE',

  FETCH_COMPANY_SUBSCRIPTION_REQUEST: 'FETCH_COMPANY_SUBSCRIPTION_REQUEST',
  FETCH_COMPANY_SUBSCRIPTION_SUCCESS: 'FETCH_COMPANY_SUBSCRIPTION_SUCCESS',
  FETCH_COMPANY_SUBSCRIPTION_FAILURE: 'FETCH_COMPANY_SUBSCRIPTION_FAILURE',

  FETCH_COMPANY_SUBSCRIPTION_PLANS_REQUEST: 'FETCH_COMPANY_SUBSCRIPTION_PLANS_REQUEST',
  FETCH_COMPANY_SUBSCRIPTION_PLANS_SUCCESS: 'FETCH_COMPANY_SUBSCRIPTION_PLANS_SUCCESS',
  FETCH_COMPANY_SUBSCRIPTION_PLANS_FAILURE: 'FETCH_COMPANY_SUBSCRIPTION_PLANS_FAILURE',

  UPDATE_BILLING_INFORMATION_REQUEST: 'UPDATE_BILLING_INFORMATION_REQUEST',
  UPDATE_BILLING_INFORMATION_SUCCESS: 'UPDATE_BILLING_INFORMATION_SUCCESS',
  UPDATE_BILLING_INFORMATION_FAILURE: 'UPDATE_BILLING_INFORMATION_FAILURE',

  CREATE_CHECKOUT_REQUEST: 'CREATE_CHECKOUT_REQUEST',
  CREATE_CHECKOUT_SUCCESS: 'CREATE_CHECKOUT_SUCCESS',
  CREATE_CHECKOUT_FAILURE: 'CREATE_CHECKOUT_FAILURE',

  CANCEL_SUBSCRIPTION_REQUEST: 'CANCEL_SUBSCRIPTION_REQUEST',
  CANCEL_SUBSCRIPTION_SUCCESS: 'CANCEL_SUBSCRIPTION_SUCCESS',
  CANCEL_SUBSCRIPTION_FAILURE: 'CANCEL_SUBSCRIPTION_FAILURE',

  FETCH_COMPANY_SUBSCRIPTION_INVOICES_REQUEST:
    'FETCH_COMPANY_SUBSCRIPTION_INVOICES_REQUEST',
  FETCH_COMPANY_SUBSCRIPTION_INVOICES_SUCCESS:
    'FETCH_COMPANY_SUBSCRIPTION_INVOICES_SUCCESS',
  FETCH_COMPANY_SUBSCRIPTION_INVOICES_FAILURE:
    'FETCH_COMPANY_SUBSCRIPTION_INVOICES_FAILURE',

  FETCH_USER_SEATS_PRICING_REQUEST: 'FETCH_USER_SEATS_PRICING_REQUEST',
  FETCH_USER_SEATS_PRICING_SUCCESS: 'FETCH_USER_SEATS_PRICING_SUCCESS',
  FETCH_USER_SEATS_PRICING_FAILURE: 'FETCH_USER_SEATS_PRICING_FAILURE',

  UPDATE_STORE_SUBSCRIPTION_REQUEST: 'UPDATE_STORE_SUBSCRIPTION_REQUEST',
  CLEAR_PAYMENT_ALERT_REQUEST: 'CLEAR_PAYMENT_ALERT_REQUEST',

  VALIDATE_PLAN_CHANGE_REQUEST: 'VALIDATE_PLAN_CHANGE_REQUEST',
  VALIDATE_PLAN_CHANGE_SUCCESS: 'VALIDATE_PLAN_CHANGE_SUCCESS',
  VALIDATE_PLAN_CHANGE_FAILURE: 'VALIDATE_PLAN_CHANGE_FAILURE',

  REFRESH_PAYMENT_DATA_REQUEST: 'REFRESH_PAYMENT_DATA_REQUEST',
  REFRESH_PAYMENT_DATA_SUCCESS: 'REFRESH_PAYMENT_DATA_SUCCESS',
  REFRESH_PAYMENT_DATA_FAILURE: 'REFRESH_PAYMENT_DATA_FAILURE',

  CONFIRM_USER_SEATS_PURCHASE_REQUEST: 'CONFIRM_USER_SEATS_PURCHASE_REQUEST',
  CONFIRM_USER_SEATS_PURCHASE_SUCCESS: 'CONFIRM_USER_SEATS_PURCHASE_SUCCESS',
  CONFIRM_USER_SEATS_PURCHASE_FAILURE: 'CONFIRM_USER_SEATS_PURCHASE_FAILURE',

  FETCH_MANAGER_SUBSCRIPTION_REQUEST: 'FETCH_MANAGER_SUBSCRIPTION_REQUEST',
  FETCH_MANAGER_SUBSCRIPTION_SUCCESS: 'FETCH_MANAGER_SUBSCRIPTION_SUCCESS',
  FETCH_MANAGER_SUBSCRIPTION_FAILURE: 'FETCH_MANAGER_SUBSCRIPTION_FAILURE',

  CONFIRM_COMPANY_SEATS_PURCHASE_REQUEST: 'CONFIRM_COMPANY_SEATS_PURCHASE_REQUEST',
  CONFIRM_COMPANY_SEATS_PURCHASE_SUCCESS: 'CONFIRM_COMPANY_SEATS_PURCHASE_SUCCESS',
  CONFIRM_COMPANY_SEATS_PURCHASE_FAILURE: 'CONFIRM_COMPANY_SEATS_PURCHASE_FAILURE',

  FETCH_COMPANY_SEATS_PRICING_REQUEST: 'FETCH_COMPANY_SEATS_PRICING_REQUEST',
  FETCH_COMPANY_SEATS_PRICING_SUCCESS: 'FETCH_COMPANY_SEATS_PRICING_SUCCESS',
  FETCH_COMPANY_SEATS_PRICING_FAILURE: 'FETCH_COMPANY_SEATS_PRICING_FAILURE',

  GET_UPDATE_PAYMENT_SESSION_URL_REQUEST: 'GET_UPDATE_PAYMENT_SESSION_URL_REQUEST',
  GET_UPDATE_PAYMENT_SESSION_URL_SUCCESS: 'GET_UPDATE_PAYMENT_SESSION_URL_SUCCESS',
  GET_UPDATE_PAYMENT_SESSION_URL_FAILURE: 'GET_UPDATE_PAYMENT_SESSION_URL_FAILURE',

  GET_INVOICE_BY_ID_REQUEST: 'GET_INVOICE_BY_ID_REQUEST',
  GET_INVOICE_BY_ID_SUCCESS: 'GET_INVOICE_BY_ID_SUCCESS',
  GET_INVOICE_BY_ID_FAILURE: 'GET_INVOICE_BY_ID_FAILURE',

  FETCH_PAYMENT_METHODS_REQUEST: 'FETCH_PAYMENT_METHODS_REQUEST',
  FETCH_PAYMENT_METHODS_SUCCESS: 'FETCH_PAYMENT_METHODS_SUCCESS',
  FETCH_PAYMENT_METHODS_FAILURE: 'FETCH_PAYMENT_METHODS_FAILURE',

  UPDATE_PAYMENT_METHOD_REQUEST: 'UPDATE_PAYMENT_METHOD_REQUEST',
  UPDATE_PAYMENT_METHOD_SUCCESS: 'UPDATE_PAYMENT_METHOD_SUCCESS',
  UPDATE_PAYMENT_METHOD_FAILURE: 'UPDATE_PAYMENT_METHOD_FAILURE',

  DELETE_PAYMENT_METHOD_REQUEST: 'DELETE_PAYMENT_METHOD_REQUEST',
  DELETE_PAYMENT_METHOD_SUCCESS: 'DELETE_PAYMENT_METHOD_SUCCESS',
  DELETE_PAYMENT_METHOD_FAILURE: 'DELETE_PAYMENT_METHOD_FAILURE',

  SET_DEFAULT_PAYMENT_METHOD_REQUEST: 'SET_DEFAULT_PAYMENT_METHOD_REQUEST',
  SET_DEFAULT_PAYMENT_METHOD_SUCCESS: 'SET_DEFAULT_PAYMENT_METHOD_SUCCESS',
  SET_DEFAULT_PAYMENT_METHOD_FAILURE: 'SET_DEFAULT_PAYMENT_METHOD_FAILURE',

  CREATE_PAYMENT_METHOD_REQUEST: 'CREATE_PAYMENT_METHOD_REQUEST',
  CREATE_PAYMENT_METHOD_SUCCESS: 'CREATE_PAYMENT_METHOD_SUCCESS',
  CREATE_PAYMENT_METHOD_FAILURE: 'CREATE_PAYMENT_METHOD_FAILURE',

  CONFIRM_PLAN_CHANGE_REQUEST: 'CONFIRM_PLAN_CHANGE_REQUEST',
  CONFIRM_PLAN_CHANGE_SUCCESS: 'CONFIRM_PLAN_CHANGE_SUCCESS',
  CONFIRM_PLAN_CHANGE_FAILURE: 'CONFIRM_PLAN_CHANGE_FAILURE',

  PAY_INVOICE_REQUEST: 'PAY_INVOICE_REQUEST',
  PAY_INVOICE_SUCCESS: 'PAY_INVOICE_SUCCESS',
  PAY_INVOICE_FAILURE: 'PAY_INVOICE_FAILURE',

  KEEP_SUBSCRIPTION_REQUEST: 'KEEP_SUBSCRIPTION_REQUEST',
  KEEP_SUBSCRIPTION_SUCCESS: 'KEEP_SUBSCRIPTION_SUCCESS',
  KEEP_SUBSCRIPTION_FAILURE: 'KEEP_SUBSCRIPTION_FAILURE',

  FETCH_PERSONAL_ACCOUNT_REQUEST: 'FETCH_PERSONAL_ACCOUNT_REQUEST',
  FETCH_PERSONAL_ACCOUNT_SUCCESS: 'FETCH_PERSONAL_ACCOUNT_SUCCESS',
  FETCH_PERSONAL_ACCOUNT_FAILURE: 'FETCH_PERSONAL_ACCOUNT_FAILURE',

  VALIDATE_USER_SEATS_PURCHASE_REQUEST: 'VALIDATE_USER_SEATS_PURCHASE_REQUEST',
  VALIDATE_USER_SEATS_PURCHASE_SUCCESS: 'VALIDATE_USER_SEATS_PURCHASE_SUCCESS',
  VALIDATE_USER_SEATS_PURCHASE_FAILURE: 'VALIDATE_USER_SEATS_PURCHASE_FAILURE',
};

export default subscriptionConstants;
