import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { LuAlertCircle, LuChevronDown, LuChevronLeft } from 'react-icons/lu';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import has from 'lodash/has';

import { sanitizePlanName } from 'helpers';
import FORMATTERS from 'helpers/formatters';
import { SubscriptionStatusTag } from '_components/_shared';

import {
  CustomCard,
  CustomCardBody,
  CustomCardFooter,
  Label,
  Value,
} from '../../../../../styles';

const CYCLE = {
  1: 'mensal',
  6: 'semestral',
  12: 'anual',
};

function SubscriptionAmountCard({ subscription }) {
  const [showAddons, setShowAddons] = useState(false);

  const { status, pending_invoice, addons = [] } = subscription || {};
  const { metadata, reason } = pending_invoice || {};

  const formattedAddons = useMemo(() => {
    return addons.map(addon => {
      const { product_id, plan_id } = addon;

      let name = '';
      let description = '';
      let quantity = 0;
      let price = 0;
      let total = 0;

      if (product_id) {
        name = addon.product_name;
        quantity = addon.quantity;
        price = addon.unit_amount;
        total = addon.total_amount;
      }

      if (plan_id) {
        name = `Plano ${sanitizePlanName(addon.plan_name)}`;
        quantity = addon.quantity;
        price = addon.plan_price;
        total = addon.total_amount;
      }

      return {
        ...addon,
        name,
        description,
        quantity,
        price,
        total,
      };
    });
  }, [addons]);

  if (!subscription) {
    return null;
  }

  return (
    <CustomCard>
      <ReactTooltip effect="solid" />
      <CustomCardBody>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <Label>Plano {sanitizePlanName(subscription.plan.name)}</Label>
            <Value variant="large" className="mt-2">
              {FORMATTERS.NUMBER(subscription.total_amount)}&nbsp;
              <small>{CYCLE[subscription.cycle_months]}</small>
            </Value>
          </div>
          <div className="d-flex align-items-center">
            <SubscriptionStatusTag status={subscription.status} />
            {showAddons ? (
              <LuChevronDown
                size={20}
                onClick={() => setShowAddons(!showAddons)}
                className="ml-3"
                style={{ cursor: 'pointer' }}
              />
            ) : (
              <LuChevronLeft
                size={20}
                onClick={() => setShowAddons(!showAddons)}
                className="ml-3"
                style={{ cursor: 'pointer' }}
              />
            )}
          </div>
        </div>
        <div
          className={classNames({
            'd-none': !showAddons,
          })}
        >
          {formattedAddons.map((addon, index) => (
            <div
              key={addon.id}
              className="d-flex justify-content-between align-items-center"
              style={{
                borderTop: '1px solid #f0f0f0',
                paddingTop: index === 0 ? '12px' : '8px',
                marginTop: '8px',
              }}
            >
              <div className="d-flex flex-column justify-content-center align-items-start">
                <small>{addon.name}</small>
                <div className="text-muted">
                  <small className="mr-2">Qtde: {addon.quantity}</small>•
                  <small className="ml-2">{FORMATTERS.NUMBER(addon.price)}</small>
                </div>
              </div>
              <div>
                <small style={{ fontWeight: '600' }}>
                  {FORMATTERS.NUMBER(addon.total)}
                </small>
              </div>
            </div>
          ))}
        </div>
      </CustomCardBody>
      {status === 'incomplete' && (
        <CustomCardFooter>
          <small className="text-muted">Aguardando pagamento</small>
          <LuAlertCircle
            style={{
              cursor: 'pointer',
            }}
            data-place="bottom"
            data-tip="Conclua o pagamento da fatura em aberto para que sua assinatura seja efetivada."
            className="text-info"
          />
        </CustomCardFooter>
      )}
      {status === 'past_due' &&
        reason === 'SUBSCRIPTION_UPDATE' &&
        has(metadata, 'action') && (
          <CustomCardFooter>
            {metadata.action === 'CHANGE_PLAN' && (
              <>
                <small className="text-muted">
                  Atualização pendente (plano {metadata.new_plan_name})
                </small>
                <LuAlertCircle
                  style={{
                    cursor: 'pointer',
                  }}
                  data-place="bottom"
                  data-tip="Conclua o pagamento da fatura em aberto para atualizar seu plano."
                  className="text-info"
                />
              </>
            )}
            {metadata.action === 'ADD_USERS' && (
              <>
                <small className="text-muted">
                  Atualização pendente (compra de {metadata.quantity} usuários adicionais)
                </small>
                <LuAlertCircle
                  style={{
                    cursor: 'pointer',
                  }}
                  data-place="bottom"
                  data-tip="Conclua o pagamento da fatura em aberto para atualizar a assinatura."
                  className="text-info"
                />
              </>
            )}
          </CustomCardFooter>
        )}
    </CustomCard>
  );
}

SubscriptionAmountCard.defaultProps = {
  subscription: null,
};

SubscriptionAmountCard.propTypes = {
  subscription: PropTypes.object,
};

export default SubscriptionAmountCard;
