import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { LuPlus, LuTrash, LuPencil, LuArrowRight } from 'react-icons/lu';

import { Button, Tag } from '_components/_core';

import { WebhookEvents, WebhookModal } from './components';

import EmptyMessage from '../../../EmptyMessage';
import { CustomCard, CustomCardBody, CustomCardHeader } from '../../../../styles';
import { StyledTable } from './styles';

const Webhooks = ({ isLoading, webhooks, onDeleteWebhook, onFetchWebhooks }) => {
  const [isWebhookModalOpen, setIsWebhookModalOpen] = useState(false);
  const [selectedWebhook, setSelectedWebhook] = useState(null);
  const [showEvents, setShowEvents] = useState(null);

  useEffect(() => {
    onFetchWebhooks();
  }, [onFetchWebhooks]);

  const handleOpenCreateModal = useCallback(() => {
    setSelectedWebhook(null);
    setIsWebhookModalOpen(true);
  }, []);

  const handleOpenEditModal = useCallback(webhook => {
    setSelectedWebhook(webhook);
    setIsWebhookModalOpen(true);
  }, []);

  const handleDeleteWebhook = useCallback(
    webhookId => {
      onDeleteWebhook(webhookId, () => {
        onFetchWebhooks();
      });
    },
    [onDeleteWebhook, onFetchWebhooks],
  );

  const handleViewWebhook = useCallback(webhook => {
    setSelectedWebhook(webhook);
    setShowEvents(true);
  }, []);

  if (showEvents && selectedWebhook) {
    return (
      <WebhookEvents
        webhook={selectedWebhook}
        onBack={() => {
          setShowEvents(false);
          setSelectedWebhook(null);
        }}
      />
    );
  }

  return (
    <>
      <WebhookModal
        isVisible={isWebhookModalOpen}
        onModalToggle={() => setIsWebhookModalOpen(!isWebhookModalOpen)}
        webhook={selectedWebhook}
      />
      <CustomCard>
        <CustomCardHeader className="d-flex justify-content-between align-items-center">
          <h3>Webhooks</h3>
          <Button
            size="sm"
            variant="dark"
            className="d-flex justify-content-center"
            onClick={handleOpenCreateModal}
          >
            Novo Webhook
            <LuPlus className="ml-1" />
          </Button>
        </CustomCardHeader>
        <CustomCardBody noPadding>
          {isEmpty(webhooks) && <EmptyMessage />}
          <div className="table-responsive">
            {!isEmpty(webhooks) && (
              <StyledTable className="table table-hover">
                <thead>
                  <tr>
                    <th style={{ minWidth: '500px' }} className="text-left">
                      Nome / Endereço
                    </th>
                    <th className="text-center">Status</th>
                    <th width="5%" className="text-center">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {webhooks.map(webhook => {
                    const { id, name, url, enabled } = webhook;

                    return (
                      <tr key={id} onClick={() => handleViewWebhook(webhook)}>
                        <td>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex flex-column justify-content-center align-items-start">
                              <strong>{name}</strong>
                              <div className="mt-2 text-muted">{url}</div>
                            </div>
                            <div className="d-flex justify-content-center align-items-center">
                              <Button
                                variant="link"
                                className="p-0 m-0 d-flex justify-content-center align-items-center"
                              >
                                Visualizar eventos
                                <LuArrowRight className="ml-2" />
                              </Button>
                            </div>
                          </div>
                        </td>
                        <td className="text-center">
                          {enabled && <Tag variant="success">Ativado</Tag>}
                          {!enabled && <Tag variant="danger">Desativado</Tag>}
                        </td>
                        <td className="text-center">
                          <span className="d-flex justify-content-center align-items-center bg-black">
                            <Button
                              size="sm"
                              variant="default"
                              className="d-flex justify-content-center align-items-center"
                              onClick={e => {
                                e.stopPropagation();

                                handleOpenEditModal(webhook);
                              }}
                              isLoading={isLoading}
                              disabled={isLoading}
                            >
                              <LuPencil />
                            </Button>
                            <Button
                              size="sm"
                              variant="inverse-danger"
                              className="ml-2 d-flex justify-content-center align-items-center"
                              onClick={e => {
                                e.stopPropagation();

                                handleDeleteWebhook(id);
                              }}
                              disabled={isLoading}
                              isLoading={isLoading}
                            >
                              <LuTrash />
                            </Button>
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </StyledTable>
            )}
          </div>
        </CustomCardBody>
      </CustomCard>
    </>
  );
};

Webhooks.propTypes = {
  webhooks: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onDeleteWebhook: PropTypes.func.isRequired,
  onFetchWebhooks: PropTypes.func.isRequired,
};

export default Webhooks;
