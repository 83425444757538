import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { LuInfo, LuStar, LuX } from 'react-icons/lu';
import { FaExclamationCircle } from 'react-icons/fa';

import FORMATTERS from 'helpers/formatters';
import { Button } from '_components/_core';

import { Container, StyledTag, StyledText } from './styles';

const VARIANT_ICONS = {
  info: <LuInfo size={20} />,
  success: <LuStar size={20} className="text-yellow" />,
  danger: <FaExclamationCircle size={20} className="text-danger" />,
  warning: <FaExclamationCircle size={20} className="text-warning" />,
};

function DashboardNotifications({
  notifications,
  onFetchNotifications,
  onReadNotification,
  onArchiveNotification,
}) {
  useEffect(() => {
    onFetchNotifications();
  }, [onFetchNotifications]);

  const dashboardNotifications = useMemo(() => {
    return notifications.filter(
      notification => notification.important && !notification.archived_at,
    );
  });

  const handleRedirect = useCallback(
    notification => {
      onReadNotification(notification.id);

      window.open(notification.redirect_url, '_blank');
    },
    [onReadNotification],
  );

  const handleDismissAlert = useCallback(
    notification => {
      onArchiveNotification(notification.id, () => {});
    },
    [onArchiveNotification],
  );

  return (
    <Container>
      {dashboardNotifications.map(notification => (
        <StyledTag variant={notification.variant} className="p-3">
          <div className="d-flex">
            <span className="d-flex justify-content-center align-items-center">
              {VARIANT_ICONS[notification.variant]}
            </span>
            <div className="ml-3">
              <h6 className="m-0">{notification.title}</h6>
              <StyledText className="mt-1">{notification.description}</StyledText>
              {notification.redirect_url && (
                <Button
                  onClick={() => handleRedirect(notification)}
                  variant="link"
                  className="m-0 p-0 mt-1"
                >
                  Ver detalhes
                </Button>
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <small className="text-muted">
              {FORMATTERS.DATE_DDMMYYYYHHMM(
                notification.send_at || notification.created_at,
              )}
            </small>
            <Button
              className="p-2 pl-3 pr-3 ml-3 d-flex justify-content-center align-items-center"
              variant="white"
              onClick={() => handleDismissAlert(notification)}
            >
              <span>Fechar aviso</span>
              <LuX className="ml-2" />
            </Button>
          </div>
        </StyledTag>
      ))}
    </Container>
  );
}

DashboardNotifications.defaultProps = {
  subscription: null,
  user: null,
};

DashboardNotifications.propTypes = {
  subscription: PropTypes.object,
  user: PropTypes.object,
  onCreateUpdatePaymentSession: PropTypes.func.isRequired,
};

export default DashboardNotifications;
