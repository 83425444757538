import { connect } from 'react-redux';

import authSelectors from '_store/_selectors/auth';
import companyTransactionsActionCreators from '_store/_actions/companyTransactions';
import notificationsActionCreators from '_store/_actions/notifications';

import ZenpaperDashboard from './ZenpaperDashboard';

const mapStateToProps = state => ({
  user: authSelectors.currentUserSelector(state),
  activeCompany: state.company.activeCompany,
  accounts: state.companyTransactions.accounts,
  selectedAccountIds: state.companyTransactions.selected_account_ids,
  invitations: state.user.invitations,
});

const mapDispatchToProps = {
  onLoadDashboardData: companyTransactionsActionCreators.loadDashboardData,
  onFetchRecipients: companyTransactionsActionCreators.fetchAllRecipients,
  onFetchCategories: companyTransactionsActionCreators.fetchAllCategories,
  onFetchTransactionOverview: companyTransactionsActionCreators.fetchTransactionOverview,
  onFetchBankAccounts: companyTransactionsActionCreators.fetchAllAccounts,
  onFetchNotifications: notificationsActionCreators.fetchUserNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(ZenpaperDashboard);
