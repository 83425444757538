import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';

import { useMediaQuery } from 'helpers';

import { FormTextField, Button, PageHeader } from '_components/_core';
import { UpdateEmail } from '_components/_shared';

import { AvatarUpload } from './components';
import ProfileSchema from './utilities';
import {
  CustomCard,
  CustomCardBody,
  CustomCardFooter,
  CustomCardHeader,
} from '../SettingsV2/styles';

const Profile = ({
  isLoading,
  user,
  onUpdateUser,
  onFetchUser,
  onAvatarUpload,
  onAvatarDelete,
  isCompact,
}) => {
  const [userFetched, setUserFetched] = useState(false);

  const { isMobile, isDesktopExtraLarge } = useMediaQuery();

  const COLUMN_SIZE_LEFT = isDesktopExtraLarge ? 2 : 3;
  const COLUMN_SIZE_RIGHT = 9;

  const handleUpdateProfile = values => {
    onUpdateUser(values);
  };

  useEffect(() => {
    if (!userFetched) {
      onFetchUser();
      setUserFetched(true);
    }
  }, [onFetchUser, userFetched]);

  return (
    <Container
      fluid={!isCompact}
      className={classNames({
        'content-wrapper': !isCompact,
      })}
    >
      {!isCompact && <PageHeader title="Meus dados" variant="small" />}
      <Formik
        initialValues={{
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          document_type: user.document_type,
        }}
        onSubmit={handleUpdateProfile}
        enableReinitialize
        validationSchema={ProfileSchema}
      >
        {({ handleSubmit }) => (
          <>
            <Row>
              <Col>
                <CustomCard noBorders>
                  <CustomCardHeader>
                    <h3>Dados pessoais</h3>
                  </CustomCardHeader>
                  <CustomCardBody>
                    <Form>
                      <Form.Row>
                        <Col md={COLUMN_SIZE_LEFT} className="d-flex">
                          <Form.Group className="m-0 d-flex align-items-center">
                            <Form.Label className="m-0">&nbsp;</Form.Label>
                          </Form.Group>
                        </Col>
                        <Col
                          md={COLUMN_SIZE_RIGHT}
                          className="d-flex justify-content-center"
                        >
                          <AvatarUpload
                            user={user}
                            user_id={user.id}
                            avatar_url={user.avatar_url}
                            onAvatarUpload={onAvatarUpload}
                            onAvatarDelete={onAvatarDelete}
                            onFetchUser={onFetchUser}
                          />
                        </Col>
                      </Form.Row>
                      {/* <Form.Row>
                        <Col md={COLUMN_SIZE_LEFT}>
                          <Form.Group className="m-0 d-flex align-items-center">
                            <Form.Label className="m-0 mt-2">
                              Tipo de Documento
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={COLUMN_SIZE_RIGHT}>
                          <Form.Group>
                            <FormSelectField
                              name="document_type"
                              options={[
                                { value: 'PF', label: 'Pessoa Física' },
                                { value: 'PJ', label: 'Pessoa Jurídica' },
                              ]}
                              placeholder="Tipo de Documento"
                            />
                          </Form.Group>
                        </Col>
                      </Form.Row> */}
                      <Form.Row className="mt-2">
                        <Col md={COLUMN_SIZE_LEFT}>
                          <Form.Group className="m-0 d-flex align-items-center">
                            <Form.Label className="m-0 mt-2">
                              {user.document_type === 'PF' ? 'Nome' : 'Razão Social'}
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={COLUMN_SIZE_RIGHT}>
                          <Form.Group>
                            <FormTextField
                              name="first_name"
                              placeholder={
                                user.document_type === 'PF' ? 'Nome' : 'Razão Social'
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col md={COLUMN_SIZE_LEFT}>
                          <Form.Group className="m-0 d-flex align-items-center">
                            <Form.Label className="m-0 mt-2">
                              {user.document_type === 'PF'
                                ? 'Sobrenome'
                                : 'Nome Fantasia'}
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={COLUMN_SIZE_RIGHT}>
                          <Form.Group>
                            <FormTextField
                              name="last_name"
                              placeholder={
                                user.document_type === 'PF'
                                  ? 'Sobrenome'
                                  : 'Nome Fantasia'
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col md={COLUMN_SIZE_LEFT}>
                          <Form.Group className="m-0 d-flex align-items-center">
                            <Form.Label className="m-0 mt-2">E-mail</Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={COLUMN_SIZE_RIGHT}>
                          <Form.Group
                            className={classNames({
                              'd-flex': !isMobile,
                              'mb-1': isMobile,
                            })}
                          >
                            <FormTextField name="email" placeholder="E-mail" disabled />
                            {!isMobile && <UpdateEmail />}
                          </Form.Group>
                          {isMobile && <UpdateEmail isMobile={isMobile} />}
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col md={COLUMN_SIZE_LEFT}>
                          <Form.Group className="m-0 d-flex align-items-center">
                            <Form.Label className="m-0 mt-2">Senha</Form.Label>
                          </Form.Group>
                        </Col>
                        <Col md={COLUMN_SIZE_RIGHT}>
                          <Form.Group>
                            <FormTextField
                              name="password"
                              placeholder="Senha (deixe em branco para não alterar)"
                            />
                          </Form.Group>
                        </Col>
                      </Form.Row>
                    </Form>
                  </CustomCardBody>
                  <CustomCardFooter>
                    <Button
                      variant="primary"
                      onClick={handleSubmit}
                      isLoading={isLoading}
                      disabled={isLoading}
                    >
                      Salvar alterações
                    </Button>
                  </CustomCardFooter>
                </CustomCard>
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default Profile;

Profile.defaultProps = {
  user: {},
  isCompact: false,
  authUser: {},
};

Profile.propTypes = {
  onUpdateUser: PropTypes.func,
  onFetchUser: PropTypes.func,
  onAvatarUpload: PropTypes.func,
  onAvatarDelete: PropTypes.func,
  user: PropTypes.object,
  isLoading: PropTypes.bool,
  isCompact: PropTypes.bool,
  authUser: PropTypes.object,
};
