import { push } from 'connected-react-router';
import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';

import setActiveCompany from './setActiveCompany';

const addFinancialCompany = (financialCompany, isOnboarding, callback, errorCallback) => {
  return dispatch => {
    dispatch(request(financialCompany));

    service
      .addFinancialCompany(financialCompany)
      .then(response => {
        const company = response.data;

        dispatch(success(company));

        const handleConfirm = () => {
          dispatch(setActiveCompany(company));
        };

        confirmDialog.success(
          'Empresa cadastrada com sucesso!',
          `Deseja que o Zenply mude para a nova empresa <b>${company.company_name}</b>?`,
          handleConfirm,
          () => {},
        );

        if (callback) {
          callback(company);
        } else {
          dispatch(push('/dashboard'));
        }
      })
      .catch(error => {
        dispatch(failure(error));

        if (errorCallback) {
          errorCallback(error);
        }
      });
  };

  function request(financialCompany) {
    return {
      type: constants.ADD_FINANCIAL_COMPANY_REQUEST,
      payload: { financialCompany },
    };
  }
  function success(financialCompany) {
    return {
      type: constants.ADD_FINANCIAL_COMPANY_SUCCESS,
      payload: { financialCompany },
    };
  }
  function failure(message) {
    return {
      type: constants.ADD_FINANCIAL_COMPANY_FAILURE,
      payload: { message },
    };
  }
};

export default addFinancialCompany;
