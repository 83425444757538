import React, { useEffect, useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DateTime } from 'luxon';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import FORMATTERS from 'helpers/formatters';
import { useMediaQuery, usePrevious } from 'helpers';
import { PageHeader } from '_components/_core';
import { DashboardNotifications } from '_components/_shared';

import {
  BankAccount,
  CashflowMonth,
  MonthSchedule,
  CashflowSemester,
  DemonstrativeResults,
  MonthComparison,
  AttachmentsQuota,
} from './components';
import { StyledMonthPicker } from './styles';

function ZenpaperDashboard({
  user,
  activeCompany,
  onLoadDashboardData,
  onFetchRecipients,
  onFetchCategories,
  onFetchTransactionOverview,
  accounts,
  selectedAccountIds,
  onFetchBankAccounts,
}) {
  const [selectedDate, setSelectedDate] = useState(DateTime.now());
  const { isMobile, isDesktopExtraLarge } = useMediaQuery();
  const [changingTimeout, setChangingTimeout] = useState(0);

  useEffect(() => {
    if (changingTimeout) {
      return () => clearTimeout(changingTimeout);
    }
  }, [changingTimeout]);

  useEffect(() => {
    onFetchBankAccounts();
  }, [onFetchBankAccounts, activeCompany]);

  useEffect(() => {
    onFetchTransactionOverview();
  }, [onFetchTransactionOverview, activeCompany]);

  const isMounted = useRef(false);
  const prevCompany = usePrevious(activeCompany);
  const prevSelectedAccountIds = usePrevious(selectedAccountIds);
  const prevSelectedDate = usePrevious(selectedDate);

  useEffect(() => {
    if (!activeCompany) {
      return;
    }

    if (isEmpty(selectedAccountIds)) {
      return;
    }

    if (!isMounted.current) {
      onLoadDashboardData(selectedDate, accounts, selectedAccountIds);
      onFetchRecipients();
      onFetchCategories();

      isMounted.current = true;

      return;
    }

    if (
      selectedDate &&
      prevSelectedDate &&
      prevSelectedDate.toFormat('yyyy-MM') !== selectedDate.toFormat('yyyy-MM')
    ) {
      onLoadDashboardData(selectedDate, accounts, selectedAccountIds);
    }

    if (activeCompany && prevCompany && prevCompany.id !== activeCompany.id) {
      onLoadDashboardData(selectedDate, accounts, selectedAccountIds);
      onFetchRecipients();
      onFetchCategories();
    }

    if (isMounted.current && !isEqual(prevSelectedAccountIds, selectedAccountIds)) {
      onLoadDashboardData(selectedDate, accounts, selectedAccountIds);
    }
  }, [
    activeCompany,
    prevCompany,
    accounts,
    selectedAccountIds,
    prevSelectedAccountIds,
    selectedDate,
    prevSelectedDate,
    onFetchRecipients,
    onFetchCategories,
    onLoadDashboardData,
  ]);

  const delayedChangeDate = date => {
    if (changingTimeout) {
      clearTimeout(changingTimeout);
    }

    const newChangingTimeout = setTimeout(() => {
      setSelectedDate(date);
    }, 475);

    setChangingTimeout(newChangingTimeout);
  };

  const DesktopExtraLargeView = useCallback(
    () => (
      <Row>
        <Col sm={4}>
          <Row>
            <Col>
              <CashflowMonth competence={selectedDate.toFormat('yyyy-MM')} />
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <MonthComparison />
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <AttachmentsQuota />
            </Col>
          </Row>
        </Col>
        <Col sm={5}>
          <Row>
            <Col>
              <CashflowSemester />
            </Col>
          </Row>
          <Row>
            <Col className="mt-3">
              <DemonstrativeResults />
            </Col>
          </Row>
        </Col>
        <Col sm={3}>
          <Row>
            <Col sm={12}>
              <BankAccount />
            </Col>
            <Col sm={12} className="mt-3">
              <MonthSchedule selectedDate={selectedDate} />
            </Col>
          </Row>
        </Col>
      </Row>
    ),
    [selectedDate],
  );

  const DesktopView = useCallback(
    () => (
      <Row>
        <Col sm={12}>
          <Row>
            <Col sm={6}>
              <Row>
                <Col sm={12}>
                  <CashflowMonth competence={selectedDate.toFormat('yyyy-MM')} />
                </Col>
                <Col sm={12}>
                  <CashflowSemester className="mt-3" />
                </Col>
                <Col sm={12} className="mt-3">
                  <MonthComparison />
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm={12}>
                  <BankAccount />
                </Col>
                <Col sm={12} className="mt-3">
                  <MonthSchedule selectedDate={selectedDate} />
                </Col>
                <Col sm={12} className="mt-3">
                  <DemonstrativeResults />
                </Col>
                <Col sm={12} className="mt-3">
                  <AttachmentsQuota />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    ),
    [selectedDate],
  );

  const MobileView = useCallback(
    () => (
      <Row>
        <Col sm={12}>
          <BankAccount />
        </Col>
        <Col sm={12}>
          <MonthSchedule selectedDate={selectedDate} />
        </Col>
        <Col sm={12} className="mt-3">
          <CashflowMonth competence={selectedDate.toFormat('yyyy-MM')} />
        </Col>
        <Col sm={12}>
          <CashflowSemester className="mt-3" />
        </Col>
        <Col sm={12} className="mt-3">
          <DemonstrativeResults />
        </Col>
        <Col sm={12} className="mt-3">
          <MonthComparison />
        </Col>
        <Col sm={12} className="mt-3">
          <AttachmentsQuota />
        </Col>
      </Row>
    ),
    [selectedDate],
  );

  return (
    <>
      <DashboardNotifications />
      <Row>
        <Col className="pl-4 pr-4">
          <PageHeader
            title="Dashboard"
            description={`Olá, ${FORMATTERS.DISPLAY_NAME(user)}`}
            className="mb-0 ml-2"
            sideContent={
              <div className="d-flex align-items-center">
                <StyledMonthPicker
                  variant="transactions"
                  value={selectedDate}
                  onChange={newDate => delayedChangeDate(newDate)}
                />
              </div>
            }
          />
        </Col>
      </Row>
      <hr />
      {!isDesktopExtraLarge && !isMobile && <DesktopView />}
      {isDesktopExtraLarge && <DesktopExtraLargeView />}
      {isMobile && <MobileView />}
    </>
  );
}

ZenpaperDashboard.defaultProps = {
  activeCompany: null,
};

ZenpaperDashboard.propTypes = {
  activeCompany: PropTypes.object,
  user: PropTypes.object,
  onFetchCategories: PropTypes.func,
  onFetchRecipients: PropTypes.func,
  onLoadDashboardData: PropTypes.func.isRequired,
  onFetchTransactionOverview: PropTypes.func.isRequired,
  accounts: PropTypes.array.isRequired,
  selectedAccountIds: PropTypes.array.isRequired,
  onFetchBankAccounts: PropTypes.func.isRequired,
  onFetchNotifications: PropTypes.func.isRequired,
};

export default ZenpaperDashboard;
