import constants from '../../_constants/notifications.constants';
import service from '../../../services/notifications.service';

const archiveNotification = (id, callback) => {
  return dispatch => {
    dispatch(request(id));

    service
      .archiveNotification(id)
      .then(response => {
        dispatch(success(response.data));

        if (callback) {
          callback(response.data);
        }
      })
      .catch(error => {
        dispatch(failure(error));
      });
  };

  function request(id) {
    return {
      type: constants.ARCHIVE_USER_NOTIFICATION_REQUEST,
      payload: { id },
    };
  }
  function success(data) {
    return {
      type: constants.ARCHIVE_USER_NOTIFICATION_SUCCESS,
      payload: data,
    };
  }
  function failure(error) {
    return {
      type: constants.ARCHIVE_USER_NOTIFICATION_FAILURE,
      payload: error,
    };
  }
};

export default archiveNotification;
