import React, { useEffect, useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'react-bootstrap';
import { LuLogOut, LuPencil, LuTrash } from 'react-icons/lu';
import { FaCheckCircle, FaPencilAlt, FaPlus, FaTrash } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';

import FORMATTERS from 'helpers/formatters';
import { useMediaQuery, isUserMigrated } from 'helpers';
import { Button, Card, CardBody, PageHeader, Tag } from '_components/_core';
import {
  SubscriptionStatusTag,
  CompanyFormModal,
  PermissionsGate,
} from '_components/_shared';
import { CompanyAvatarUpload, EditFinancialCompanyModal } from './components';

import {
  CompanyDetails,
  CompanyEmail,
  CompanyListItemMobile,
  CompanyListItemMobileInfo,
  CompanyName,
  CustomCard,
  CustomCardBody,
  CustomCardHeader,
  StyledTable,
  SubscriptionStatus,
} from './styles';

function Companies({
  activeCompany,
  companies,
  user,
  onDeleteFinancialCompany,
  onUpdateFinancialCompany,
  onCompanyAvatarUpload,
  onCompanyAvatarDelete,
  onFetchCompanies,
  onFetchMarketSegments,
  isCompact,
  onLeaveCompany,
}) {
  const location = useLocation();

  const { isDesktopExtraLarge, isMobile, isTablet } = useMediaQuery();

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false);

  const isMigrated = useMemo(() => {
    return isUserMigrated(user);
  }, [user]);

  useEffect(() => {
    onFetchMarketSegments();
    onFetchCompanies();
  }, [onFetchMarketSegments, onFetchCompanies]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);

    const action = urlParams.get('action');

    if (action && action === 'create') {
      setSelectedCompany(null);
      setIsCompanyModalOpen(true);
    }
  }, [location]);

  const handleEditCompany = useCallback(
    company => {
      if (!isMigrated) {
        setSelectedCompany(company);
        setIsModalOpen(true);

        return;
      }

      setSelectedCompany(company);
      setIsCompanyModalOpen(true);
    },
    [isMigrated],
  );

  const handleCreateCompany = useCallback(() => {
    setSelectedCompany(null);
    setIsCompanyModalOpen(true);
  }, []);

  const handleLeaveCompany = useCallback(
    (companyId, companyName) => {
      onLeaveCompany(companyId, companyName, () => {
        onFetchCompanies();
      });
    },
    [onLeaveCompany, onFetchCompanies],
  );

  const shouldHideDeleteButton = useCallback(
    company => {
      if (!user) {
        return false;
      }

      if (!isMigrated) {
        return user.manager_id !== null;
      }

      return !company.is_manager;
    },
    [user, isMigrated],
  );

  const ownedCompanies = useMemo(() => {
    if (!companies) {
      return [];
    }

    return companies.filter(company => company.is_manager);
  }, [companies]);

  const invitedCompanies = useMemo(() => {
    if (!companies) {
      return [];
    }

    return companies.filter(company => !company.is_manager);
  }, [companies]);

  const renderAddCompanyButton = useCallback(() => {
    if (!isMigrated) {
      return null;
    }

    return (
      <Button
        variant="success-2"
        onClick={handleCreateCompany}
        className="d-flex align-items-center"
      >
        Nova empresa
        <FaPlus className="ml-2" />
      </Button>
    );
  }, [isMigrated, handleCreateCompany]);

  const renderTable = useCallback(
    companies => {
      if (isEmpty(companies) || !activeCompany) {
        return (
          <div className="d-flex justify-content-center align-items-center pt-5 pb-5">
            <p className="m-0 p-0">Nenhum registro encontrado</p>
          </div>
        );
      }

      if (isMobile || isTablet) {
        return (
          <div>
            {companies.map(company => {
              const { id } = company;

              return (
                <CompanyListItemMobile key={id}>
                  <div className="d-flex justify-content-start align-items-center">
                    <CompanyAvatarUpload
                      key={company.id}
                      company_id={company.id}
                      company_avatar_url={company.company_avatar_url}
                      onCompanyAvatarUpload={onCompanyAvatarUpload}
                      onCompanyAvatarDelete={onCompanyAvatarDelete}
                      onFetchCompanies={onFetchCompanies}
                      variant="extra-small"
                    />
                    <CompanyListItemMobileInfo>
                      <strong>
                        {FORMATTERS.MAX_X_CHARS(company.trading_name.toLowerCase(), 27)}
                      </strong>
                      <small>
                        {company.personal_account
                          ? 'Conta pessoal'
                          : FORMATTERS.CPF_CNPJ(company.document_number)}
                      </small>
                      <SubscriptionStatus status={company.subscription_status}>
                        {FORMATTERS.SUBSCRIPTION_STATUS(company.subscription_status)}
                      </SubscriptionStatus>
                    </CompanyListItemMobileInfo>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-end align-items-center">
                      {company.is_manager && (
                        <Button
                          size="sm"
                          variant="default"
                          className="d-flex justify-content-center align-items-center"
                          onClick={() => handleEditCompany(company)}
                          data-tip="Editar empresa"
                          data-place="bottom"
                        >
                          <LuPencil />
                        </Button>
                      )}
                      {!company.is_manager && (
                        <Button
                          size="sm"
                          variant="inverse-danger"
                          className="ml-2 d-flex justify-content-center align-items-center"
                          onClick={() => handleLeaveCompany(id, company.company_name)}
                          data-tip="Sair da empresa"
                          data-place="bottom"
                        >
                          <LuLogOut />
                        </Button>
                      )}
                      {!shouldHideDeleteButton(company) && (
                        <Button
                          size="sm"
                          variant="inverse-danger"
                          className="ml-2 d-flex justify-content-center align-items-center"
                          data-tip="Excluir empresa"
                          data-place="bottom"
                          onClick={() =>
                            onDeleteFinancialCompany(id, company.company_name)
                          }
                        >
                          <LuTrash />
                        </Button>
                      )}
                    </div>
                  </div>
                </CompanyListItemMobile>
              );
            })}
          </div>
        );
      }

      return (
        <div className={classNames({ 'table-responsive': isMobile })}>
          <StyledTable className={classNames({ 'table-hover': true })}>
            <thead>
              <tr>
                <th>Empresa</th>
                <th className="text-center">Assinatura</th>
                <th className="text-center">Ações</th>
              </tr>
            </thead>
            <tbody>
              {companies.map(company => {
                const { id } = company;

                return (
                  <tr key={id}>
                    <td width="100%">
                      <CompanyDetails className="d-flex align-items-center">
                        <div>
                          <CompanyAvatarUpload
                            key={company.id}
                            company_id={company.id}
                            company_avatar_url={company.company_avatar_url}
                            onCompanyAvatarUpload={onCompanyAvatarUpload}
                            onCompanyAvatarDelete={onCompanyAvatarDelete}
                            onFetchCompanies={onFetchCompanies}
                            variant="small"
                          />
                        </div>
                        <div className="d-flex justify-content-start flex-column mr-3">
                          <CompanyName>{company.trading_name}</CompanyName>
                          <div className="d-flex justify-content-start align-items-center">
                            <CompanyEmail>{company.company_name}</CompanyEmail>
                            {company.document_number && (
                              <>
                                <span className="mx-1">•</span>
                                <CompanyEmail>
                                  {company.personal_account
                                    ? 'Conta pessoal'
                                    : FORMATTERS.CPF_CNPJ(company.document_number)}
                                </CompanyEmail>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="ml-2">
                          {activeCompany.id === id && (
                            <FaCheckCircle
                              size="1.5em"
                              className="text-success"
                              data-tip="Esta é a empresa selecionada no momento"
                              data-place="bottom"
                            />
                          )}
                        </div>
                      </CompanyDetails>
                    </td>
                    <td className="text-center">
                      <SubscriptionStatusTag status={company.subscription_status} />
                    </td>
                    <td className="text-center">
                      <div className="d-flex">
                        {company.is_manager && (
                          <Button
                            size="sm"
                            variant="default"
                            className="d-flex justify-content-center align-items-center"
                            onClick={() => handleEditCompany(company)}
                            data-tip="Editar empresa"
                            data-place="bottom"
                          >
                            <LuPencil />
                          </Button>
                        )}
                        {!company.is_manager && (
                          <Button
                            size="sm"
                            variant="inverse-danger"
                            className="d-flex justify-content-center align-items-center"
                            onClick={() => handleLeaveCompany(id, company.company_name)}
                            data-tip="Sair da empresa"
                            data-place="bottom"
                          >
                            <LuLogOut />
                          </Button>
                        )}
                        {!shouldHideDeleteButton(company) && (
                          <Button
                            size="sm"
                            variant="inverse-danger"
                            className="ml-2 d-flex justify-content-center align-items-center"
                            data-tip="Excluir empresa"
                            data-place="bottom"
                            onClick={() =>
                              onDeleteFinancialCompany(id, company.company_name)
                            }
                          >
                            <LuTrash />
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
        </div>
      );
    },
    [
      isMobile,
      isTablet,
      handleEditCompany,
      handleLeaveCompany,
      shouldHideDeleteButton,
      activeCompany,
      onDeleteFinancialCompany,
      onCompanyAvatarDelete,
      onCompanyAvatarUpload,
      onFetchCompanies,
    ],
  );

  return (
    <Container
      fluid={!isCompact}
      className={classNames({
        'content-wrapper': !isCompact,
      })}
    >
      <ReactTooltip effect="solid" />
      {!isCompact && (
        <PageHeader
          title="Minhas empresas"
          variant="small"
          sideContent={renderAddCompanyButton()}
        />
      )}
      <EditFinancialCompanyModal
        isVisible={isModalOpen}
        selectedCompany={selectedCompany}
        onModalToggle={() => setIsModalOpen(!isModalOpen)}
        onUpdateFinancialCompany={onUpdateFinancialCompany}
      />
      <CompanyFormModal
        isVisible={isCompanyModalOpen}
        company_id={selectedCompany ? selectedCompany.id : null}
        selectedCompany={selectedCompany ? selectedCompany : null}
        onToggleForm={() => setIsCompanyModalOpen(!isCompanyModalOpen)}
        onAfterSaveCallbackWithReset={() => {
          setIsCompanyModalOpen(false);
          setSelectedCompany(null);

          onFetchCompanies();
        }}
      />
      {isMigrated && (
        <>
          {isCompact && (
            <div className="d-flex justify-content-between align-items-end mb-3">
              <h4 className="m-0">Minhas empresas</h4>
              <Button
                variant="success-2"
                onClick={handleCreateCompany}
                className="d-flex align-items-center"
              >
                Nova empresa
                <FaPlus className="ml-2" />
              </Button>
            </div>
          )}
          <Row className="mt-3">
            <Col>
              <CustomCard>
                <CustomCardHeader className="d-flex justify-content-between">
                  <h3 className="d-flex justify-content-center">Administrador</h3>
                </CustomCardHeader>
                <CustomCardBody noPadding>{renderTable(ownedCompanies)}</CustomCardBody>
              </CustomCard>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <CustomCard>
                <CustomCardHeader className="d-flex justify-content-between">
                  <h3 className="d-flex justify-content-center">Convidado</h3>
                </CustomCardHeader>
                <CustomCardBody noPadding>{renderTable(invitedCompanies)}</CustomCardBody>
              </CustomCard>
            </Col>
          </Row>
        </>
      )}
      {!isMigrated && (
        <Row>
          {companies.map(company => {
            const { id } = company;

            return (
              <>
                <Col key={id} xl={isDesktopExtraLarge ? 6 : 12}>
                  <Card className="mt-3">
                    <CardBody>
                      <div className="d-flex align-items-center">
                        <div>
                          <CompanyAvatarUpload
                            key={id}
                            company_id={company.id}
                            company_avatar_url={company.company_avatar_url}
                            onCompanyAvatarUpload={onCompanyAvatarUpload}
                            onCompanyAvatarDelete={onCompanyAvatarDelete}
                            onFetchCompanies={onFetchCompanies}
                          />
                        </div>
                        <div>
                          <h5>
                            {company.company_name}
                            {activeCompany && activeCompany.id === id && (
                              <Tag className="ml-3">ATIVA</Tag>
                            )}
                          </h5>
                          <p className="text-muted mb-0">
                            {FORMATTERS.CPF_CNPJ(company.document_number)}
                          </p>
                        </div>
                        <div className="ml-auto">
                          <PermissionsGate permissions={['manager_company']} type="all">
                            <Button
                              noMargin
                              variant="inverse-dark btn-sm mr-2"
                              icon={<FaPencilAlt />}
                              onClick={() => handleEditCompany(company)}
                            />
                          </PermissionsGate>
                          {!shouldHideDeleteButton(company) && (
                            <Button
                              noMargin
                              variant="inverse-danger btn-sm"
                              icon={<FaTrash />}
                              onClick={() =>
                                onDeleteFinancialCompany(id, company.company_name)
                              }
                            />
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </>
            );
          })}
        </Row>
      )}
    </Container>
  );
}

Companies.defaultProps = {
  companies: [],
  activeCompany: {},
  subscription: {},
  user: null,
  isCompact: false,
};

Companies.propTypes = {
  companies: PropTypes.array,
  activeCompany: PropTypes.object,
  subscription: PropTypes.object,
  user: PropTypes.object,
  onDeleteFinancialCompany: PropTypes.func.isRequired,
  onUpdateFinancialCompany: PropTypes.func.isRequired,
  onCompanyAvatarUpload: PropTypes.func,
  onCompanyAvatarDelete: PropTypes.func,
  onFetchCompanies: PropTypes.func,
  onFetchMarketSegments: PropTypes.func.isRequired,
  isCompact: PropTypes.bool,
  onLeaveCompany: PropTypes.func.isRequired,
};

export default Companies;
