import confirmDialog from 'helpers/confirmDialog';

import constants from '../../_constants/company.constants';
import service from '../../../services/company.service';
import alertActionCreators from '../alert.actions';

const deleteCompanyInvitation = id => {
  return async dispatch => {
    const message = `Tem certeza que deseja remover este convite? Esta ação não poderá ser desfeita.`;

    confirmDialog.warning({
      title: 'Remover convite',
      message: message,
      confirmButtonText: 'Sim, remover convite',
      icon: 'warning',
      onConfirm: () => {
        dispatch(request(id));

        service
          .deleteInvitation(id)
          .then(() => {
            dispatch(success(id));

            dispatch(alertActionCreators.success('Convite removido com sucesso!'));
          })
          .catch(error => {
            dispatch(failure(error));
          });
      },
      onCancel: () => {},
    });
  };

  function request(id) {
    return {
      type: constants.DELETE_COMPANY_INVITATION_REQUEST,
      payload: id,
    };
  }
  function success(id) {
    return {
      type: constants.DELETE_COMPANY_INVITATION_SUCCESS,
      payload: id,
    };
  }
  function failure(error) {
    return {
      type: constants.DELETE_COMPANY_INVITATION_FAILURE,
      payload: { error },
    };
  }
};

export default deleteCompanyInvitation;
